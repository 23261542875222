import React, { useContext } from 'react';
import { Context } from '../hooks';
import './DNAContent.css';
import '../../src/Colors.css';
import TrainingWarning from './common/TrainingWarning';

const DNAContent = ({ rightContent, leftContent }) => {
  const { img, caption, alignmentClass, footer, hiddenFooter } = leftContent;
  const { footerOpen, setFooterOpen } = useContext(Context);
  const RightComponents = (props) => rightContent;

  return (
    <>
      <div className={`ish-content ${alignmentClass}`}>
        <div className="ish-content-left">
          <img src={img} alt="DNA Probe Kit" className="ish-image" />
          <div className="subheading-container white">
            <div dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        </div>
        <div className="white right-content">
          <RightComponents />
        </div>
      </div>
      <div className="footer-container white" style={{ textAlign: 'left' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {footerOpen && <div dangerouslySetInnerHTML={{ __html: hiddenFooter }} />}
          <div dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
        <TrainingWarning isTrainingLabelOpen={true} />
      </div>
    </>
  );
};

export default DNAContent;
