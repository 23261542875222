import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CloseIcon from '../images/x.svg';
import closeModalIcon from '../images/closeModalIcon.svg';
import OpenModalIcon from '../images/openModalIcon.svg';
import lungContentConfig from '../config/lungwall/content';

import '../styles/lungwall.css';
import TrainingWarning from '../components/common/TrainingWarning';

const LungWall = ({ videoEl }) => {
  //this will be passed in as props from the parent component
  const { group, assay, slide } = useParams();
  const navigate = useNavigate();
  console.log('group and assay: ', group, assay);
  const { listData, imagingListData, expansionData, liquidNGSReport, tissueNGSReport } =
    lungContentConfig;
  const [activeSlide, setActiveSlide] = useState(0);
  const [contentData, setContentData] = useState({ key: '', data: [] });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (slide !== undefined) {
      setActiveSlide(parseInt(slide));
    }
  }, [slide]);

  useEffect(() => {
    function handleDataLoad() {
      console.log('HANDLING DATA LOAD');

      if (group === '6') {
        setContentData(expansionData);
      } else if (group === '7') {
        setContentData(listData);
      } else if (group === '4') {
        setContentData(liquidNGSReport);
      } else if (group === '5') {
        setContentData(tissueNGSReport);
      } else if (group === '8') {
        setContentData(imagingListData);
      } else {
        setContentData([]);
      }
    }

    handleDataLoad();
  }, [group, assay]);

  console.log('content data:', contentData);
  console.log('active slide:', activeSlide);

  const getContent = () => {
    switch (contentData.key) {
      case 'list':
        return (
          <>
            <div className="lung-tab-left">
              <img src={contentData.data[activeSlide].img} alt="" className="lung-image" />
            </div>
            <div className="lung-tab-right">
              <table>
                <tbody>
                  {contentData.data[activeSlide].list.map((list, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>
                            &bull;
                          </span>
                        </td>
                        <td>
                          <span>{list}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      case 'expansion':
        return (
          <>
            <div className="lung-tab-left">
              <img src={contentData.data[activeSlide].img} alt="" className="lung-image" />
              <img
                src={OpenModalIcon}
                alt=""
                style={{
                  width: '10%',
                  padding: 0,
                  minWidth: 0,
                  marginRight: 40
                }}
                onClick={() => {
                  setModalOpen(!modalOpen);
                }}
              />
            </div>
            <div className="lung-tab-right">{contentData.data[activeSlide].content}</div>
          </>
        );
      case 'liquidNGSReport':
        return (
          <>
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '95%',
                width: '98%',
                margin: '10px auto'
              }}
            >
              <img src={contentData.data[activeSlide].img} alt="" className="report-image" />
            </div>
          </>
        );
      case 'tissueNGSReport':
        return (
          <>
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '95%',
                width: '98%',
                margin: '10px auto'
              }}
            >
              <img src={contentData.data[activeSlide].img} alt="" className="report-image" />
            </div>
          </>
        );
      default:
    }
  };

  const getModalContent = () => {
    if (!contentData.data.length) {
      return <div>Loading ...</div>;
    } else {
      return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          <img
            src={contentData.data[activeSlide].modalImg}
            alt=""
            style={{
              maxWidth: '100% !important',
              width: 'auto',
              margin: '0 auto',
              display: 'flex'
            }}
          />
        </div>
      );
    }
  };

  const handleCloseClick = () => {
    if (group === '6') {
      navigate('/piechart');
    } else if (group === '7') {
      videoEl.current[2].currentTime = 65.0;
    } else if (group === '8') {
      videoEl.current[2].currentTime = 50.0;
    } else if (group === '5') {
      navigate('/landing/5/' + assay);
    } else if (group === '4') {
      navigate('/landing/4/' + assay);
    } else {
      console.log('doesnt work');
    }
  };

  console.log('content data:', contentData);
  return (
    <div className="lung-wall-container app" style={{ height: '100%' }} id="lung-wall-container">
      <div
        className="lung-wall-bottom"
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <div
          className={`close-div ${contentData.data.length <= 1 ? 'move-down-close' : ''}`}
          id="close-div"
        >
          <img
            className="close-button"
            src={CloseIcon}
            alt="close"
            onClick={() => handleCloseClick()}
          />
        </div>
        <div className="button-wall">
          <div className="lung-buttons white" id="lung-buttons">
            {contentData.data.map((slide, index) => {
              let gtagAttrs = {};
              if (slide.gtagID !== undefined && slide.gtagText !== undefined) {
                gtagAttrs = {
                  'id': slide.gtagID,
                  'text': slide.gtagText
                };
              }
              return (
                <div
                  {...gtagAttrs}
                  className={`${contentData.data.length >= 2 ? 'lung-button' : 'one-lung-button'} ${
                    activeSlide === index && contentData.data.length > 1 ? 'lung-clicked-button' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    setActiveSlide(index);
                  }}
                >
                  {slide.title}
                </div>
              )
            })}
          </div>
        </div>
        <div className="lung-tab" style={{ flex: 1 }}>
          <div className="lung-tab-content" id="lung-tab-content">
            {getContent()}
          </div>
          <TrainingWarning isTrainingLabelOpen={true} />
        </div>
        <Modal
          isOpen={modalOpen}
          overlayClassName="lung-modal-overlay"
          className="lung-modal"
          shouldFocusAfterRender={false}
          onRequestClose={() => setModalOpen(false)}
        >
          <div className="lung-modal-container">{getModalContent()}</div>
          <div className="lung-modal-close" onClick={() => setModalOpen(false)}>
            <img src={closeModalIcon} alt="" />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LungWall;
