import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../hooks';
import './ButtonWall.css';
import '../Colors.css';
import './ISHTab.css';
import CloseIcon from '../images/x.svg';
import DNAContent from './DNAContent';
import PrinterContent from './PrinterContent';

const ButtonWall = ({ slides, activeSlide, setActiveSlide, layout, closePath, topContent }) => {
  const { rightContent, leftContent } = slides[activeSlide];
  const { footerOpen, setFooterOpen } = useContext(Context);
  const getContent = () => {
    switch (layout) {
      case 'dna':
        return <DNAContent rightContent={rightContent} leftContent={leftContent} />;
      case 'printer':
        return <PrinterContent rightContent={rightContent} leftContent={leftContent} />;
      default:
    }
  };
  return (
    <>
      <div className="button-wall-container app">
        {layout === 'printer' && topContent}
        <div className="button-wall-bottom">
          <Link className="close-div" to={closePath} onClick={() => setFooterOpen(false)}>
            <img className="close-button" src={CloseIcon} alt="close" />
          </Link>
          <div className="button-wall">
            <div className="buttons white">
              {slides.map((slide, index) => (
                <div
                  className={`button ${activeSlide === index ? 'clicked-button' : ''}`}
                  key={index}
                  onClick={() => {
                    setActiveSlide(index);
                    setFooterOpen(false);
                  }}
                >
                  {slide.name}
                </div>
              ))}
            </div>
          </div>
          <div className={`ish-tab ${layout === 'printer' ? 'printer-tab' : ''}`}>
            {getContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonWall;
