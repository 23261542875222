import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ButtonWall from "../components/ButtonWall";
import GroupsConfig from "../config/groups.js";

const Wall = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { group, assay, slide } = useParams();
  const { slides, layout, topContent } = GroupsConfig[group].assays[assay].wall;

  useEffect(() => {
    if (slide !== undefined) {
      setActiveSlide(parseInt(slide));
    }
  }, [slide]);

  return (
    <>
      <ButtonWall
        slides={slides}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
        layout={layout}
        closePath={`/landing/${group}/${assay}`}
        topContent={topContent}
      />
    </>
  );
};

export default Wall;
