import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import wirewaxConfig from '../config/wirewax';
import '../App.css';

const ONE_MINUTE = 60.0;
const TWO_MINUTES = 120.0;
const THREE_MINUTES = 180.0;
const FOUR_MINUTES = 240.0;
const FIVE_MINUTES = 300.0;
const SIX_MINUTES = 360.0;
const SEVEN_MINUTES = 420.0;
const EIGHT_MINUTES = 480.0;
const NINE_MINUTES = 540.0;
const TEN_MINUTES = 600.0;
const ELEVEN_MINUTES = 660.0;
const TWELVE_MINUTES = 720.0;
const THIRTEEN_MINUTES = 780.0;

const links = [
  {
    isBubbles: true
  },
  {
    title: 'Home',
    bolded: true,
    tag: wirewaxConfig.Home.spriteId,
    time: 14.0,
    items: [],
    id: 'home',
    videoKey: 0
  },
  {
    title: 'Breast Cancer',
    bolded: true,
    tag: wirewaxConfig.BreastCancer.spriteId,
    id: 'breast-cancer',
    items: [
      {
        title: 'Breast Home',
        bolded: true,
        tag: wirewaxConfig.BreastCancer.spriteId,
        overLayBool: false,
        time: 18.0,
        videoKey: 1
      },
      {
        title: 'TNM Staging',
        bolded: true,
        tag: wirewaxConfig.TNMStaging.spriteId,
        overLayBool: false,
        time: 22.0,
        videoKey: 1
      },
      {
        title: 'T: Assess Primary Tumor',
        bolded: false,
        tag: wirewaxConfig.TAssessPrimary.spriteId,
        overLayBool: false,
        time: 30.0,
        videoKey: 1
      },
      {
        title: 'N: Assess Regional Nodal Investment',
        bolded: false,
        tag: wirewaxConfig.NAssessRegional.spriteId,
        overLayBool: false,
        time: 82.0,
        videoKey: 1
      },
      {
        title: 'M: Assess Metastases',
        bolded: false,
        tag: wirewaxConfig.MAssessMetastases.spriteId,
        overLayBool: false,
        time: 108.0,
        videoKey: 1
      },
      {
        title: 'Final TNM Stage',
        bolded: false,
        tag: wirewaxConfig.FinalTNM.spriteId,
        overLayBool: false,
        time: 144.0,
        videoKey: 1
      },
      {
        title: 'Determine HER2 Status',
        bolded: true,
        tag: wirewaxConfig.DetermineHER2.spriteId,
        overLayBool: false,
        time: 145.0,
        videoKey: 1
      },
      {
        title: 'Tumor Biopsy',
        bolded: false,
        tag: wirewaxConfig.TissueVideo.spriteId,
        overLayBool: false,
        time: 152.0,
        videoKey: 1
      },
      {
        title: 'IHC',
        bolded: false,
        tag: wirewaxConfig.IHC.spriteId,
        overLayBool: true,
        time: 230.0,
        videoKey: 1,
        navigateTo: '/home/0'
      },
      {
        title: 'ISH',
        bolded: false,
        tag: wirewaxConfig.ISH.spriteId,
        overLayBool: true,
        time: 269.0, //4:29
        videoKey: 1,
        navigateTo: '/home/1'
      },
      {
        title: 'NGS',
        bolded: false,
        tag: wirewaxConfig.Tissue.spriteId,
        overLayBool: true,
        time: 349.0, //5:49
        videoKey: 1,
        navigateTo: '/home/2'
      },
      {
        title: 'Liquid Biopsy',
        bolded: false,
        tag: wirewaxConfig.LiquidVideo.spriteId,
        overLayBool: false,
        time: 364.0, //6:00,
        videoKey: 1,
        navigateTo: '/home/3'
      }
    ]
  },
  {
    title: 'Lung Cancer',
    bolded: true,
    tag: wirewaxConfig.BreastCancer.spriteId,
    id: 'lung-cancer',
    items: [
      {
        title: 'Lung Home',
        bolded: true,
        tag: wirewaxConfig.BreastCancer.spriteId,
        overLayBool: false,
        time: 29.0,
        videoKey: 2
      },
      {
        title: 'Components of Diagnosis',
        bolded: true,
        tag: wirewaxConfig.TNMStaging.spriteId,
        overLayBool: false,
        time: 40.0,
        videoKey: 2
      },
      {
        title: 'TNM Staging',
        bolded: true,
        tag: wirewaxConfig.TAssessPrimary.spriteId,
        overLayBool: false,
        time: ONE_MINUTE + 50.0,
        videoKey: 2
      },
      {
        title: 'T: Assess Primary Tumor',
        bolded: false,
        tag: wirewaxConfig.NAssessRegional.spriteId,
        overLayBool: false,
        time: TWO_MINUTES,
        videoKey: 2 //1:22
      },
      {
        title: 'N: Assess Regional Nodal Involvement',
        bolded: false,
        tag: wirewaxConfig.MAssessMetastases.spriteId,
        overLayBool: false,
        time: TWO_MINUTES + 50.0,
        videoKey: 2 //1:48
      },
      {
        title: 'M: Assess Metastases',
        bolded: false,
        tag: wirewaxConfig.FinalTNM.spriteId,
        overLayBool: false,
        time: THREE_MINUTES + 20.0,
        videoKey: 2 //2:24
      },
      {
        title: 'Final TNM Stage',
        bolded: false,
        tag: wirewaxConfig.DetermineHER2.spriteId,
        overLayBool: false,
        time: FOUR_MINUTES,
        videoKey: 2 //2:25
      },
      {
        title: 'Test for Biomarkers',
        bolded: true,
        tag: wirewaxConfig.DetermineHER2.spriteId,
        overLayBool: false,
        time: FOUR_MINUTES + 6.0,
        videoKey: 2 //2:25
      },
      {
        title: 'Molecular Subtypes',
        bolded: false,
        tag: wirewaxConfig.DetermineHER2.spriteId,
        overLayBool: false,
        time: FOUR_MINUTES + 8.0,
        videoKey: 2 //2:25
      },
      {
        title: 'Sample Collection',
        bolded: false,
        tag: wirewaxConfig.DetermineHER2.spriteId,
        overLayBool: false,
        time: FOUR_MINUTES + 17.0,
        videoKey: 2 //2:25
      },
      {
        title: 'Tumor Biopsy',
        bolded: false,
        indent: 1,
        tag: wirewaxConfig.IHC.spriteId,
        overLayBool: false,
        time: FOUR_MINUTES + 21.0,
        videoKey: 2
      },
      {
        title: 'Tissue NGS Assays',
        bolded: false,
        indent: 2,
        tag: wirewaxConfig.IHC.spriteId,
        overLayBool: false,
        time: FIVE_MINUTES + 51.0,
        videoKey: 2,
        navigateTo: '/home/5'
      },
      {
        title: 'Liquid Biopsy',
        bolded: false,
        indent: 1,
        tag: wirewaxConfig.ISH.spriteId,
        overLayBool: false,
        time: SIX_MINUTES + 26.0,
        videoKey: 2 //4:29
      },
      {
        title: 'Liquid Biopsy NGS Assays',
        bolded: false,
        indent: 2,
        tag: wirewaxConfig.IHC.spriteId,
        overLayBool: false,
        time: SIX_MINUTES + 56.0,
        videoKey: 2,
        navigateTo: '/home/4'
      }
    ]
  },
  {
    title: 'Anemia',
    bolded: true,
    id: 'anemia',
    items: [
      {
        title: 'Anemia Home',
        bolded: true,
        overLayBool: false,
        time: 35.0,
        videoKey: 3
      },
      {
        title: 'Screening for IDA',
        bolded: true,
        overLayBool: false,
        time: 42.0,
        videoKey: 3
      },
      {
        title: 'CBC Without Differential',
        bolded: false,
        overLayBool: false,
        time: 49.0,
        videoKey: 3
      },
      {
        title: 'CBC With Differential',
        bolded: false,
        overLayBool: false,
        time: FOUR_MINUTES + 30.0,
        videoKey: 3
      },
      {
        title: 'CBC Without Differential,<br/> With Reflex to an Anemia Panel',
        bolded: false,
        overLayBool: false,
        time: EIGHT_MINUTES + 13,
        videoKey: 3
      },
      {
        title: 'CBC With Differential,<br/> With Reflex to an Anemia Panel',
        bolded: false,
        overLayBool: false,
        time: NINE_MINUTES + 32,
        videoKey: 3
      },
      {
        title: 'Iron Panel',
        bolded: false,
        overLayBool: false,
        time: TEN_MINUTES + 57,
        videoKey: 3
      }
    ]
  }
];

const Menu = ({ setMenuOpen, videoEl, setOverlayOpen, setActiveVideoIndex, activeVideoIndex }) => {
  const navigate = useNavigate();
  const [openBreastLinks, setOpenBreastLinks] = useState(false);
  const [openLungLinks, setOpenLungLinks] = useState(false);
  const [openAnemiaLinks, setOpenAnemiaLinks] = useState(false);
  const [activeID, setActiveID] = useState(null);

  const handleMenuItemClick = (videoIndex, videoKey) => {
    console.log('Clicked on menu item', videoIndex, videoKey);
    if (videoIndex !== videoKey) {
      videoEl.current[videoIndex].pause();
      setActiveVideoIndex(videoKey);
    }
  };

  const getToggleContent = (data) => {
    return (
      <>
        {data.map(({ title, time, bolded, indent, navigateTo, overLayBool, videoKey, gtagID, gtagText }, key) => {
          let gtagAttrs = {};
          if (gtagID !== undefined && gtagText !== undefined) {
            gtagAttrs = {
              'id': gtagID,
              'text': gtagText
            };
          }
          return (
            <div
              {...gtagAttrs}
              className="toggle-content-item"
              key={key}
              // style={bolded ? { fontWeight: '900' } : { fontWeight: '500' }}
              style={{
                fontWeight: bolded ? '900' : '500'
              }}
              onClick={() => {
                console.log('title', title, 'overLayBool', overLayBool, 'time: ', time);
                //SETTING CORRECT VIDEO INDEX FIRST
                handleMenuItemClick(activeVideoIndex, videoKey);

                setOverlayOpen(overLayBool);
                setMenuOpen(false);

                //SETTTING CORRECT TIME TO SEEK TO
                videoEl.current[videoKey].currentTime = time;

                // if (time < 230.0 || time >= 365.0) {
                //   console.log('WE ARE PLAYING THE VIDEO RN ');
                //   videoEl.current[activeVideoIndex].play();
                // }
                if (navigateTo !== undefined) {
                  navigate(navigateTo);
                }
              }}
            >
              <span
                style={{ marginLeft: indent && `${indent * 20}px` }}
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="menu-wrapper">
      {links.map(({ title, bolded, tag, time, isBubbles, navigateTo, id, items }, key) => {
        if (isBubbles) {
          return (
            <div key={key} className="menu-item bubbles" onClick={() => setMenuOpen(false)}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
          );
        }
        return (
          <div key={key}>
            <div
              key={key}
              className="menu-item"
              style={bolded ? { fontWeight: '900' } : { fontWeight: '500' }}
              onClick={() => {
                if (time) {
                  videoEl.current[activeVideoIndex].currentTime = time;
                }
                if (time < 230.0 || time >= 365.0) {
                  videoEl.current[activeVideoIndex].play();
                }
                if (navigateTo !== undefined) {
                  navigate(navigateTo);
                }
                if (id === 'home') {
                  handleMenuItemClick(activeVideoIndex, 0);
                  videoEl.current[0].currentTime = time;
                  setMenuOpen(false);
                  setOpenBreastLinks(false);
                  setOpenLungLinks(false);
                  setOpenAnemiaLinks(false);
                  setActiveID(null);
                } else if (id === 'breast-cancer') {
                  setOpenBreastLinks(!openBreastLinks);
                  setOpenAnemiaLinks(false);
                  setOpenLungLinks(false);
                  if (activeID === id) {
                    setActiveID(null);
                  } else {
                    setActiveID(id);
                  }
                } else if (id === 'lung-cancer') {
                  setOpenLungLinks(!openLungLinks);
                  setOpenAnemiaLinks(false);
                  setOpenBreastLinks(false);
                  if (activeID === id) {
                    setActiveID(null);
                  } else {
                    setActiveID(id);
                  }
                } else if (id === 'anemia') {
                  setOpenAnemiaLinks(!openAnemiaLinks);
                  setOpenBreastLinks(false);
                  setOpenLungLinks(false);
                  if (activeID === id) {
                    setActiveID(null);
                  } else {
                    setActiveID(id);
                  }
                }
              }}
            >
              {title}
              <div style={{ display: id === 'home' ? 'none' : 'block' }}>
                {activeID === id ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </div>
            <div
              className="toggle-content"
              style={{
                display:
                  (id === 'breast-cancer' && openBreastLinks) ||
                  (id === 'lung-cancer' && openLungLinks) ||
                  (id === 'anemia' && openAnemiaLinks)
                    ? 'block'
                    : 'none'
              }}
            >
              {getToggleContent(items)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
