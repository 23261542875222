import React from 'react';
import { useParams } from 'react-router-dom';
import Buttons from '../common/Buttons';
import wirewaxConfig from '../../../config/wirewax';

const LandingButtons = ({ routes, videoEl }) => {
  const { group, assay } = useParams();

  return (
    <Buttons
      containerClass={'printer'}
      buttons={[
        {
          name: 'HER2-POSITIVE',
          route: `/wall/${group}/${assay}/0`,
          buttonClass: 'wall'
        },
        {
          name: 'HER2-NEGATIVE',
          route: `/wall/${group}/${assay}/1`,
          buttonClass: 'wall'
        },
        {
          name: 'EXPLORE MORE TISSUE NGS ASSAYS',
          route: '/home/2',
          seek: wirewaxConfig.Tissue.spriteId,
          time: 349.0,
          videoIndex: 1
        },
        {
          name: 'EXPLORE LIQUID BIOPSY TESTING',
          seek: wirewaxConfig.LiquidVideo.spriteId,
          route: '/home/3',
          time: 394.0,
          videoIndex: 1
        }
      ]}
      videoEl={videoEl}
    />
  );
};

export default LandingButtons;

//6:33 393
