import React from 'react';
import Buttons from '../common/Buttons';
import wirewaxConfig from '../../../config/wirewax';

const LandingButtons = ({ videoEl }) => (
  <Buttons
    buttons={[
      {
        name: 'EXPLORE MORE ISH ASSAYS',
        route: '/home/1',
        time: 269.0,
        videoIndex: 1
      },
      {
        name: 'EXPLORE IHC ASSAYS',
        route: '/home/0',
        time: 230.0,
        videoIndex: 1
      },
      {
        name: 'EXPLORE TISSUE NGS ASSAYS',
        route: '/home/2',
        time: 349.0,
        videoIndex: 1
      }
    ]}
    videoEl={videoEl}
  />
);

export default LandingButtons;
