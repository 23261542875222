import BondOracleThumb01 from '../images/thumbnails/BondOracle/01_Bond Oracle_button image_v2.png';
import BondOracleThumb02 from '../images/thumbnails/BondOracle/02_Bond Oracle_button image_v2.png';
import BondOracleThumb03 from '../images/thumbnails/BondOracle/03_Bond Oracle_button image_v2.png';
import BondOracleThumb04 from '../images/thumbnails/BondOracle/04_Bond Oracle_button image_v2.png';

import HER2IQFishThumb01 from '../images/thumbnails/HER2FishIQ/01_HER2 IQFISH_Button image.png';
import HER2IQFishThumb02 from '../images/thumbnails/HER2FishIQ/02_HER2 IQFISH_Button image.png';
import HER2IQFishThumb03 from '../images/thumbnails/HER2FishIQ/03_HER2 IQFISH_Button image.png';
import HER2IQFishThumb04 from '../images/thumbnails/HER2FishIQ/04_HER2 IQFISH_Button image.png';
import HER2IQFishThumb05 from '../images/thumbnails/HER2FishIQ/05_HER2 IQFISH_Button image.png';

import HercepTestThumb01 from '../images/thumbnails/HercepTest/01_HercepTest_button image_v2.png';
import HercepTestThumb02 from '../images/thumbnails/HercepTest/02_HercepTest_button image_v2.png';
import HercepTestThumb03 from '../images/thumbnails/HercepTest/03_HercepTest_button image_v2.png';
import HercepTestThumb04 from '../images/thumbnails/HercepTest/04_HercepTest_button image_v2.png';

import PathVysionThumb01 from '../images/thumbnails/PathVysion/01_PathVysion_Button image.png';
import PathVysionThumb02 from '../images/thumbnails/PathVysion/02_PathVysion_Button image.png';
import PathVysionThumb03 from '../images/thumbnails/PathVysion/03_PathVysion_Button image.png';
import PathVysionThumb04 from '../images/thumbnails/PathVysion/04_PathVysion_Button image.png';
import PathVysionThumb05 from '../images/thumbnails/PathVysion/05_PathVysion_Button image.png';

import SPOTLightThumb01 from '../images/thumbnails/SPOTLight/01_SPOT-Light_Button image_v2.png';
import SPOTLightThumb02 from '../images/thumbnails/SPOTLight/02_SPOT-Light_Button image_v2.png';
import SPOTLightThumb03 from '../images/thumbnails/SPOTLight/03_SPOT-Light_Button image_v2.png';

import VentanaThumb01 from '../images/thumbnails/Ventana/01_VENTANA_button image_v2.png';
import VentanaThumb02 from '../images/thumbnails/Ventana/02_VENTANA_button image_v2.png';
import VentanaThumb03 from '../images/thumbnails/Ventana/03_VENTANA_button image_v2.png';
import VentanaThumb04 from '../images/thumbnails/Ventana/04_VENTANA_button image_v2.png';

import VentanaHER2Thumb01 from '../images/thumbnails/VentanaHER2/01_VENTANA HER2 Dual ISH_Button image.png';
import VentanaHER2Thumb02 from '../images/thumbnails/VentanaHER2/02_VENTANA HER2 Dual ISH_Button image.png';
import VentanaHER2Thumb03 from '../images/thumbnails/VentanaHER2/03_VENTANA HER2 Dual ISH_Button image.png';
import VentanaHER2Thumb04 from '../images/thumbnails/VentanaHER2/04_VENTANA HER2 Dual ISH_Button image.png';
import VentanaHER2Thumb05 from '../images/thumbnails/VentanaHER2/05_VENTANA HER2 Dual ISH_Button image.png';

import LandingTopSection from '../components/landing/common/TopSection';

import LeftConfig from './wall/left';
import {
  IHCScore3,
  IHCScore2,
  IHCScore1,
  IHCScore0,
  ISHGreaterThanSix,
  ISHBetweenSixAndFour,
  ISHLessThanFour,
  ISHGroup1,
  ISHGroup2,
  ISHGroup3,
  ISHGroup4,
  ISHGroup5,
  HER2Positive,
  HER2NegativeExplained,
  HER2NegativeTitle,
  HER2NegativeNotConclusive,
  ISHGroup1HER2
} from './wall/right';

const SlidesConfig = {
  Ventana: {
    layout: 'dna',
    slides: [
      {
        name: 'SCORE 3+',
        thumb: VentanaThumb01,
        leftContent: LeftConfig.VET,
        rightContent: <IHCScore3 />
      },
      {
        name: 'SCORE 2+',
        thumb: VentanaThumb02,
        leftContent: LeftConfig.VET2,
        rightContent: <IHCScore2 />
      },
      {
        name: 'SCORE 1+',
        thumb: VentanaThumb03,
        leftContent: LeftConfig.VET1,
        rightContent: <IHCScore1 />
      },
      {
        name: 'SCORE 0',
        thumb: VentanaThumb04,
        leftContent: LeftConfig.VET0,
        rightContent: <IHCScore0 />
      }
    ]
  },
  BondOracle: {
    layout: 'dna',
    slides: [
      {
        name: 'SCORE 3+',
        thumb: BondOracleThumb01,
        leftContent: LeftConfig.BOND,
        rightContent: <IHCScore3 />
      },
      {
        name: 'SCORE 2+',
        thumb: BondOracleThumb02,
        leftContent: LeftConfig.BOND2,
        rightContent: <IHCScore2 />
      },
      {
        name: 'SCORE 1+',
        thumb: BondOracleThumb03,
        leftContent: LeftConfig.BOND1,
        rightContent: <IHCScore1 />
      },
      {
        name: 'SCORE 0',
        thumb: BondOracleThumb04,
        leftContent: LeftConfig.BOND0,
        rightContent: <IHCScore0 />
      }
    ]
  },
  Hercep: {
    layout: 'dna',
    slides: [
      {
        name: 'SCORE 3+',
        thumb: HercepTestThumb01,
        leftContent: LeftConfig.HER,
        rightContent: <IHCScore3 />
      },
      {
        name: 'SCORE 2+',
        thumb: HercepTestThumb02,
        leftContent: LeftConfig.HER2,
        rightContent: <IHCScore2 />
      },
      {
        name: 'SCORE 1+',
        thumb: HercepTestThumb03,
        leftContent: LeftConfig.HER1,
        rightContent: <IHCScore1 />
      },
      {
        name: 'SCORE 0',
        thumb: HercepTestThumb04,
        leftContent: LeftConfig.HER0,
        rightContent: <IHCScore0 />
      }
    ]
  },
  SPOTLight: {
    layout: 'dna',
    slides: [
      {
        name: 'AVERAGE HER2 COPY NUMBER ≥6 SIGNALS/CELL',
        thumb: SPOTLightThumb01,
        leftContent: LeftConfig.SPT1,
        customWidthClass: 'spotlight-img',
        rightContent: <ISHGreaterThanSix />
      },
      {
        name: 'AVERAGE HER2 COPY NUMBER ≥4 AND <6.0 SIGNALS/CELL',
        thumb: SPOTLightThumb02,
        leftContent: LeftConfig.SPT2,
        customWidthClass: 'spotlight-img',
        rightContent: <ISHBetweenSixAndFour />
      },
      {
        name: 'AVERAGE HER2 COPY NUMBER <4 SIGNALS/CELL',
        thumb: SPOTLightThumb03,
        leftContent: LeftConfig.SPT3,
        customWidthClass: 'spotlight-img',
        rightContent: <ISHLessThanFour />
      }
    ]
  },
  PathVysion: {
    layout: 'dna',
    slides: [
      {
        name: 'GROUP 1',
        thumb: PathVysionThumb01,
        leftContent: LeftConfig.PATH1,
        rightContent: <ISHGroup1 />
      },
      {
        name: 'GROUP 2',
        thumb: PathVysionThumb02,
        leftContent: LeftConfig.PATH2,
        rightContent: <ISHGroup2 />
      },
      {
        name: 'GROUP 3',
        thumb: PathVysionThumb03,
        leftContent: LeftConfig.PATH3,
        rightContent: <ISHGroup3 />
      },
      {
        name: 'GROUP 4',
        thumb: PathVysionThumb04,
        leftContent: LeftConfig.PATH4,
        rightContent: <ISHGroup4 />
      },
      {
        name: 'GROUP 5',
        thumb: PathVysionThumb05,
        leftContent: LeftConfig.PATH5,
        rightContent: <ISHGroup5 />
      }
    ]
  },
  HER2IQFish: {
    layout: 'dna',
    slides: [
      {
        name: 'GROUP 1',
        thumb: HER2IQFishThumb01,
        leftContent: LeftConfig.HERIQ1,
        rightContent: <ISHGroup1HER2 />
      },
      {
        name: 'GROUP 2',
        thumb: HER2IQFishThumb02,
        leftContent: LeftConfig.HERIQ2,
        rightContent: <ISHGroup2 />
      },
      {
        name: 'GROUP 3',
        thumb: HER2IQFishThumb03,
        leftContent: LeftConfig.HERIQ3,
        rightContent: <ISHGroup3 />
      },
      {
        name: 'GROUP 4',
        thumb: HER2IQFishThumb04,
        leftContent: LeftConfig.HERIQ4,
        rightContent: <ISHGroup4 />
      },
      {
        name: 'GROUP 5',
        thumb: HER2IQFishThumb05,
        leftContent: LeftConfig.HERIQ5,
        rightContent: <ISHGroup5 />
      }
    ]
  },
  ISHVentana: {
    layout: 'dna',
    slides: [
      {
        name: 'GROUP 1',
        thumb: VentanaHER2Thumb01,
        leftContent: LeftConfig.VENHER1,
        rightContent: <ISHGroup1 />
      },
      {
        name: 'GROUP 2',
        thumb: VentanaHER2Thumb02,
        leftContent: LeftConfig.VENHER2,
        rightContent: <ISHGroup2 />
      },
      {
        name: 'GROUP 3',
        thumb: VentanaHER2Thumb03,
        leftContent: LeftConfig.VENHER3,
        rightContent: <ISHGroup3 />
      },
      {
        name: 'GROUP 4',
        thumb: VentanaHER2Thumb04,
        leftContent: LeftConfig.VENHER4,
        rightContent: <ISHGroup4 />
      },
      {
        name: 'GROUP 5',
        thumb: VentanaHER2Thumb05,
        leftContent: LeftConfig.VENHER5,
        rightContent: <ISHGroup5 />
      }
    ]
  },
  NGSFountation: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeExplained />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'FoundationOne<sup>&reg;</sup> CDx '}
        subtitle={'(Foundation Medicine Inc)'}
        topContent={'FDA-approved companion diagnostic for several tumors, including breast cancer'}
        bullets={[
          'Detects alterations in 324 genes, including HER2 gene amplification',
          'Turnaround time is typically 12 days or less from receipt of specimen'
        ]}
        blueBar={
          'In case of insufficient tumor tissue, reflex to FoundationOne<sup>&reg;</sup>Liquid CDx (liquid biopsy).'
        }
        bottomContent={
          'Clinical validity for HER2 testing with FoundationOne<sup>&reg;</sup> CDx was established through concordance with the Dako HER2 FISH pharmDx kit.'
        }
        printer={true}
      />
    )
  },
  NGSTempusXT: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeTitle />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'TEMPUS xT Gene Panel '}
        subtitle={'(Tempus)'}
        bullets={[
          'RNA and DNA sequencing detects alterations in 648 genes, including HER2 gene amplification',
          'Turnaround time is typically 14 days from receipt of specimen',
          'TEMPUS xT Gene Panel is an LDT. LDTs are not FDA approved'
        ]}
        blueBar={
          'In case of insufficient tumor tissue, reflex to the TEMPUS xF Gene Panel (liquid biopsy).'
        }
        printer={true}
      />
    )
  },
  NGSCarisMolecular: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeTitle />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'Caris Molecular Intelligence<sup>&reg;</sup>&nbsp;'}
        subtitle={'(Caris Life Sciences<sup>&reg;</sup>)'}
        bullets={[
          'Tumor-specific customized testing for breast cancer includes NGS and reflex IHC, when copy<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;number alteration (CNA) is amplified, and CISH testing, when IHC is equivocal (IHC 2+)',
          'Turnaround time is typically 10 to 14 days from receipt of specimen',
          'Caris Molecular Intelligence<sup>&reg;</sup> is an LDT. LDTs are not FDA approved'
        ]}
        printer={true}
      />
    )
  },
  LiquidGuardant360: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeNotConclusive />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'Guardant360<sup>&reg;</sup> CDx '}
        subtitle={'(Guardant Health, Inc)'}
        topContent={'First FDA-approved comprehensive liquid biopsy for all advanced solid tumors'}
        bullets={[
          'Detects alterations in 55 genes, including HER2 amplification',
          'Turnaround time is typically 7 days from receipt of specimen'
        ]}
        bottomContent={
          'Guardant360<sup>&reg;</sup> CDx is an FDA-approved companion diagnostic in NSCLC.'
        }
        printer={true}
      />
    )
  },
  LiquidFoundationOne: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeNotConclusive />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'FoundationOne<sup>&reg;</sup>Liquid CDx '}
        subtitle={'(Foundation Medicine Inc)'}
        topContent={
          'FDA-approved companion diagnostic in 4 tumor types, including breast cancer (for PIK3CA mutation)'
        }
        bullets={[
          'Detects alterations in >311 genes, including HER2 amplification',
          'Turnaround time is typically 10 days or less from receipt of specimen'
        ]}
        printer={true}
      />
    )
  },
  LiquidTempusXF: {
    layout: 'printer',
    slides: [
      {
        name: 'HER2-POSITIVE',
        leftContent: LeftConfig.HER2PosEligible,
        rightContent: <HER2Positive />
      },
      {
        name: 'HER2-NEGATIVE',
        leftContent: LeftConfig.HER2NegEligible,
        rightContent: <HER2NegativeNotConclusive />
      }
    ],
    topContent: (
      <LandingTopSection
        title={'TEMPUS xF Gene Panel '}
        subtitle={'(Tempus)'}
        bullets={[
          'Detects alterations in 105 genes, including HER2 amplification',
          'Turnaround time is typically 14 days from receipt of specimen',
          'Tempus xF is an LDT. LDTs are not FDA approved'
        ]}
        printer={true}
      />
    )
  },
  LungLiquidNGSTestReport: {
    layout: 'dna',
    slides: [
      {
        name: 'GROUP 1',
        thumb: VentanaHER2Thumb01,
        leftContent: LeftConfig.VENHER1,
        rightContent: <ISHGroup1 />
      }
    ]
  }
};

export default SlidesConfig;
