import React from 'react';
import TopSection from '../common/TopSection';

const IHCTopSection = ({ title, subtitle, bullets }) => (
  <TopSection
    title={title}
    subtitle={subtitle}
    bullets={bullets}
    bottomContent={
      'IHC measures the level of HER2 protein expression, evaluated as intensity of membranous staining pattern, reported as a score of 0 to 3+.'
    }
  />
);

export default IHCTopSection;
