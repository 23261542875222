import BondOracleImage1 from '../../images/tabs/BondOracle/01_Bond Oracle_Tab image_v2.png';
import BondOracleImage2 from '../../images/tabs/BondOracle/02_Bond Oracle_Tab image_v2.png';
import BondOracleImage3 from '../../images/tabs/BondOracle/03_Bond Oracle_Tab image_v2.png';
import BondOracleImage4 from '../../images/tabs/BondOracle/04_Bond Oracle_Tab image_v2.png';

import HER2FishIQImage1 from '../../images/tabs/HER2FishIQ/01_HER2 IQFISH_Tab image.png';
import HER2FishIQImage2 from '../../images/tabs/HER2FishIQ/02_HER2 IQFISH_Tab image.png';
import HER2FishIQImage3 from '../../images/tabs/HER2FishIQ/03_HER2 IQFISH_Tab image.png';
import HER2FishIQImage4 from '../../images/tabs/HER2FishIQ/04_HER2 IQFISH_Tab image.png';
import HER2FishIQImage5 from '../../images/tabs/HER2FishIQ/05_HER2 IQFISH_Tab image.png';

import HercepTestImage1 from '../../images/tabs/HercepTest/01_HercepTest_Tab image_v2.png';
import HercepTestImage2 from '../../images/tabs/HercepTest/02_HercepTest_Tab image_v2.png';
import HercepTestImage3 from '../../images/tabs/HercepTest/03_HercepTest_Tab image_v2.png';
import HercepTestImage4 from '../../images/tabs/HercepTest/04_HercepTest_Tab image_v2.png';

import PathVysionImage1 from '../../images/tabs/PathVysion/01_PathVysion_Tab image.png';
import PathVysionImage2 from '../../images/tabs/PathVysion/02_PathVysion_Tab image.png';
import PathVysionImage3 from '../../images/tabs/PathVysion/03_PathVysion_Tab image.png';
import PathVysionImage4 from '../../images/tabs/PathVysion/04_PathVysion_Tab image.png';
import PathVysionImage5 from '../../images/tabs/PathVysion/05_PathVysion_Tab image.png';

import SPOTLightImage1 from '../../images/tabs/SPOTLight/01_SPOT-Light_Tab image_v2.png';
import SPOTLightImage2 from '../../images/tabs/SPOTLight/02_SPOT-Light_Tab image_v2.png';
import SPOTLightImage3 from '../../images/tabs/SPOTLight/03_SPOT-Light_Tab image_v2.png';

import VentanaICHImage1 from '../../images/tabs/Ventana/01_VENTANA_Tab image_v2.png';
import VentanaICHImage2 from '../../images/tabs/Ventana/02_VENTANA_Tab image_v2.png';
import VentanaICHImage3 from '../../images/tabs/Ventana/03_VENTANA_Tab image_v2.png';
import VentanaICHImage4 from '../../images/tabs/Ventana/04_VENTANA_Tab image_v2.png';

import VentanaHER2Image1 from '../../images/tabs/VentanaHER2/01_VENTANA HER2 Dual ISH_Tab image.png';
import VentanaHER2Image2 from '../../images/tabs/VentanaHER2/02_VENTANA HER2 Dual ISH_Tab image.png';
import VentanaHER2Image3 from '../../images/tabs/VentanaHER2/03_VENTANA HER2 Dual ISH_Tab image.png';
import VentanaHER2Image4 from '../../images/tabs/VentanaHER2/04_VENTANA HER2 Dual ISH_Tab image.png';
import VentanaHER2Image5 from '../../images/tabs/VentanaHER2/05_VENTANA HER2 Dual ISH_Tab image.png';

import PrinterCheckImage from '../../images/Sequencing machine_Check mark.png';
import PrinterXImage from '../../images/Sequencing machine_X mark.png';

const LeftConfig = {
  VET: {
    img: VentanaICHImage1,
    caption:
      'Circumferential membrane staining that is complete, intense, and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  VET2: {
    img: VentanaICHImage2,
    caption: 'Weak to moderate complete membrane staining observed in >10% of tumor cells',
    footer:
      '*Alternatively, order a new IHC or ISH with a different specimen. <br/> Contributed by Dr David G. Hicks ',
    alignmentClass: 'ish-content-centered'
  },
  VET1: {
    img: VentanaICHImage3,
    caption:
      'Incomplete membrane staining that is faint/barely </br> perceptible and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  VET0: {
    img: VentanaICHImage4,
    caption:
      'No staining is observed or membrane staining that is incomplete and is faint/barely perceptible </br> and in &le;10% of tumor cells ',
    footer: 'Contributed by Dr Savitri Krishnamurthy',
    alignmentClass: 'ish-content-centered'
  },
  BOND: {
    img: BondOracleImage1,
    caption:
      'Circumferential membrane staining that is complete, intense, and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  BOND2: {
    img: BondOracleImage2,
    caption: 'Weak to moderate complete membrane staining </br> observed in >10% of tumor cells',
    footer:
      '*Alternatively, order a new IHC or ISH with a different specimen. <br/> Contributed by Dr David G. Hicks ',
    alignmentClass: 'ish-content-centered'
  },
  BOND1: {
    img: BondOracleImage3,
    caption:
      'Incomplete membrane staining that is faint/barely </br> perceptible and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  BOND0: {
    img: BondOracleImage4,
    caption:
      'No staining is observed or membrane staining that is incomplete and is faint/barely perceptible </br> and in &le;10% of tumor cells ',
    footer: 'Contributed by Dr Savitri Krishnamurthy',
    alignmentClass: 'ish-content-centered'
  },
  HER: {
    img: HercepTestImage1,
    caption:
      'Circumferential membrane staining that is complete, intense, and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  HER2: {
    img: HercepTestImage2,
    caption: 'Weak to moderate complete membrane staining observed in >10% of tumor cells',
    footer:
      '*Alternatively, order a new IHC or ISH with a different specimen. <br/> Contributed by Dr David G. Hicks ',
    alignmentClass: 'ish-content-centered'
  },
  HER1: {
    img: HercepTestImage3,
    caption:
      'Incomplete membrane staining that is faint/barely perceptible and in >10% of tumor cells ',
    footer: 'Contributed by Dr David G. Hicks',
    alignmentClass: 'ish-content-centered'
  },
  HER0: {
    img: HercepTestImage4,
    caption:
      'No staining is observed or membrane staining that is incomplete and is faint/barely perceptible </br> and in &le;10% of tumor cells ',
    footer: 'Contributed by Dr Savitri Krishnamurthy',
    alignmentClass: 'ish-content-centered'
  },
  SPT1: {
    img: SPOTLightImage1,
    caption: 'HER2 signal seen as dot or cluster',
    alignmentClass: 'ish-content-centered'
  },
  SPT2: {
    img: SPOTLightImage2,
    caption: 'HER2 signal seen as dot or cluster',
    alignmentClass: ''
  },
  SPT3: {
    img: SPOTLightImage3,
    caption: 'HER2 signal seen as dot or cluster</br>',
    alignmentClass: 'ish-content-centered'
  },
  PATH1: {
    img: PathVysionImage1,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: 'ish-content-centered'
  },
  PATH2: {
    img: PathVysionImage2,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in the small subset of cases with a HER2/CEP17 ratio ≥2 and an average HER2 copy number <4 per cell.',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  PATH3: {
    img: PathVysionImage3,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in cases with a HER2 ratio of <2 in the absence of protein overexpression (IHC 3+).',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  PATH4: {
    img: PathVysionImage4,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, it is uncertain whether patients with an average of ≥4 and <6 HER2 signals/cell and a HER2/CEP17 ratio of <2 benefit from HER2-targeted therapy in the absence of protein overexpression (IHC 3+).',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  PATH5: {
    img: PathVysionImage5,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: 'ish-content-centered'
  },
  HERIQ1: {
    img: HER2FishIQImage1,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: 'ish-content-centered'
  },
  HERIQ2: {
    img: HER2FishIQImage2,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in the small subset of cases with a HER2/CEP17 ratio ≥2 and an average HER2 copy number <4 per cell.',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  HERIQ3: {
    img: HER2FishIQImage3,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in cases with a HER2 ratio of <2 in the absence of protein overexpression (IHC 3+).',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  HERIQ4: {
    img: HER2FishIQImage4,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, it is uncertain whether patients with an average of ≥4 and <6 HER2 signals/cell and a HER2/CEP17 ratio of <2 benefit from HER2-targeted therapy in the absence of protein overexpression (IHC 3+).',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: ''
  },
  HERIQ5: {
    img: HER2FishIQImage5,
    caption: 'HER2 signal seen as orange/red dot or cluster</br> CEP17 signal seen as green dot',
    footer:
      'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
    alignmentClass: 'ish-content-centered'
  },
  VENHER1: {
    img: VentanaHER2Image1,
    caption: 'HER2 signal seen as black dot or cluster</br> CEP17 signal seen as red dot',
    alignmentClass: 'ish-content-centered'
  },
  VENHER2: {
    img: VentanaHER2Image2,
    caption: 'HER2 signal seen as black dot or cluster</br> CEP17 signal seen as red dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in the small subset of cases with a HER2/CEP17 ratio ≥2 and an average HER2 copy number <4 per cell.',
    alignmentClass: ''
  },
  VENHER3: {
    img: VentanaHER2Image3,
    caption: 'HER2 signal seen as black dot or cluster</br> CEP17 signal seen as red dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, evidence is limited on the efficacy of HER2-targeted therapy in cases with a HER2 ratio of <2 in the absence of protein overexpression (IHC 3+).',
    alignmentClass: ''
  },
  VENHER4: {
    img: VentanaHER2Image4,
    caption: 'HER2 signal seen as black dot or cluster</br> CEP17 signal seen as red dot',
    hiddenFooter:
      '*According to ASCO/CAP guidelines, it is uncertain whether patients with an average of ≥4 and <6 HER2 signals/cell and a HER2/CEP17 ratio of <2 benefit from HER2-targeted therapy in the absence of protein overexpression (IHC 3+).',
    alignmentClass: ''
  },
  VENHER5: {
    img: VentanaHER2Image5,
    caption: 'HER2 signal seen as black dot or cluster</br> CEP17 signal seen as red dot',
    alignmentClass: 'ish-content-centered'
  },
  HER2PosEligible: {
    img: PrinterCheckImage
  },
  HER2NegEligible: {
    img: PrinterXImage
  }
};

export default LeftConfig;
