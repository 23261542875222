import React, { useState } from 'react';
import Bullets from '../../common/Bullets';
import BulletTable from '../../common/BulletTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'react-modal';

const TopSection = ({
  title,
  subtitle,
  topContent,
  blueBar,
  greyContent,
  bullets,
  bulletTable,
  bottomContent,
  learnMore,
  learnMoreAlt,
  printer
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const afterOpenModal = () => {
    let containerFontSize = document.getElementById('wirewax-container').style.fontSize;
    let containerWidth = document.getElementById('wirewax-container').offsetWidth;
    let modalContainers = document.getElementsByClassName('modal-container');
    for (let modalContainer of modalContainers) {
      modalContainer.style.fontSize = containerFontSize;
    }
    let parentModalContainers = document.getElementsByClassName('modal');
    for (let parentModalContainer of parentModalContainers) {
      parentModalContainer.style.width = 'calc(' + containerWidth + 'px - 10%)';
    }
  };

  return (
    <div className="topsection-container">
      <div className="title-container">
        <span className="title" dangerouslySetInnerHTML={{ __html: title }} />
        <span className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      {printer ? (
        <>
          {' '}
          {topContent && <div>{topContent}</div>}
          {bullets && <Bullets bullets={bullets} />}
          {blueBar && <div className="bar blue-bg" dangerouslySetInnerHTML={{ __html: blueBar }} />}
          {bottomContent && <div dangerouslySetInnerHTML={{ __html: bottomContent }} />}
        </>
      ) : (
        <>
          {' '}
          {topContent && <div dangerouslySetInnerHTML={{ __html: topContent }} />}
          {bullets && <Bullets bullets={bullets} />}
          {bulletTable && <BulletTable bullets={bulletTable} />}
          {blueBar && <div className="bar blue-bg" dangerouslySetInnerHTML={{ __html: blueBar }} />}
          {bottomContent && <div dangerouslySetInnerHTML={{ __html: bottomContent }} />}
        </>
      )}

      {learnMore && (
        <>
          <div className="learn-more-container" onClick={() => setIsModalOpen(true)}>
            <div className="learn">{learnMoreAlt ? learnMoreAlt : 'LEARN MORE'}</div>
            <FontAwesomeIcon icon={faCircleInfo} />
          </div>
          <Modal
            isOpen={isModalOpen}
            onAfterOpen={afterOpenModal}
            className="modal"
            overlayClassName="modal-overlay"
            shouldFocusAfterRender={false}
            onRequestClose={() => setIsModalOpen(false)}
          >
            <div className="modal-container">
              <div
                className="modal-inner-container"
                dangerouslySetInnerHTML={{ __html: learnMore }}
              />
              <FontAwesomeIcon
                className="modal-close"
                icon={faXmark}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </Modal>
        </>
      )}
      {greyContent && (
        <>
          <div className="grey-content-container">
            <p dangerouslySetInnerHTML={{ __html: greyContent }} />
          </div>
        </>
      )}
    </div>
  );
};

export default TopSection;
