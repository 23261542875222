import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'react-modal';
import EffectCarousel from '../effect-carousel.esm';
import GroupsConfig from '../config/groups';
import Bullets from '../components/common/Bullets';
import TrainingWarning from '../components/common/TrainingWarning';
import Buttons from '../components/landing/common/Buttons';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/home.css';

const Home = ({
  ihcInitialSlide,
  setIHCInitialSlide,
  ishInitialSlide,
  setISHInitialSlide,
  tissueInitialSlide,
  setTissueInitialSlide,
  liquidInitialSlide,
  setLiquidInitialSlide,
  lungTissueInitialSlide,
  setLungTissueInitialSlide,
  lungLiquidInitialSlide,
  setLungLiquidInitialSlide,
  videoEl
}) => {
  const { group } = useParams();
  const { title, topContent, bullets, blueBar, bottomContent, homeLearnMoreModal, test } =
    GroupsConfig[group].home;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);

  const afterOpenModal = () => {
    let containerFontSize = document.getElementById('wirewax-container').style.fontSize;
    let containerWidth = document.getElementById('wirewax-container').offsetWidth;
    let modalContainers = document.getElementsByClassName('modal-container');
    for (let modalContainer of modalContainers) {
      modalContainer.style.fontSize = containerFontSize;
    }
    let parentModalContainers = document.getElementsByClassName('modal');
    for (let parentModalContainer of parentModalContainers) {
      parentModalContainer.style.width = 'calc(' + containerWidth + 'px - 10%)';
    }
  };

  const getInitialSlide = () => {
    switch (group) {
      case '0':
        return ihcInitialSlide;
      case '1':
        return ishInitialSlide;
      case '2':
        return tissueInitialSlide;
      case '3':
        return liquidInitialSlide;
      case '4':
        return lungTissueInitialSlide;
      case '5':
        return lungLiquidInitialSlide;
      default:
        return 0;
    }
  };

  const setInitialSlide = (i) => {
    switch (group) {
      case '0':
        return setIHCInitialSlide(i);
      case '1':
        return setISHInitialSlide(i);
      case '2':
        return setTissueInitialSlide(i);
      case '3':
        return setLiquidInitialSlide(i);
      case '4':
        return setLungTissueInitialSlide(i);
      case '5':
        return setLungLiquidInitialSlide(i);
      default:
        return;
    }
  };

  if (group === '4' || group === '5') {
    return (
      //TODO: edit inline styles to css
      <div className="home-container app" style={{ gap: '0.8em' }}>
        <div className="title">{title}</div>
        <div
          className="content"
          style={{ maxWidth: '80%', width: '100%', margin: '0 auto' }}
          dangerouslySetInnerHTML={{ __html: topContent }}
        />
        {bullets && <Bullets bullets={bullets} />}
        {blueBar && <div className="bar blue-bg">{blueBar}</div>}
        <div
          className="content smaller"
          style={{ maxWidth: '80%', width: '100%', margin: '0 auto' }}
        >
          {bottomContent}
        </div>
        <div className="test-container content" style={{ marginTop: 0 }}>
          <span className="green-text">SELECT A TEST</span> TO LEARN MORE
        </div>
        <div className={`swiper-container ${isSwiperLoaded ? 'show-swiper' : ''}`}>
          <Swiper
            initialSlide={getInitialSlide()}
            effect={'carousel'}
            slidesPerView={
              GroupsConfig[group].assays.length > 3 ? GroupsConfig[group].assays.length : 4
            }
            grabCursor={true}
            loop={true}
            spaceBetween={1}
            pagination={{
              clickable: true,
              el: '.swiper-pagination'
            }}
            navigation={true}
            modules={[Navigation, Pagination, EffectCarousel]}
            className="mySwiper"
            onAfterInit={(swiper) => {
              setTimeout(() => {
                setIsSwiperLoaded(true);
              }, 300);
            }}
          >
            {GroupsConfig[group].assays.map((assay, i) => {
              let gtagAttrs = {};
              if (assay.gtagId !== undefined && assay.gtagText !== undefined) {
                gtagAttrs = {
                  'id': assay.gtagId,
                  'text': assay.gtagText
                };
              }
              return (
                <SwiperSlide key={i}>
                  <Link
                    {...gtagAttrs}
                    to={`/landing/${group}/${i}`}
                    onClick={() => {
                      setInitialSlide(i);
                      videoEl.current[assay.landing.videoIndex].currentTime = assay.landing.time;
                      videoEl.current[assay.landing.videoIndex].pause();
                      // window.wirewax.triggerEvent(window.wirewax.events.triggers.GO_TO_TAG, assay.landing.seek);
                    }}
                  >
                    <img src={assay.img} alt="" />
                  </Link>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="swiper-pagination" />
        </div>
        <div style={{ width: '80%', margin: '0 auto' }}>
          <Buttons
            buttons={GroupsConfig[group].home.homeButtons}
            videoEl={videoEl}
            containerClass={'flex-nowrap margin-top-10'}
          />
        </div>
        {homeLearnMoreModal && (
          <div className="home-learn-more">
            <div className="smaller learn-more-caption">{homeLearnMoreModal.caption}</div>
            <>
              <div id="Learn_More_PD_L1_Select_Option" text="Learn_More_PD_L1_Select_Option" className="learn-more-container" onClick={() => setIsModalOpen(true)}>
                <div className="learn">{homeLearnMoreModal.buttonTitle}</div>
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
              <Modal
                isOpen={isModalOpen}
                onAfterOpen={afterOpenModal}
                className="modal bigger-modal"
                overlayClassName="modal-overlay"
                shouldFocusAfterRender={false}
                onRequestClose={() => setIsModalOpen(false)}
              >
                <div className="modal-container">
                  <div className="modal-title">{homeLearnMoreModal.title}</div>
                  <div className="home-modal-inner-container">
                    <img src={homeLearnMoreModal.img} alt="" className="modal-image" />
                  </div>
                  <FontAwesomeIcon
                    className="modal-close"
                    icon={faXmark}
                    onClick={() => setIsModalOpen(false)}
                  />
                </div>
              </Modal>
            </>
          </div>
        )}
        <TrainingWarning isTrainingLabelOpen={true} />
      </div>
    );
  }

  return (
    <div className="home-container app">
      <div className="title">{title}</div>
      <div className="content">{topContent}</div>
      {bullets && <Bullets bullets={bullets} />}
      {blueBar && <div className="bar blue-bg">{blueBar}</div>}
      <div className="content smaller">{bottomContent}</div>
      <div className="test-container content">
        <span className="green-text">SELECT A TEST</span> TO LEARN MORE ABOUT HER2 TESTING WITH{' '}
        {test}
      </div>
      <div className={`swiper-container ${isSwiperLoaded ? 'show-swiper' : ''}`}>
        <Swiper
          initialSlide={getInitialSlide()}
          effect={'carousel'}
          slidesPerView={GroupsConfig[group].assays.length}
          grabCursor={true}
          loop={true}
          spaceBetween={1}
          pagination={{
            clickable: true,
            el: '.swiper-pagination'
          }}
          navigation={true}
          modules={[Navigation, Pagination, EffectCarousel]}
          className="mySwiper"
          onAfterInit={(swiper) => {
            setTimeout(() => {
              setIsSwiperLoaded(true);
            }, 300);
          }}
        >
          {GroupsConfig[group].assays.map((assay, i) => {
            let gtagAttrs = {};
            if (assay.gtagId !== undefined && assay.gtagText !== undefined) {
              gtagAttrs = {
                'id': assay.gtagId,
                'text': assay.gtagText
              };
            }
            return (
              <SwiperSlide key={i}>
                <Link
                  {...gtagAttrs}
                  to={`/landing/${group}/${i}`}
                  onClick={() => {
                    setInitialSlide(i);
                    videoEl.current[assay.landing.videoIndex].currentTime = assay.landing.time;
                    videoEl.current[assay.landing.videoIndex].pause();
                    // window.wirewax.triggerEvent(window.wirewax.events.triggers.GO_TO_TAG, assay.landing.seek);
                  }}
                >
                  <img src={assay.img} alt="" />
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="swiper-pagination" />
      </div>
      <TrainingWarning isTrainingLabelOpen={true} />
    </div>
  );
};

export default Home;
