import './App.css';
import { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { AuthProvider } from 'oidc-react';
import { Context } from './hooks';
import Landing from './pages/Landing';
import Wall from './pages/Wall';
import Home from './pages/Home';
import Menu from './components/Menu';
import References from './components/References';
import Glossary from './components/Glossary';
import Video from './pages/Video';
import WirewaxListener from './components/WirewaxListener';
import TrainingWarning from './components/common/TrainingWarning';
import PieChart from './components/PieChart';
import LungWall from './pages/LungWall';
import VideoPlayer from './components/VideoPlayer';

import NewPosterImg from '../src/images/Lung_Frame_0_A.png';

import introAnimVideo from './images/videos/Anemia_+_DSI_Breast_+_Lung_Combined_v2.mp4';
import breastVideo from './images/videos/DSI_Breast_Cancer_Tool_v21.mp4';
import lungVideo from './images/videos/DSI_Lung_Interactive_Tool_v19.mp4';
import anemiaVideo from './images/videos/MEI-DSI-13604_Anemia_Interactive_Learning_Tool_Part01-03_v09.mp4';

const oidcConfig = {
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  clientId: 'DSI',
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URL
};

function App() {
  const [footerOpen, setFooterOpen] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);
  const [isTrainingLabelOpen, setTrainingLabelOpen] = useState(false);
  const [ihcInitialSlide, setIHCInitialSlide] = useState(0);
  const [ishInitialSlide, setISHInitialSlide] = useState(0);
  const [tissueInitialSlide, setTissueInitialSlide] = useState(0);
  const [liquidInitialSlide, setLiquidInitialSlide] = useState(0);

  const [lungTissueInitialSlide, setLungTissueInitialSlide] = useState(0);
  const [lungLiquidInitialSlide, setLungLiquidInitialSlide] = useState(0);

  const [initialPlay, setInitialPlay] = useState(true);
  const videoEl = useRef([]);

  const videoUrls = [introAnimVideo, breastVideo, lungVideo, anemiaVideo];
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [videosPreloaded, setVideosPreloaded] = useState(false);
  const videoElements = [];

  useEffect(() => {
    Promise.all(
      videoUrls.map((url) => {
        return new Promise((resolve, reject) => {
          const videoElement = document.createElement('video');
          videoElement.src = url;
          videoElement.preload = 'auto';

          videoElement.addEventListener('loadeddata', () => {
            resolve();
          });

          videoElement.addEventListener('error', (event) => {
            reject(event.error || new Error('Failed to preload video.'));
          });

          videoElements.push(videoElement);
        });
      })
    )
      .then(() => {
        console.log('Videos preloaded successfully');
        setVideosPreloaded(true);
      })
      .catch((error) => {
        console.error('Error preloading videos:', error);
      });

    return () => {
      videoElements.forEach((videoElement) => {
        if (videoElement.parentNode) {
          videoElement.parentNode.removeChild(videoElement);
        }
      });
    };
  }, []);

  const afterOpenModal = () => {
    let containerFontSize = document.getElementById('wirewax-container').style.fontSize;
    let containerWidth = document.getElementById('wirewax-container').offsetWidth;
    let modalContainers = document.getElementsByClassName('references-modal-container');
    for (let modalContainer of modalContainers) {
      modalContainer.style.fontSize = containerFontSize;
    }
    let parentModalContainers = document.getElementsByClassName('reference-modal');
    for (let parentModalContainer of parentModalContainers) {
      parentModalContainer.style.width = 'calc(' + containerWidth + 'px - 10%)';
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log(
  //       'CURRENT VIDEO TIME: ',
  //       videoEl.current[activeVideoIndex]?.currentTime,
  //       activeVideoIndex
  //     );
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [activeVideoIndex]);

  useEffect(() => {
    //window.wirewax.playerId = 'wirewax-player';

    function handleResize() {
      const wirewaxContainerEl = document.getElementById('wirewax-container');
      if (window.innerWidth / window.innerHeight > 1.77) {
        wirewaxContainerEl.style.height = '100%';
        wirewaxContainerEl.style.width = 'unset';
      } else {
        wirewaxContainerEl.style.height = 'unset';
        wirewaxContainerEl.style.width = '100%';
      }

      wirewaxContainerEl.style.fontSize = `${wirewaxContainerEl.offsetWidth * 0.008334}px`;
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AuthProvider {...oidcConfig}>
      <Context.Provider value={{ footerOpen, setFooterOpen }}>
        <Router>
          <div id="wirewax-container" className="wirewax-container">
            {activeVideoIndex === 3 && (
              <div
                id="Glossary_Anemia"
                text="Glossary_Anemia"
                className="glossary-hotspot"
                onClick={() => setIsGlossaryOpen(!isGlossaryOpen)}
              />
            )}
            <div id="References" text="References" className="references-hotspot" onClick={() => setIsModalOpen(!isModalOpen)} />
            <div id="Hamburger_Menu_Select" text="Hamburger_Menu_Select" className="hambuger-hotspot" onClick={() => setMenuOpen(!menuOpen)} />
            <div
              id="DSI_Constant_Logo"
              text="DSI_Constant_Logo"
              onClick={() => {
                videoEl.current[activeVideoIndex].pause();
                videoEl.current[0].currentTime = 14;
                setActiveVideoIndex(0);
              }}
              style={{
                position: 'absolute',
                top: '3%',
                left: '1%',
                width: '8%',
                height: '12%',
                zIndex: 9999,
                cursor: 'pointer'
              }}
            />
            <div className={`${menuOpen ? 'menu-open' : ''} hamburger-container`}>
              <Menu
                setMenuOpen={setMenuOpen}
                videoEl={videoEl}
                setOverlayOpen={setOverlayOpen}
                setActiveVideoIndex={setActiveVideoIndex}
                activeVideoIndex={activeVideoIndex}
              />
            </div>
            <TrainingWarning isTrainingLabelOpen={isTrainingLabelOpen} />
            {initialPlay && (
              <div
                id="Click_to_Proceed"
                text="Click to Proceed"
                style={{
                  height: '100vh',
                  width: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  backgroundColor: 'rgba(0,0,0,0.0)',
                  zIndex: 9999
                }}
                onClick={() => {
                  videoEl.current[activeVideoIndex].play();
                  setInitialPlay(false);
                }}
              ></div>
            )}
            <VideoPlayer
              videoUrls={videoUrls}
              videoEl={videoEl}
              activeVideoIndex={activeVideoIndex}
              setActiveVideoIndex={setActiveVideoIndex}
              videosPreloaded={videosPreloaded}
              setVideosPreloaded={setVideosPreloaded}
              setOverlayOpen={setOverlayOpen}
              overlayOpen={overlayOpen}
              posterImg={NewPosterImg}
            />
            {/* <video
              id="wirewax-player"
              ref={videoEl}
              style={{
                height: '100%',
                width: '100%'
              }}
              src={
                process.env.REACT_APP_BRANDING === 'blinded'
                  ? 'https://d59nx84ake9tn.cloudfront.net/dsi/DSIBreast%2BLungCombined_v4.mp4'
                  : 'https://d59nx84ake9tn.cloudfront.net/dsi/DSIBreast%2BLungCombined_v4.mp4'
              } //NEED TO GET BRANDED VIDEO
              poster={NewPosterImg} //need to get cloudfront links
            ></video> */}
            {/* <WirewaxListener
              setOverlayOpen={setOverlayOpen}
              overlayOpen={overlayOpen}
              videoEl={videoEl}
              isTrainingLabelOpen={isTrainingLabelOpen}
              setTrainingLabelOpen={setTrainingLabelOpen}
              activeVideoIndex={activeVideoIndex}
            /> */}
            <div className={`assay-container ${overlayOpen ? 'overlayOpen' : ''}`}>
              {/* <div className={`assay-container overlayOpen`}> */}
              <Routes>
                <Route path="/" element={<Video />} />
                <Route
                  path="/home/:group"
                  element={
                    <Home
                      ihcInitialSlide={ihcInitialSlide}
                      setIHCInitialSlide={setIHCInitialSlide}
                      ishInitialSlide={ishInitialSlide}
                      setISHInitialSlide={setISHInitialSlide}
                      tissueInitialSlide={tissueInitialSlide}
                      setTissueInitialSlide={setTissueInitialSlide}
                      liquidInitialSlide={liquidInitialSlide}
                      setLiquidInitialSlide={setLiquidInitialSlide}
                      lungTissueInitialSlide={tissueInitialSlide}
                      setLungTissueInitialSlide={setTissueInitialSlide}
                      lungLiquidInitialSlide={lungLiquidInitialSlide}
                      setLungLiquidInitialSlide={setLungLiquidInitialSlide}
                      videoEl={videoEl}
                    />
                  }
                />
                <Route path="/landing/:group/:assay" element={<Landing videoEl={videoEl} />} />
                <Route path="/wall/:group/:assay/:slide" element={<Wall videoEl={videoEl} />} />
                <Route path="/piechart" element={<PieChart videoEl={videoEl} />} />
                <Route
                  path="/lungwall/:group/:assay/:slide"
                  element={<LungWall videoEl={videoEl} />}
                />
              </Routes>
            </div>
            <Modal
              isOpen={isGlossaryOpen}
              onAfterOpen={afterOpenModal}
              className="reference-modal"
              overlayClassName="modal-overlay"
              shouldFocusAfterRender={false}
              onRequestClose={() => setIsGlossaryOpen(false)}
            >
              <div className="references-modal-container">
                <div className="references-inner-container">
                  <Glossary videoEl={videoEl} activeVideoIndex={activeVideoIndex} />
                </div>
                <FontAwesomeIcon
                  className="reference-modal-close"
                  icon={faXmark}
                  onClick={() => setIsGlossaryOpen(false)}
                />
              </div>
            </Modal>
            <Modal
              isOpen={isModalOpen}
              onAfterOpen={afterOpenModal}
              className="reference-modal"
              overlayClassName="modal-overlay"
              shouldFocusAfterRender={false}
              onRequestClose={() => setIsModalOpen(false)}
            >
              <div className="references-modal-container">
                <div className="references-inner-container">
                  <References videoEl={videoEl} activeVideoIndex={activeVideoIndex} />
                </div>
                <FontAwesomeIcon
                  className="reference-modal-close"
                  icon={faXmark}
                  onClick={() => setIsModalOpen(false)}
                />
              </div>
            </Modal>
          </div>
        </Router>
      </Context.Provider>
    </AuthProvider>
  );
}

export default App;
