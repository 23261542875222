import tempModal from '../../images/lung/test.svg';
import TissueTestReport from '../../images/lung/TissueNGSTestReport.png';
import LiquidTestReport from '../../images/lung/LiquidNGSTestReport2.png';

import Mediastinoscopy from '../../images/lung/wall/Mediastinoscopy.png';
import Thoracoscopy from '../../images/lung/wall/Thoracoscopy.png';
import EBUS from '../../images/lung/wall/EBUS.png';
import EUS from '../../images/lung/wall/EUS.png';
import CTGuidedBiopsy from '../../images/lung/wall/CTGuidedBiopsy.png';

import EGFR from '../../images/lung/wall/EGFR.png';
import EGFRModal from '../../images/lung/wall/EGFRModal.png';
import HER2 from '../../images/lung/wall/HER2.png';
import HER2Modal from '../../images/lung/wall/HER2Modal.png';
import HER3 from '../../images/lung/wall/HER3.png';
import HER3Modal from '../../images/lung/wall/HER3Modal.png';
import TROP2 from '../../images/lung/wall/TROP2.png';
import TROP2Modal from '../../images/lung/wall/TROP2Modal2.png';

import Bone from '../../images/lung/wall/Bone.png';
import ChestXray from '../../images/lung/wall/ChestXray.png';
import CT from '../../images/lung/wall/CT.png';
import MRI from '../../images/lung/wall/MRI.png';
import PETCT from '../../images/lung/wall/PET-CT.png';

import cbcWithDiffImage from '../../images/pg 37-38_Anemia LV14 Part 2-05.png';
import cbcWithoutReflexImage from '../../images/Pg 67-68_Anemia LV14 Part 3-07.png';
import cbcWithReflexImage from '../../images/Pg 80-81-82_Anemia LV14 Part 3-20.png';

import { ExpansionAccordion } from '../../components/DNARightContent';

const HER2AccordionContent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <div style={{ flex: 1, borderRight: '1px solid white', padding: '10px' }}>
        <p style={{ margin: '0px 0px', fontSize: '1.5em', fontWeight: 'bold' }}>
          <strong>
            <i>HER2</i> (<i>ERBB2</i>) mutation:
          </strong>
        </p>
        <p style={{ margin: '8px 0px 0px 0px', fontSize: '1.5em' }}>
          Mutation in the <i>HER2</i> (<i>ERBB2</i>) gene
        </p>
      </div>
      <div style={{ flex: 1, borderRight: '1px solid white', padding: '10px' }}>
        <p style={{ margin: '0px 0px', fontSize: '1.5em' }}>
          <strong>
            <i>HER2</i> (<i>ERBB2</i>) amplification:{' '}
          </strong>
        </p>
        <p style={{ margin: '8px 0px 0px 0px', fontSize: '1.5em' }}>
          Increased number of the <i>HER2</i> (<i>ERBB2</i>) gene relative to normal
        </p>
      </div>
      <div style={{ flex: 1, padding: '10px' }}>
        <p style={{ margin: '0px 0px', fontSize: '1.5em' }}>
          <strong>
            <i>HER2</i> (<i>ERBB2</i>) overexpression:
          </strong>
        </p>
        <p style={{ margin: '8px 0px 0px 0px', fontSize: '1.5em' }}>
          Increase in the amount of <i>HER2</i>&nbsp;&nbsp;protein expressed relative to normal
        </p>
      </div>
    </div>
  );
};

const EGFRContent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        justifyContent: 'center'
      }}
    >
      <div className="bolded-header">
        Epidermal growth factor receptor (EGFR), also known as HER1 (ERBB1), is a member of the ERBB
        family of receptor tyrosine kinases, along with HER2 (ERBB2), HER3 (ERBB3), and HER4 (ERBB4)
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>EGFR</i> (also known as <i>HER1</i>) mutation is one of the most prevalent
                genomic alterations in NSCLC (≈22%)
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>EGFR</i> mutations promote constitutive activation of EGFR and increased
                signaling, which promotes tumor cell growth and survival
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bolded-header">
        EGFR-TKIs are the standard of care for NSCLC with EGFR mutations
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                Acquired resistance to EGFR-TKIs is common, and about half develop <i>T790M</i>{' '}
                resistance mutation
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                Treatment with an EGFR-TKI has been linked to an increase in HER3 expression
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const HER2Content = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        justifyContent: 'center'
      }}
    >
      <div className="bolded-header">
        HER2 (ERBB2) is a member of the ERBB family of receptor tyrosine kinases, along with EGFR,
        also known as HER1 (ERBB1), HER3 (ERBB3), and HER4 (ERBB4)
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>HER2</i> (<i>ERBB2</i>) mutation may cause increased activity of the HER2
                receptor, leading to upregulation of pathways that drive tumor cell growth and
                survival
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>HER2</i> (<i>ERBB2</i>) mutations are rare but aggressive, occurring in ≈2% to 4%
                of NSCLC
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <ExpansionAccordion
        accordionHeading="Different <span>HER2</span> (<span>ERBB2</span>) alterations occur in NSCLC adenocarcinoma"
        accordionContent={<HER2AccordionContent />}
      />
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>HER2 (ERBB2)</i> mutations are actionable in NSCLC
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                Studies suggest that there is no association between <i>HER2</i> (<i>ERBB2</i>) gene
                mutations and <i>HER2</i> (<i>ERBB2</i>) gene amplifications, presumably because
                they are driven by independent mechanisms
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const HER3Content = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        justifyContent: 'center'
      }}
    >
      <div className="bolded-header">
        HER3 (ERBB3) is a member of the ERBB family of receptor tyrosine kinases, along with EGFR,
        also known as HER1 (ERBB1), HER2 (ERBB2), and HER4 (ERBB4)
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                HER3 is activated by heterodimer formation, preferentially with the HER2 receptor,
                activating oncogenic signaling and driving tumor progression
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bolded-header">
        HER3 expression is associated with poor prognosis and resistance to treatment in NSCLC
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                83% of primary NSCLC tumors express HER3, and HER3 is upregulated in metastatic
                tissue
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                <i>HER3</i>&nbsp;&nbsp;is being studied as an actionable target in <i>EGFR</i>m
                NSCLC, regardless of the mechanism of EGFR-TKI resistance or the level of baseline
                HER3 expression
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TROP2Content = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        justifyContent: 'center'
      }}
    >
      <div className="bolded-header">
        Trophoblast cell surface antigen 2 (TROP-2) is a transmembrane glycoprotein that is highly
        expressed in various tumors
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>Several different ligands activating TROP-2 have been proposed</span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>TROP-2 stimulates cell cycle progression and tumor metastasis</span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>Most lung cancer expresses high levels of TROP-2</span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&mdash;
              </span>
            </td>
            <td>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;64% of NSCLC adenocarcinoma and 75% of squamous cell
                carcinoma have high TROP-2 expression
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ position: 'relative' }}>
              <span style={{ position: 'absolute', top: '0px', left: '-8px' }}>&bull;</span>
            </td>
            <td>
              <span>
                High TROP-2 expression is associated with poor prognosis for NSCLC adenocarcinoma
                versus no or low TROP-2 expression
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const lungContentConfig = {
  listData: {
    key: 'list',
    data: [
      {
        title: 'MEDIASTINOSCOPY',
        img: Mediastinoscopy,
        list: [
          'Used to isolate samples from the area between the lungs (the mediastinum)',
          'Performed under general anesthesia',
          'Has high sensitivity and high negative predictive value'
        ],
        gtagID: 'Mediastinoscopy_Tab',
        gtagText: 'Mediastinoscopy_Tab'
      },
      {
        title: 'THORACOSCOPY',
        img: Thoracoscopy,
        list: [
          'Used to determine if cancer has spread to the spaces between the lungs and the chest wall',
          'Uses a small camera (thoracoscope) inserted through the chest wall',
          'Performed under local or general anesthesia'
        ],
        gtagID: 'Thoracoscpy_Tab',
        gtagText: 'Thoracoscpy_Tab'
      },
      {
        title: 'EBUS',
        img: EBUS,
        list: [
          'Uses an endoscope fitted with an ultrasound processor and a fine gauge aspiration needle, which is guided through the trachea into the lungs',
          'Can collect samples from lesions that are too small or too hard to reach by conventional mediastinoscopy',
          'Minimally invasive; performed under moderate sedation or general anesthesia'
        ],
        gtagID: 'EBUS_Tab',
        gtagText: 'EBUS_Tab'
      },
      {
        title: 'EUS',
        img: EUS,
        list: [
          'Used to sample lymph nodes through the esophagus',
          'Allows biopsy of tissue from the upper abdomen if metastasis in that area is suspected',
          'Performed under conscious sedation'
        ],
        gtagID: 'EUS_Tab',
        gtagText: 'EUS_Tab'
      },
      {
        title: 'CT-GUIDED BIOPSY',
        img: CTGuidedBiopsy,
        list: [
          'Used to evaluate cancer spread to mediastinal lymph nodes',
          'Can be difficult to perform in certain areas, such as around the diaphragm or adjacent to other vital structures',
          'Performed under local anesthesia'
        ],
        gtagID: 'CT_Guided_Tab',
        gtagText: 'CT_Guided_Tab'
      }
    ]
  },
  imagingListData: {
    key: 'list',
    data: [
      {
        title: 'CHEST X-RAY',
        img: ChestXray,
        list: [
          'Uses radiation to create images of the inside of the body',
          'Imaging takes a few minutes',
          'Results can be available within minutes but typically take 1 to 2 days'
        ],
        gtagID: 'Chest_X_Ray_Tab',
        gtagText: 'Chest_X_Ray_Tab'
      },
      {
        title: 'CT',
        img: CT,
        list: [
          'Takes a series of x-rays from different angles to create a cross-sectional image',
          'Imaging takes about 10 minutes to 15 minutes',
          'Results are typically available within 24 hours to 48 hours'
        ],
        gtagID: 'CT_Tab',
        gtagText: 'CT_Tab'
      },
      {
        title: 'PET-CT',
        img: PETCT,
        list: [
          'Uses a machine that combines CT and PET imaging',
          'PET uses radioactive sugar injected into the body to detect rapidly growing cells',
          'Imaging takes about 30 minutes',
          'Results are typically available within 48 hours'
        ],
        gtagID: 'PET_CT_Tab',
        gtagText: 'PET_CT_Tab'
      },
      {
        title: 'MRI',
        img: MRI,
        list: [
          'Uses a magnetic field and radio waves to capture detailed images of organs and soft tissue',
          'Imaging can take anywhere from 15 minutes to over an hour',
          'Results are typically available within 1 week to 2 weeks'
        ],
        gtagID: 'MRI_Tab',
        gtagText: 'MRI_Tab'
      },
      {
        title: 'BONE SCINTIGRAPHY',
        img: Bone,
        list: [
          'Also known as bone scan',
          'Uses radiotracers injected into the body to assess spread of cancer to the bones',
          'Imaging takes 1 hour',
          'Results are typically available within 48 hours'
        ],
        gtagID: 'Bone_Scintigraphy_Tab',
        gtagText: 'Bone_Scintigraphy_Tab'
      }
    ]
  },
  expansionData: {
    key: 'expansion',
    data: [
      {
        title: 'EGFR in NSCLC',
        img: EGFR,
        content: <EGFRContent />,
        modalImg: EGFRModal,
        gtagID: 'EFGR_Tab',
        gtagText: 'EFGR_Tab'
      },
      {
        title: 'HER2 in NSCLC',
        img: HER2,
        content: <HER2Content />,
        modalImg: HER2Modal,
        gtagID: 'HER2_Tab',
        gtagText: 'HER2_Tab'
      },
      {
        title: 'HER3 in NSCLC',
        img: HER3,
        content: <HER3Content />,
        modalImg: HER3Modal,
        gtagID: 'HER3_Tab',
        gtagText: 'HER3_Tab'
      },
      {
        title: 'TROP-2 in NSCLC',
        img: TROP2,
        content: <TROP2Content />,
        modalImg: TROP2Modal,
        gtagID: 'TROP2_Tab',
        gtagText: 'TROP2_Tab'
      }
    ]
  },
  liquidNGSReport: {
    key: 'liquidNGSReport',
    data: [
      {
        title: 'Liquid NGS Test Report',
        img: LiquidTestReport
      }
    ]
  },
  tissueNGSReport: {
    key: 'tissueNGSReport',
    data: [
      {
        title: 'Tissue NGS Test Report',
        img: TissueTestReport
      }
    ]
  }
};

export default lungContentConfig;
