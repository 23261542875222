import React from 'react';
import { useParams } from 'react-router-dom';
import Buttons from '../common/Buttons';
import GroupsConfig from '../../../config/groups';
import wirewaxConfig from '../../../config/wirewax';

const LaboratoryButtons = ({ routes, videoEl }) => {
  const { group, assay } = useParams();

  return (
    <Buttons
      buttons={[
        {
          name: 'LEARN ABOUT THE TEMPUS xT GENE PANEL',
          route: `/landing/2/1`,
          buttonClass: 'bigger',
          time: 351.0, //5:51
          videoIndex: 1
        },
        {
          name: 'LEARN ABOUT CARIS MOLECULAR INTELLIGENCE<sup>&reg;</sup>',
          route: `/landing/2/2`,
          buttonClass: 'bigger',
          time: 355.0, //5:55
          videoIndex: 1
        },
        {
          name: 'EXPLORE MORE TISSUE NGS ASSAYS',
          route: '/home/2',
          time: 349.0,
          videoIndex: 1
        },
        {
          name: 'EXPLORE LIQUID BIOPSY TESTING',
          route: '/home/3',
          time: 394.0,
          videoIndex: 1
        }
      ]}
      videoEl={videoEl}
    />
  );
};

export default LaboratoryButtons;
