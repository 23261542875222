import React from 'react';
import LaboratoryButtons from '../tissue/LaboratoryButtons';
import Laboratory from '../common/Laboratory';

const TissueLaboratory = ({ videoEl }) => (
  <Laboratory
    content={
      'Examples of NGS LDTs include the TEMPUS xT Gene Panel and Caris Molecular Intelligence<sup><small>&reg;</small></sup>.'
    }
    buttons={<LaboratoryButtons videoEl={videoEl} />}
    footer={
      '*Tests should be performed in laboratories that adhere to Clinical Laboratory Improvement Amendment of 1988 (CLIA) and CAP accreditation requirements.'
    }
  />
);

export default TissueLaboratory;
