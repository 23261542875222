import React from 'react';
import { useParams } from 'react-router-dom';
import Buttons from '../common/Buttons';
import wirewaxConfig from '../../../config/wirewax';

const LandingButtons = ({ routes, videoEl }) => {
  const { group, assay } = useParams();

  return (
    <Buttons
      containerClass={'button-stack'}
      buttons={[
        {
          name: 'REVIEW SAMPLE TEST REPORT',
          route: `/lungwall/${group}/${assay}/0`,
          buttonClass: 'wall'
        },
        {
          name: 'EXPLORE MORE TISSUE NGS ASSAYS',
          route: '/home/5',
          time: 351.0,
          videoIndex: 2
        }
      ]}
      videoEl={videoEl}
    />
  );
};

export default LandingButtons;
