import React from 'react';
import LandingButtons from '../ish/LandingButtons';
import Laboratory from '../common/Laboratory';

const ISHLaboratory = ({ videoEl }) => (
  <Laboratory
    content={'An example of an ISH LDT is the NeoGenomics HER2 Breast Cancer FISH assay.'}
    buttons={<LandingButtons videoEl={videoEl} />}
    footer={
      '*Tests should be performed in laboratories that adhere to Clinical Laboratory Improvement Amendment of 1988 (CLIA) and CAP accreditation requirements.'
    }
  />
);

export default ISHLaboratory;
