import React from 'react';
import './DNAContent.css';
import '../../src/Colors.css';
import TrainingWarning from './common/TrainingWarning';
const PrinterContent = ({ leftContent, rightContent }) => {
  return (
    <>
      <div
        className="ish-content ish-content-centered"
        style={{ justifyContent: 'space-between', flexDirection: 'row-reverse', textAlign: 'left' }}
      >
        <div style={{ flex: '0 0 40%', display: 'flex', justifyContent: 'flex-end' }}>
          <img style={{ width: '80%' }} src={leftContent.img} alt="" />
        </div>
        <div>{rightContent}</div>
      </div>
      <div class="footer-container white" style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
        <TrainingWarning isTrainingLabelOpen={true} />
      </div>
    </>
  );
};

export default PrinterContent;
