import IHCAssay1Image from '../images/carousel/ihc1.png';
import IHCAssay2Image from '../images/carousel/ihc2.png';
import IHCAssay3Image from '../images/carousel/ihc3.png';
import IHCAssay4Image from '../images/carousel/ihc4.png';
import ISHAssay1Image from '../images/carousel/ISH1.png';
import ISHAssay2Image from '../images/carousel/ISH2.png';
import ISHAssay3Image from '../images/carousel/ISH3.png';
import ISHAssay4Image from '../images/carousel/ISH4.png';
import ISHAssay5Image from '../images/carousel/ISH5.png';
import TissNGSAssay1Image from '../images/carousel/TISS1.png';
import TissNGSAssay2Image from '../images/carousel/TISS2.png';
import TissNGSAssay3Image from '../images/carousel/TISS3.png';
import TissNGSAssay4Image from '../images/carousel/TISS4.png';
import LiqNGSAssay1Image from '../images/carousel/NGS1.png';
import LiqNGSAssay2Image from '../images/carousel/NGS2.png';
import LiqNGSAssay3Image from '../images/carousel/NGS3.png';
import LiqNGSAssay4Image from '../images/carousel/NGS4.png';

import LungLiquidAssay1Image from '../images/lungcarousel/LungLiquidAssay1Image.png';
import LungLiquidAssay2Image from '../images/lungcarousel/LungLiquidAssay2Image.png';
import LungLiquidAssay3Image from '../images/lungcarousel/LungLiquidAssay3Image.png';

import LungTissueAssay1Image from '../images/lungcarousel/LungTissueAssay1Image.png';
import LungTissueAssay2Image from '../images/lungcarousel/LungTissueAssay2Image.png';
import LungTissueAssay3Image from '../images/lungcarousel/LungTissueAssay3Image.png';
import LungTissueAssay4Image from '../images/lungcarousel/LungTissueAssay4Image.png';
import LungTissueModalImage from '../images/PDL1Testing2.png';
import LungTissueModalImage2 from '../images/Frame30_1.2.1_4 – 1_pop up only.png';

import IHCLandingButtons from '../components/landing/ihc/LandingButtons';
import IHCLandingTopSection from '../components/landing/ihc/TopSection';
import IHCLaboratory from '../components/landing/ihc/Laboratory';

import ISHLandingButtons from '../components/landing/ish/LandingButtons';
import ISHLaboratory from '../components/landing/ish/Laboratory';

import TissueLandingButtons from '../components/landing/tissue/LandingButtons';
import TissueLaboratory from '../components/landing/tissue/Laboratory';

import LiquidLandingButtons from '../components/landing/liquid/LandingButtons';
import LiquidLaboratory from '../components/landing/liquid/Laboratory';

import LungLiquidLandingButtons from '../components/landing/lungLiquid/LandingButtons';
import LungTissueLandingButtons from '../components/landing/lungTissue/LandingButtons';

import LandingTopSection from '../components/landing/common/TopSection';
import SlidesConfig from './slides';

import wirewaxConfig from '../config/wirewax';

const GroupsConfig = [
  {
    home: {
      title: 'IHC ASSAYS',
      topContent:
        'IHC is a qualitative method for detecting specific antigens in tissues or cells based on antigen-antibody recognition.',
      bullets: [
        'ER/PR testing is also conducted with IHC, often concurrently with HER2 testing<sup>2,40</sup>',
        'Average turnaround time for IHC testing is 1 to 2 days'
      ],
      blueBar:
        'The majority of HER2 testing begins with IHC, with reflex testing to ISH for equivocal scores.',
      bottomContent:
        'CPT codes for IHC include 88360 (manual) and 88361 (automated).  Reflex testing may require an additional code.',
      test: 'IHC'
    },
    assays: [
      {
        img: IHCAssay1Image,
        landing: {
          time: 230.8,
          videoIndex: 1,
          seek: wirewaxConfig.IHC.breadcrumbs.ventana.spriteId, //3:50 - 230.0
          topContent: (
            <IHCLandingTopSection
              title={'VENTANA anti-HER2/neu (4B5) '}
              subtitle={'(Roche Diagnostics)'}
              bullets={[
                'Uses rabbit monoclonal primary antibody (clone 4B5)',
                'FDA-approved companion diagnostic (breast and gastric cancer)'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <IHCLandingButtons />
        },
        wall: SlidesConfig.Ventana,
        footer: '',
        gtagId: 'bc_ventana_anti_her2_select_option',
        gtagText: 'bc_ventana_anti_her2_select_option'
      },
      {
        img: IHCAssay2Image,
        landing: {
          time: 243.5, //4:02.8 - 4:03.8
          videoIndex: 1,
          seek: wirewaxConfig.IHC.breadcrumbs.bond.spriteId,
          topContent: (
            <IHCLandingTopSection
              title={'Bond Oracle™ HER2 IHC System '}
              subtitle={'(Leica Biosystems)'}
              bullets={[
                'Uses mouse monoclonal primary antibody (clone CB11)',
                'FDA-approved companion diagnostic (breast cancer)'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <IHCLandingButtons />
        },
        wall: SlidesConfig.BondOracle,
        footer: '',
        gtagId: 'bc_bond_oracle_her2_ihc_system_select_option',
        gtagText: 'bc_bond_oracle_her2_ihc_system_select_option'
      },
      {
        img: IHCAssay3Image,
        landing: {
          time: 255.3, //4:14.08 - 4:15.08
          videoIndex: 1,
          seek: wirewaxConfig.IHC.breadcrumbs.hercep.spriteId,
          topContent: (
            <IHCLandingTopSection
              title={'HercepTest™ '}
              subtitle={'(Dako/Agilent Technologies, Inc)'}
              bullets={[
                'Uses rabbit polyclonal primary antibody',
                'FDA-approved companion diagnostic (breast and gastric cancer)'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <IHCLandingButtons />
        },
        wall: SlidesConfig.Hercep,
        footer: '',
        gtagId: 'bc_herceptest_select_option',
        gtagText: 'bc_herceptest_select_option'
      },
      {
        img: IHCAssay4Image,
        landing: {
          time: 267.0, //4:26.8-4:27.8
          videoIndex: 1,
          seek: wirewaxConfig.IHC.breadcrumbs.laboratory.spriteId,
          laboratory: <IHCLaboratory />
        },
        gtagId: 'bc_laboratory_developed_tests_select_option_ihc',
        gtagText: 'bc_laboratory_developed_tests_select_option_ihc'
      }
    ]
  },
  {
    home: {
      title: 'ISH Assays',
      topContent:
        'ISH is a technique used to identify either the location of a specific gene on a chromosome or the number of copies of that specific gene in a sample.',
      bullets: [
        'ISH assays for HER2 testing include single-probe and dual-probe assays',
        'ASCO/CAP Expert Panel recommends the use of dual-probe assays over single-probe assays',
        'Average turnaround time for ISH tests is 2 to 5 days'
      ],
      blueBar:
        'ISH is a clinically validated alternative method to IHC for assessment of HER2 status and is also used in cases in which IHC scores are equivocal.',
      bottomContent:
        'CPT codes for ISH include 88374 (automated) and 88377. Reflex testing may require an additional code.',
      test: 'ISH'
    },
    assays: [
      {
        img: ISHAssay1Image,
        landing: {
          time: 271.0, //4:30.8 - 4:31.8
          videoIndex: 1,
          seek: wirewaxConfig.ISH.breadcrumbs.spot.spriteId,
          topContent: (
            <LandingTopSection
              title={'SPOT-Light HER2 CISH Kit '}
              subtitle={'(Invitrogen)'}
              bullets={[
                'Single-probe chromogenic in situ hybridization (CISH) assay that uses brightfield microscopy',
                'FDA-approved companion diagnostic (breast cancer)'
              ]}
              blueBar={
                'ASCO/CAP guidelines recommend that concomitant IHC review become part of the interpretation of single-probe ISH results to allow the most accurate HER2 designation.'
              }
              bottomContent={
                'Like other single-probe ISH tests, this assay quantitatively detects amplification of the HER2 gene.'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <ISHLandingButtons />
        },
        wall: SlidesConfig.SPOTLight,
        footer: '',
        gtagId: 'bc_spot_light_her2_cish_kit_select_option',
        gtagText: 'bc_spot_light_her2_cish_kit_select_option'
      },
      {
        img: ISHAssay2Image,
        landing: {
          time: 283.0, //4:42.8 - 4:43.8
          videoIndex: 1,
          seek: wirewaxConfig.ISH.breadcrumbs.pathvysion.spriteId,
          topContent: (
            <LandingTopSection
              title={'PathVysion HER-2 DNA Probe Kit '}
              subtitle={'(Roche)'}
              bullets={[
                'Dual-probe fluorescence in situ hybridization (FISH) assay',
                'FDA-approved companion diagnostic (breast cancer)'
              ]}
              blueBar={
                'ASCO/CAP Expert Panel recommends the use of dual-probe assays over single-probe assays.'
              }
              bottomContent={
                'Like other dual-probe ISH tests, this assay quantitatively detects amplification of the HER2 gene by enumeration of the ratio of the HER2 gene to chromosome 17 detected. Potential results are classified as Group 1 to 5.'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <ISHLandingButtons />
        },
        wall: SlidesConfig.PathVysion,
        footer:
          'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
        gtagId: 'bc_pathvysion_her2_dna_probe_kit_select_option',
        gtagText: 'bc_pathvysion_her2_dna_probe_kit_select_option'
      },
      {
        img: ISHAssay3Image,
        landing: {
          time: 303.0, //5:02.8 - 5:03.8
          videoIndex: 1,
          seek: wirewaxConfig.ISH.breadcrumbs.her2.spriteId,
          topContent: (
            <LandingTopSection
              title={'HER2 IQFISH pharmDX '}
              subtitle={'(Dako/Agilent Technologies, Inc)'}
              bullets={[
                'Dual-probe fluorescence in situ hybridization (FISH) assay',
                'FDA-approved companion diagnostic (breast, gastric, and gastroesophageal cancer)'
              ]}
              blueBar={
                'ASCO/CAP Expert Panel recommends the use of dual-probe assays over single-probe assays.'
              }
              bottomContent={
                'Like other dual-probe ISH tests, this assay quantitatively detects amplification of the HER2 gene by enumeration of the ratio of the HER2 gene to chromosome 17 detected. Potential results are classified as Group 1 to 5.'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <ISHLandingButtons />
        },
        wall: SlidesConfig.HER2IQFish,
        footer:
          'Adapted from Yang L et al. <i>J Clin Pathol</i>. 2020;73(5):283-290 with permission from BMJ Publishing Group Ltd.',
        gtagId: 'bc_her2_iqfish_pharmdx_select_option',
        gtagText: 'bc_her2_iqfish_pharmdx_select_option'
      },
      {
        img: ISHAssay4Image,
        landing: {
          time: 323.0, //5:22 -5:23
          videoIndex: 1,
          seek: wirewaxConfig.ISH.breadcrumbs.ventana.spriteId,
          topContent: (
            <LandingTopSection
              title={'VENTANA HER2 Dual ISH DNA Probe Cocktail '}
              subtitle={'(Roche Diagnostics)'}
              bullets={[
                'Dual-probe DNA in situ hybridization (ISH) assay',
                'FDA-approved companion diagnostic (breast cancer)'
              ]}
              blueBar={
                'ASCO/CAP Expert Panel recommends the use of dual-probe assays over single-probe assays.'
              }
              bottomContent={
                'Like other dual-probe ISH tests, this assay quantitatively detects amplification of the HER2 gene by enumeration of the ratio of the HER2 gene to chromosome 17 detected. Potential results are classified as Group 1 to 5.'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <ISHLandingButtons />
        },
        wall: SlidesConfig.ISHVentana,
        footer: '',
        gtagId: 'bc_ventana_her2_dual_ish_select_option',
        gtagText: 'bc_ventana_her2_dual_ish_select_option'
      },
      {
        img: ISHAssay5Image,
        landing: {
          time: 343.0, //5:42 - 5:43
          videoIndex: 1,
          seek: wirewaxConfig.ISH.breadcrumbs.laboratory.spriteId,
          laboratory: <ISHLaboratory />
        },
        gtagId: 'bc_laboratory_developed_tests_select_option_ish',
        gtagText: 'bc_laboratory_developed_tests_select_option_ish'
      }
    ]
  },
  {
    home: {
      title: 'Tissue NGS Panels',
      topContent:
        'NGS technology is capable of sequencing multiple genomic strands in parallel, enabling broad molecular profiling of multiple targets in a single sample. Several NGS panels include HER2 gene amplification.',
      bottomContent: 'The CPT code for comprehensive NGS is 81479.',
      test: 'TISSUE NGS'
    },
    assays: [
      {
        img: TissNGSAssay1Image,
        landing: {
          time: 347.0, //5:47
          videoIndex: 1,
          seek: wirewaxConfig.Tissue.breadcrumbs.foundationOne.spriteId,
          topContent: (
            <LandingTopSection
              title={'FoundationOne<sup>&reg;</sup> CDx '}
              subtitle={'(Foundation Medicine Inc)'}
              topContent={
                'FDA-approved companion diagnostic for several tumors, including breast cancer'
              }
              bullets={[
                'Detects alterations in 324 genes, including HER2 gene amplification',
                'Turnaround time is typically 12 days or less from receipt of specimen'
              ]}
              blueBar={
                'In case of insufficient tumor tissue, reflex to FoundationOne<sup>&reg;</sup>Liquid CDx (liquid biopsy).'
              }
              bottomContent={
                'Clinical validity for HER2 testing with FoundationOne<sup>&reg;</sup> CDx was established through concordance with the Dako HER2 FISH pharmDx kit.'
              }
              learnMore={
                'Concordance with Dako HER2 FISH pharmDx kit: 89.4% positive percent agreement (PPA) and 98.4% negative percent agreement (NPA)'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <TissueLandingButtons />
        },
        wall: SlidesConfig.NGSFountation,
        footer: '',
        gtagId: 'bc_foundationone_cdx_select_option',
        gtagText: 'bc_foundationone_cdx_select_option'
      },
      {
        img: TissNGSAssay2Image,
        landing: {
          time: 351.0, //5:51
          videoIndex: 1,
          seek: wirewaxConfig.Tissue.breadcrumbs.tempus.spriteId,
          topContent: (
            <LandingTopSection
              title={'TEMPUS xT Gene Panel '}
              subtitle={'(Tempus)'}
              bullets={[
                'RNA and DNA sequencing detects alterations in 648 genes, including HER2 gene amplification',
                'Turnaround time is typically 14 days from receipt of specimen',
                'TEMPUS xT Gene Panel is an LDT. LDTs are not FDA approved'
              ]}
              blueBar={
                'In case of insufficient tumor tissue, reflex to the TEMPUS xF Gene Panel (liquid biopsy).'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <TissueLandingButtons />
        },
        wall: SlidesConfig.NGSTempusXT,
        footer: '',
        gtagId: 'bc_tempus_xt_gene_panel_select_option',
        gtagText: 'bc_tempus_xt_gene_panel_select_option'
      },
      {
        img: TissNGSAssay3Image,
        landing: {
          time: 355.0, //5:55
          videoIndex: 1,
          seek: wirewaxConfig.Tissue.breadcrumbs.caris.spriteId,
          topContent: (
            <LandingTopSection
              title={'Caris Molecular Intelligence<sup>&reg;</sup>&nbsp;'}
              subtitle={'(Caris Life Sciences<sup>&reg;</sup>)'}
              bullets={[
                'Tumor-specific customized testing for breast cancer includes NGS and reflex IHC, when copy<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;number alteration (CNA) is amplified, and CISH testing, when IHC is equivocal (IHC 2+)',
                'Turnaround time is typically 10 to 14 days from receipt of specimen',
                'Caris Molecular Intelligence<sup>&reg;</sup> is an LDT. LDTs are not FDA approved'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <TissueLandingButtons />
        },
        wall: SlidesConfig.NGSCarisMolecular,
        footer: '',
        gtagId: 'bc_caris_molecular_intelligence_select_option',
        gtagText: 'bc_caris_molecular_intelligence_select_option'
      },
      {
        img: TissNGSAssay4Image,
        landing: {
          time: 359.0, //5:59
          videoIndex: 1,
          seek: wirewaxConfig.Tissue.breadcrumbs.laboratory.spriteId,
          laboratory: <TissueLaboratory />
        },
        gtagId: 'bc_laboratory_developed_tests_select_option_ngs',
        gtagText: 'bc_laboratory_developed_tests_select_option_ngs'
      }
    ]
  },
  {
    home: {
      title: 'LIQUID BIOPSY NGS PANELS',
      topContent:
        'Multigene NGS panels for liquid biopsy can also be used to determine HER2 status. Liquid biopsy can be used when tumor tissue is unavailable and can be performed concurrently with tissue testing.',
      bottomContent: 'The CPT code for comprehensive NGS is 81479.',
      test: 'LIQUID BIOPSY NGS'
    },
    assays: [
      {
        img: LiqNGSAssay1Image,
        landing: {
          time: 395.0, //6:35
          videoIndex: 1,
          seek: wirewaxConfig.Liquid.breadcrumbs.guardant.spriteId,
          topContent: (
            <LandingTopSection
              title={'Guardant360<sup>&reg;</sup> CDx '}
              subtitle={'(Guardant Health, Inc)'}
              topContent={
                'First FDA-approved comprehensive liquid biopsy for all advanced solid tumors'
              }
              bullets={[
                'Detects alterations in 55 genes, including HER2 amplification',
                'Turnaround time is typically 7 days from receipt of specimen'
              ]}
              bottomContent={
                'Guardant360<sup>&reg;</sup> CDx is an FDA-approved companion diagnostic in NSCLC.'
              }
              learnMore={
                'Initial approval is based on the NILE study, which demonstrated ≥98.2% concordance with tissue for 4 alterations: EGFR mutations (EGFR exon 19 del and EGFR L858R), ALK fusion, ROS1 fusion, and BRAF V600E mutation'
              }
            />
          ),
          exploreContentHeader: true,
          bottomContent: <LiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidGuardant360,
        footer: '',
        gtagId: 'bc_guardent360_cdx_select_optionn',
        gtagText: 'bc_guardent360_cdx_select_optionn'
      },
      {
        img: LiqNGSAssay2Image,
        landing: {
          time: 399.0, //6:39
          videoIndex: 1,
          seek: wirewaxConfig.Liquid.breadcrumbs.foundationOne.spriteId,
          topContent: (
            <LandingTopSection
              title={'FoundationOne<sup>&reg;</sup>Liquid CDx '}
              subtitle={'(Foundation Medicine Inc)'}
              topContent={
                'FDA-approved companion diagnostic in 4 tumor types, including breast cancer (for PIK3CA mutation)'
              }
              bullets={[
                'Detects alterations in >311 genes, including HER2 amplification',
                'Turnaround time is typically 10 days or less from receipt of specimen'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <LiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidFoundationOne,
        footer: '',
        gtagId: 'bc_foundationone_liquid_cdx_select_option',
        gtagText: 'bc_foundationone_liquid_cdx_select_option'
      },
      {
        img: LiqNGSAssay3Image,
        landing: {
          time: 403.0, //6:43
          videoIndex: 1,
          seek: wirewaxConfig.Liquid.breadcrumbs.tempus.spriteId,
          topContent: (
            <LandingTopSection
              title={'TEMPUS xF Gene Panel '}
              subtitle={'(Tempus)'}
              bullets={[
                'Detects alterations in 105 genes, including HER2 amplification',
                'Turnaround time is typically 14 days from receipt of specimen',
                'Tempus xF is an LDT. LDTs are not FDA approved'
              ]}
            />
          ),
          exploreContentHeader: true,
          bottomContent: <LiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidTempusXF,
        footer: '',
        gtagId: 'bc_tempus_xf_gene_panel_select_option',
        gtagText: 'bc_tempus_xf_gene_panel_select_option'
      },
      {
        img: LiqNGSAssay4Image,
        landing: {
          time: 407.0, //6:47
          videoIndex: 1,
          seek: wirewaxConfig.Liquid.breadcrumbs.laboratory.spriteId,
          laboratory: <LiquidLaboratory />
        },
        gtagId: 'bc_laboratory_developed_tests_select_option_liquid_biopsy',
        gtagText: 'bc_laboratory_developed_tests_select_option_liquid_biopsy'
      }
    ]
  },
  //LUNG SECTION
  {
    //LIQUID BIOPSY NGS PANELS
    home: {
      title: 'LIQUID BIOPSY NGS PANELS',
      topContent:
        'Multigene NGS panels for liquid biopsy can also be used for broad molecular profiling in NSCLC. Liquid biopsy can be used when tumor tissue is unavailable. Liquid biopsy testing can be used alone or concurrently with tissue testing. Negative results from liquid-based panels should be confirmed with tissue-based testing. ',
      bottomContent: 'The CPT code for comprehensive NGS is 81479.',
      homeButtons: [
        {
          name: 'BACK TO LUNG HOME',
          // route: '/home/0',
          time: 29.0,
          videoIndex: 2,
          reverse: true,
          gtagId: 'Back_to_Lung_Cancer_HP_LB',
          gtagText: 'Back_to_Lung_Cancer_HP_LB'
        },
        {
          name: 'REVIEW NSCLC SUBTYPES',
          // route: '/home/1',
          time: 248.0,
          videoIndex: 2,
          reverse: true,
          gtagId: 'Review_NSCLC_Subtypes_Select_Option_LB',
          gtagText: 'Review_NSCLC_Subtypes_Select_Option_LB'
        },
        {
          name: 'EXPLORE<br/> TISSUE BIOPSY TESTING',
          route: '/home/3',
          time: 261.0,
          videoIndex: 2,
          gtagId: 'Explore_Tissue_Biopsy_Testing_LB',
          gtagText: 'Explore_Tissue_Biopsy_Testing_LB'
        }
      ]
    },
    assays: [
      {
        img: LungLiquidAssay1Image,
        landing: {
          time: 420.0, //NEED TO UPDATE THIS
          videoIndex: 2,
          //seek: wirewaxConfig.Liquid.breadcrumbs.guardant.spriteId,
          topContent: (
            <LandingTopSection
              title={'FoundationOne<sup>&reg;</sup> Liquid CDx '}
              subtitle={'(Foundation Medicine, Inc)'}
              topContent={
                'FDA-approved companion diagnostic in multiple tumor types, including NSCLC'
              }
              bulletTable={[
                'Detects alterations in >311 genes, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), and <i>KRAS</i>',
                'Turnaround time is typically 10 days or less from receipt of specimen'
              ]}
              greyContent={
                'A negative result is not conclusive for absence of biomarkers.<br/> Reflex to tissue testing to confirm results '
              }
              exploreContent={false}
            />
          ),
          bottomContent: <LungLiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidGuardant360,
        footer: '',
        gtagId: 'FoundationOne_CDx_Select_Option_LB',
        gtagText: 'FoundationOne_CDx_Select_Option_LB'
      },

      {
        img: LungLiquidAssay2Image,
        landing: {
          time: 430.0, //6:39
          videoIndex: 2,
          //seek: wirewaxConfig.Liquid.breadcrumbs.foundationOne.spriteId,
          topContent: (
            <LandingTopSection
              title={'Guardant360<sup>&reg;</sup> CDx '}
              subtitle={'(Guardant Health, Inc)'}
              topContent={
                'Guardant360<sup>&reg;</sup> CDx is an FDA-approved companion diagnostic in NSCLC'
              }
              bulletTable={[
                'Detects alterations in 55 genes, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, <i>KRAS</i>, <i>RET</i>, and <i>NTRK1</i>',
                'Turnaround time is typically 7 days from receipt of specimen'
              ]}
              learnMore={
                'Initial approval is based on the NILE study, which demonstrated ≥98.2% concordance with tissue for 4 alterations: <i>EGFR</i> mutations (<i>EGFR</i> exon 19 del and <i>EGFR</i> L858R), <i>ALK</i> fusion, <i>ROS1</i> fusion, and <i>BRAF</i> V600E mutation'
              }
              learnMoreAlt={'LEARN MORE ABOUT THE FDA APPROVAL'}
              greyContent={
                'A negative result is not conclusive for absence of biomarkers.<br/> Reflex to tissue testing to confirm results '
              }
            />
          ),
          bottomContent: <LungLiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidFoundationOne,
        footer: '',
        gtagId: 'Guardant360_CDx_Select_Option_LB',
        gtagText: 'Guardant360_CDx_Select_Option_LB'
      },
      {
        img: LungLiquidAssay3Image,
        landing: {
          time: 442.0, //6:43
          videoIndex: 2,
          seek: wirewaxConfig.Liquid.breadcrumbs.tempus.spriteId,
          topContent: (
            <LandingTopSection
              title={'TEMPUS xF Gene Panel '}
              subtitle={'(Tempus)'}
              bulletTable={[
                'Detects alterations in 105 genes, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, <i>KRAS</i>, <i>RET</i>, and <i>NTRK1</i>',
                'Turnaround time is typically 14 days from receipt of specimen',
                'Tempus xF is a laboratory-developed test (LDT). LDTs are not FDA approved'
              ]}
              greyContent={
                'A negative result is not conclusive for absence of biomarkers.<br/> Reflex to tissue testing to confirm results '
              }
            />
          ),
          bottomContent: <LungLiquidLandingButtons />
        },
        wall: SlidesConfig.LiquidTempusXF,
        footer: '',
        gtagId: 'Tempus_xF_Gene_Panel_Select_Option_LB',
        gtagText: 'Tempus_xF_Gene_Panel_Select_Option_LB'
      }
    ]
  },
  {
    //TISSUE NGS PANELS
    home: {
      title: 'TISSUE NGS PANELS',
      topContent:
        'NGS technology is capable of sequencing multiple genomic strands in parallel, enabling broad molecular profiling of multiple targets in a single sample. Several NGS assays include guideline-recommended biomarkers in NSCLC, such as <i>EGFR</i>&nbsp;&nbsp;deletions and mutations, <i>KRAS G12C</i>&nbsp;&nbsp;mutation, <i>ALK</i>&nbsp;&nbsp;rearrangements, <i>ROS1</i>&nbsp;&nbsp;rearrangements, <i>BRAF</i>&nbsp;&nbsp;V600E mutation, <i>NTRK1/2/3</i> fusions, <i>MET</i>&nbsp;&nbsp;exon 14 skipping mutation, <i>RET</i>&nbsp;&nbsp;rearrangements, and <i>HER2</i> (<i>ERBB2</i>) mutations.',
      bottomContent: 'The CPT code for comprehensive NGS is 81479.',
      homeButtons: [
        {
          name: 'BACK TO LUNG HOME',
          // route: '/home/0',
          time: 29.0,
          videoIndex: 2,
          reverse: true,
          gtagId: 'Back_to_Lung_Cancer_HP_TNGSPanels',
          gtagText: 'Back_to_Lung_Cancer_HP_TNGSPanels'
        },
        {
          name: 'REVIEW NSCLC SUBTYPES',
          // route: '/home/1',
          time: 248.0,
          videoIndex: 2,
          reverse: true,
          gtagId: 'Review_NSCLC_Subtypes_Select_Option',
          gtagText: 'Review_NSCLC_Subtypes_Select_Option'
        },
        {
          name: 'EXPLORE LIQUID BIOPSY TESTING',
          route: '/home/4',
          time: 386.0,
          videoIndex: 2,
          gtagId: 'Explore_Liquid_Biopsy_Testing_Select_Option',
          gtagText: 'Explore_Liquid_Biopsy_Testing_Select_Option'
        }
      ],
      homeLearnMoreModal: {
        caption:
          'In the absence of actionable oncogenic drivers, PD-L1 expression assessed by IHC can inform treatment by immunotherapy.',
        buttonTitle: 'LEARN MORE ABOUT PD-L1 TESTING',
        title: 'PD-L1 Expression in NSCLC Varies From Negative to Strongly Positive',
        img: LungTissueModalImage2
      }
    },
    assays: [
      {
        img: LungTissueAssay1Image,
        landing: {
          time: 356.0, //NEED TO UPDATE THIS
          videoIndex: 2,
          //seek: wirewaxConfig.Liquid.breadcrumbs.guardant.spriteId,
          topContent: (
            <LandingTopSection
              title={'FoundationOne<sup>&reg;</sup> CDx '}
              subtitle={'(Foundation Medicine, Inc)'}
              topContent={'FDA-approved companion diagnostic for several tumors, including NSCLC'}
              bulletTable={[
                'Detects alterations in 324 genes, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, <i>KRAS</i>, <i>RET</i>, and <i>NTRK1/2/3</i>',
                'Turnaround time is typically 12 days or less from receipt of specimen'
              ]}
              greyContent={
                'In case of insufficient tumor tissue, reflex to FoundationOne<sup>&reg;</sup> Liquid CDx (liquid biopsy)'
              }
              exploreContent={false}
            />
          ),
          bottomContent: <LungTissueLandingButtons />
        },
        wall: SlidesConfig.LiquidGuardant360,
        footer: '',
        gtagId: 'FoundationOne_CDx_Select_Option',
        gtagText: 'FoundationOne_CDx_Select_Option'
      },
      {
        img: LungTissueAssay2Image,
        landing: {
          time: 364.0, //6:39
          videoIndex: 2,
          seek: wirewaxConfig.Liquid.breadcrumbs.foundationOne.spriteId,
          topContent: (
            <LandingTopSection
              title={'TEMPUS xT Gene Panel '}
              subtitle={'(Tempus)'}
              bulletTable={[
                'RNA and DNA sequencing detects alterations in 648 genes, including <i>EGFR</i>,<i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, <i>KRAS</i>, <i>RET</i>, and <i>NTRK1/2/3</i>',
                'Turnaround time is typically 14 days from receipt of specimen',
                'TEMPUS xT Gene Panel is a laboratory-developed test (LDT). LDTs are not FDA approved'
              ]}
              greyContent={
                'In case of insufficient tumor tissue, reflex to the TEMPUS xF Gene Panel (liquid biopsy)'
              }
            />
          ),
          bottomContent: <LungTissueLandingButtons />
        },
        wall: SlidesConfig.LiquidFoundationOne,
        footer: '',
        gtagId: 'Tempus_xT_Select_Option',
        gtagText: 'Tempus_xT_Select_Option'
      },
      {
        img: LungTissueAssay3Image,
        landing: {
          time: 372.0, //6:43
          videoIndex: 2,
          seek: wirewaxConfig.Liquid.breadcrumbs.tempus.spriteId,
          topContent: (
            <LandingTopSection
              title={'Caris Molecular Intelligence<sup>&reg;</sup> '}
              subtitle={'(Caris Life Sciences<sup>&reg;</sup>)'}
              bulletTable={[
                'Tumor-specific customized testing for NSCLC includes NGS for comprehensivemolecular profiling, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, <i>KRAS</i>, <i>RET</i>, and <i>NTRK1/2/3</i>; and IHC for PD-L1 testing',
                'Turnaround time is typically 10 to 14 days from receipt of specimen',
                'Caris Molecular Intelligence<sup>&reg;</sup> is a laboratory-developed test (LDT). LDTs are not FDA approved'
              ]}
            />
          ),
          bottomContent: <LungTissueLandingButtons />
        },
        wall: SlidesConfig.LiquidTempusXF,
        footer: '',
        gtagId: 'Caris_Molecular_Intelligence_Select_Option',
        gtagText: 'Caris_Molecular_Intelligence_Select_Option'
      },
      {
        img: LungTissueAssay4Image,
        landing: {
          time: 380.0, //6:43
          videoIndex: 2,
          seek: wirewaxConfig.Liquid.breadcrumbs.tempus.spriteId,
          topContent: (
            <LandingTopSection
              title={'Oncomine<sup>&trade;</sup> Dx Target Test '}
              subtitle={'(Life Technologies Corporation)'}
              topContent={'FDA-approved companion diagnostic for several tumors, including NSCLC'}
              bulletTable={[
                'RNA and DNA sequencing detects alterations in 23 genes, including <i>EGFR</i>, <i>ALK</i>, <i>BRAF</i>, <i>MET</i>, <i>HER2</i> (<i>ERBB2</i>), <i>ROS1</i>, and <i>RET</i>',
                'Turnaround time from sample collection to test report is 4 days '
              ]}
            />
          ),
          bottomContent: <LungTissueLandingButtons />
        },
        wall: SlidesConfig.LiquidTempusXF,
        footer: '',
        gtagId: 'Oncomine_Dx_Target_Test_Select_Option',
        gtagText: 'Oncomine_Dx_Target_Test_Select_Option'
      }
    ]
  }
];

export default GroupsConfig;
