// DEFAULT: DSI Branded (switch to blinded is below)
const wirewaxConfig = {
  Home: {
    spriteId: 6690807
  },
  BreastCancer: {
    spriteId: 6690808
  },
  TNMStaging: {
    spriteId: 6690809
  },
  TAssessPrimary: {
    spriteId: 6690810
  },
  NAssessRegional: {
    spriteId: 6690811
  },
  MAssessMetastases: {
    spriteId: 6690812
  },
  FinalTNM: {
    spriteId: 6690813
  },
  DetermineHER2: {
    spriteId: 6690814
  },
  IHC: {
    lower: 228,
    upper: 267,
    spriteId: 6690817,
    breadcrumbs: {
      ventana: {
        spriteId: 6690818
      },
      bond: {
        spriteId: 6690819
      },
      hercep: {
        spriteId: 6690820
      },
      laboratory: {
        spriteId: 6690821
      }
    }
  },
  ISH: {
    lower: 268,
    upper: 343,
    spriteId: 6690822,
    breadcrumbs: {
      spot: {
        spriteId: 6690823
      },
      pathvysion: {
        spriteId: 6690824
      },
      her2: {
        spriteId: 6690825
      },
      ventana: {
        spriteId: 6690826
      },
      laboratory: {
        spriteId: 6690827
      }
    }
  },
  Tissue: {
    lower: 344,
    upper: 359,
    spriteId: 6690828,
    breadcrumbs: {
      foundationOne: {
        spriteId: 6690830
      },
      tempus: {
        spriteId: 6690831
      },
      caris: {
        spriteId: 6690832
      },
      laboratory: {
        spriteId: 6690833
      }
    }
  },
  Liquid: {
    lower: 394,
    upper: 409,
    spriteId: 6690834,
    breadcrumbs: {
      guardant: {
        spriteId: 6690835
      },
      foundationOne: {
        spriteId: 6690836
      },
      tempus: {
        spriteId: 6690837
      },
      laboratory: {
        spriteId: 6690838
      }
    }
  },
  TissueVideo: {
    spriteId: 6690815
  },
  LiquidVideo: {
    spriteId: 6690816
  },
  LungPieChart: {
    lower: 480,
    upper: 483
  },
  MolecularSubtypes: {
    lower: 485,
    upper: 500
  },
  LungNAssesNodal: {
    lower: 611,
    upper: 614
  },
  LungTissue: {
    lower: 777.5,
    upper: 790
  },
  LungLiquid: {
    lower: 825,
    upper: 839
  }
};

if (process.env.REACT_APP_BRANDING === 'blinded') {
  wirewaxConfig.Home.spriteId = 6705728;
  wirewaxConfig.BreastCancer.spriteId = 6705725;
  wirewaxConfig.TNMStaging.spriteId = 6705694;
  wirewaxConfig.TAssessPrimary.spriteId = 6705727;
  wirewaxConfig.NAssessRegional.spriteId = 6705688;
  wirewaxConfig.MAssessMetastases.spriteId = 6705723;
  wirewaxConfig.FinalTNM.spriteId = 6705690;
  wirewaxConfig.DetermineHER2.spriteId = 6705715;
  wirewaxConfig.IHC.spriteId = 6705703;
  wirewaxConfig.ISH.spriteId = 6705702;
  wirewaxConfig.Tissue.spriteId = 6705698;
  wirewaxConfig.Liquid.spriteId = 6705701;
  wirewaxConfig.TissueVideo.spriteId = 6705711;
  wirewaxConfig.LiquidVideo.spriteId = 6705724;

  wirewaxConfig.IHC.breadcrumbs.ventana.spriteId = 6705719;
  wirewaxConfig.IHC.breadcrumbs.bond.spriteId = 6705704;
  wirewaxConfig.IHC.breadcrumbs.hercep.spriteId = 6705709;
  wirewaxConfig.IHC.breadcrumbs.laboratory.spriteId = 6705710;

  wirewaxConfig.ISH.breadcrumbs.spot.spriteId = 6705696;
  wirewaxConfig.ISH.breadcrumbs.pathvysion.spriteId = 6705720;
  wirewaxConfig.ISH.breadcrumbs.her2.spriteId = 6705697;
  wirewaxConfig.ISH.breadcrumbs.ventana.spriteId = 6705729;
  wirewaxConfig.ISH.breadcrumbs.laboratory.spriteId = 6705699;

  wirewaxConfig.Tissue.breadcrumbs.foundationOne.spriteId = 6705716;
  wirewaxConfig.Tissue.breadcrumbs.tempus.spriteId = 6705708;
  wirewaxConfig.Tissue.breadcrumbs.caris.spriteId = 6705705;
  wirewaxConfig.Tissue.breadcrumbs.laboratory.spriteId = 6705712;

  wirewaxConfig.Liquid.breadcrumbs.guardant.spriteId = 6705730;
  wirewaxConfig.Liquid.breadcrumbs.foundationOne.spriteId = 6705731;
  wirewaxConfig.Liquid.breadcrumbs.tempus.spriteId = 6705733;
  wirewaxConfig.Liquid.breadcrumbs.laboratory.spriteId = 6705732;
}

export default wirewaxConfig;
