import { useState } from 'react';
import TrainingWarning from '../components/common/TrainingWarning';
import orginalImg from '../images/piechart/MolecularSubtypeschartscreen_A_4.png';
import greenImg from '../images/piechart/MolecularSubtypeschartscreen_C_4.png';
import orangeImg from '../images/piechart/MolecularSubtypeschartscreen_B_4.png';
import { useNavigate } from 'react-router-dom';
import wirewaxConfig from '../config/wirewax';
import '../styles/piechart.css';

const ButtonArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17.958"
      height="29.539"
      viewBox="0 0 17.958 29.539"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1903"
            data-name="Rectangle 1903"
            width="17.958"
            height="29.539"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_5384" data-name="Group 5384" transform="translate(-38)">
        <g
          id="Group_5382"
          data-name="Group 5382"
          transform="translate(38)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_2334"
            data-name="Path 2334"
            d="M17.946,14.5a2.667,2.667,0,0,0-.759-1.572L5.689,1.066A3.484,3.484,0,0,0,1.2.621,2.613,2.613,0,0,0,.7,4.6a1.572,1.572,0,0,0,.133.133l9.722,10.036L.837,24.8a2.6,2.6,0,0,0,.2,4,3.492,3.492,0,0,0,4.513-.18c.047-.045.092-.091.133-.133l11.5-11.866a2.62,2.62,0,0,0,.759-2.1"
            transform="translate(-0.001 0.001)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

const columnData = [
  {
    desc: 'Most lung cancers express high levels of TROP-2.',
    buttonText: 'LEARN MORE ABOUT TROP-2',
    linkTo: '/lungwall/6/0/3',
    gtagId: 'TROP2_Select_Option',
    gtagText: 'TROP2_Select_Option'
  },
  {
    desc: 'Treatment with EGFR-TKIs has been linked to an increase in HER3 expression.',
    buttonText: 'LEARN MORE ABOUT HER3',
    linkTo: '/lungwall/6/0/2',
    gtagId: 'HER3_Select_Option',
    gtagText: 'HER3_Select_Option'
  }
];

const imgList = [
  {
    id: 1,
    imgSrc: orginalImg
  },
  {
    id: 2,
    imgSrc: greenImg,
    linkTo: '/lungwall/6/0/0'
  },
  {
    id: 3,
    imgSrc: orangeImg,
    linkTo: '/lungwall/6/0/1'
  }
];

const PieChart = ({ videoEl }) => {
  const [imgState, setImgState] = useState(1);
  const navigate = useNavigate();

  const handleMouseEnter = (next) => {
    setImgState(next);
  };

  const handleMouseLeave = () => {
    setImgState(1);
  };

  const handleLinkClick = (link) => {
    console.log('link clicked', link);
    videoEl.current.currentTime = 485.0;
    navigate(link);
  };

  return (
    <div className="piechart-container app">
      <div className="title" style={{ textAlign: 'left' }}>
        MOLECULAR NSCLC SUBTYPES
      </div>
      <div className="content" style={{ marginTop: '10px' }}>
        About 70% of NSCLC adenocarcinoma harbors a known genomic alteration.
      </div>
      <div className="piechart-content">
        <div className="piechart-img">
          {imgList.map((item, i) => {
            return (
              <>
                <img
                  src={item.imgSrc}
                  alt=""
                  className="piechart-img"
                  key={i}
                  style={{ display: imgState !== item.id && 'none' }}
                />
              </>
            );
          })}
          <div
            id="EFGR_Select_Option_piechart"
            text="EFGR_Select_Option"
            className="hotspot-green"
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleLinkClick('/lungwall/6/0/0')}
          />
          <div
            id="HER2_Select_Option_piechart"
            text="HER2_Select_Option"
            className="hotspot-orange"
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleLinkClick('/lungwall/6/0/1')}
          />
          <div
            id="HER2_Select_Option"
            text="HER2_Select_Option"
            className="hotspot-orange-learnmore"
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleLinkClick('/lungwall/6/0/1')}
          />
          <div
            id="EFGR_Select_Option"
            text="EFGR_Select_Option"
            className="hotspot-green-learnmore"
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleLinkClick('/lungwall/6/0/0')}
          />
        </div>
        <div className="piechart-column">
          {columnData.map((item, i) => {
            let gtagAttrs = {};
            if (item.gtagId !== undefined && item.gtagText !== undefined) {
              gtagAttrs = {
                'id': item.gtagId,
                'text': item.gtagText
              };
            }
            return (
              <div className="piechart-column-item">
                <div className="piechart-column-item-desc smaller">{item.desc}</div>
                <div
                  {...gtagAttrs}
                  className="piechart-column-item-button smaller"
                  onClick={() => handleLinkClick(item.linkTo)}
                >
                  {item.buttonText}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="button-row">
        {/* <div
          className="button-item smaller button-green"
          onClick={() => {
            videoEl.current.currentTime = 508.5;
            videoEl.current.play();
          }}
        >
          ASSESS STAGE <ButtonArrow />
        </div> */}

        <div
          id="Test_to_Determine_Select_Option"
          text="Test_to_Determine_Select_Option"
          className="button-item smaller button-blue "
          onClick={() => {
            videoEl.current[2].currentTime = 253.0;
            videoEl.current[2].play();
          }}
        >
          TEST TO DETERMINE MOLECULAR SUBTYPE <ButtonArrow />
        </div>
      </div>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', margin: '0 auto' }}>
        <TrainingWarning isTrainingLabelOpen={true} PieChart={true} />
      </div>
    </div>
  );
};

export default PieChart;
