import { DNARightCentered, DNARightAccordion } from '../../components/DNARightContent';
import '../../styles/slides.css';

/************************************
           ICH SLIDES               *
*************************************/

const IHCScore3 = () => {
  return (
    <DNARightCentered
      rightHeadline="IHC Score 3+ Is HER2+"
      rightCaption="NCCN Guidelines<sup>&reg;</sup> recommend patients with HER2+ disease receive HER2-targeted therapy, regardless of hormone receptor (HR) status, based on the testing method outlined in the ASCO/CAP HER2 testing guidelines."
    />
  );
};

const IHCScore2 = () => {
  return (
    <>
      <div className="bolded-header">IHC Score 2+ Is Equivocal</div>
      <div className="bolded-list-title">Reflex to ISH if using same specimen*</div>
      <ul className="slide-list">
        <li>Sample is HER2+ if ISH positive</li>
        <li>Sample may be HER2-negative if ISH negative</li>
        <div style={{ display: 'flex', gap: '2px' }}>
          <div>&ndash;</div>
          <div style={{ flex: 1 }}>
            <b>
              Approximately 60% of breast cancers currently categorized as HER2-negative express
              low levels of HER2
            </b>
            , which is actionable. In a proposed paradigm for identifying low HER2 expression,
            HER2 IHC score of 1+ or 2+/ISH negative would be considered HER2-low
          </div>
        </div>
      </ul>
    </>
  );
};

const IHCScore1 = () => {
  return (
    <>
      <div className="bolded-header">IHC Score 1+ Is HER2-Negative</div>
      <div>
        <b>
          Approximately 60% of breast cancers currently categorized as HER2-negative express low
          levels of HER2, which is actionable.
        </b>{' '}
        In a proposed paradigm for identifying low HER2 expression, HER2 IHC score of 1+ or 2+/ISH
        negative would be considered HER2-low.
      </div>
    </>
  );
};

const IHCScore0 = () => {
  return <div className="bolded-header">IHC Score 0 Is HER2-Negative</div>;
};

/************************************
           ISH SLIDES               *
*************************************/

const ISHGreaterThanSix = () => {
  return (
    <DNARightCentered
      rightHeadline="Average HER2 Copy Number ≥6 Signals/Cell Is ISH Positive"
      rightCaption="NCCN Guidelines recommend patients with HER2+ disease receive HER2-targeted therapy, regardless of hormone receptor (HR) status, based on the testing method outlined in the ASCO/CAP HER2 testing guidelines."
    />
  );
};

const ISHBetweenSixAndFour = () => {
  return (
    <DNARightAccordion
      rightHeadline="Average HER2 Copy Number ≥4 and <6.0 Signals/Cell Requires Concurrent IHC or Dual-Probe ISH"
      rightSubHeadLine="Using sections from the same tissue sample, perform IHC if not already performed and/or perform dual-probe ISH."
      waterFallHeadingGreen="EXPLORE POTENTIAL RESULTS"
      waterFallHeadingWhite="OF ADDITIONAL IHC AND/OR DUAL-PROBE ISH"
      waterFallContent={<ISHBetweenSixAndFourContent />}
    />
  );
};

const ISHLessThanFour = () => {
  return (
    <>
      <h3 className="bolded-header">Average HER2 Copy Number &lt;4 Signals/Cell Is ISH Negative</h3>
      <div>
        <b>
          Approximately 60% of breast cancers currently categorized as HER2-negative express low
          levels of HER2, which is actionable.
        </b>{' '}
        In a proposed paradigm for identifying low HER2 expression, HER2 IHC score of 1+ or 2+/ISH
        negative would be considered HER2-low.
      </div>
    </>
  );
};

const ISHGroup1 = () => {
  return (
    <DNARightCentered
      rightHeadline="Group 1 Is ISH Positive"
      rightBullet="HER2/CEP17 ratio ≥2, average HER2 copy number ≥4 signals/cell"
      rightCaption="NCCN Guidelines recommend patients with HER2+ disease receive HER2-targeted therapy, regardless of hormone receptor (HR) status, based on the testing method outlined in the ASCO/CAP HER2 testing guidelines."
    />
  );
};

const ISHGroup1HER2 = () => {
  return (
    <DNARightCentered
      rightHeadline="Group 1 Is ISH Positive"
      rightBullet="HER2/CEP17 ratio ≥2, average HER2 copy number ≥4 signals/cell"
      rightCaption="NCCN Guidelines<sup><small>&reg;</small></sup> recommend patients with HER2+ disease receive HER2-targeted therapy, regardless of hormone receptor (HR) status, based on the testing method outlined in the ASCO/CAP HER2 testing guidelines."
    />
  );
};

const ISHGroup2 = () => {
  return (
    <DNARightAccordion
      rightHeadline="Group 2 requires additional testing"
      rightSubHeadLine=""
      rightBullet1="HER2/CEP17 ratio ≥2, average HER2 copy number <4 signals/cell"
      rightBullet2="Reflex to IHC, using sections from the same sample"
      waterFallHeadingGreen="EXPLORE POTENTIAL RESULTS"
      waterFallHeadingWhite="AFTER IHC"
      waterFallContent={<ISHGroup2PathAndIQContent />}
    />
  );
};

const ISHGroup3 = () => {
  return (
    <DNARightAccordion
      rightHeadline="Group 3 requires additional testing"
      rightSubHeadLine=""
      rightBullet1="HER2/CEP17 ratio <2, average HER2 copy number ≥6 signals/cell"
      rightBullet2="Reflex to IHC, using sections from the same sample"
      waterFallHeadingGreen="EXPLORE POTENTIAL RESULTS"
      waterFallHeadingWhite="AFTER IHC"
      waterFallContent={<ISHGroup3PathAndIQContent />}
    />
  );
};

const ISHGroup4 = () => {
  return (
    <DNARightAccordion
      rightHeadline="Group 4 requires additional testing"
      rightSubHeadLine=""
      rightBullet1="HER2/CEP17 ratio <2.0, average HER2 copy number ≥4.0 and <6.0 signals/cell"
      rightBullet2="Reflex to IHC, using sections from the same sample"
      waterFallHeadingGreen="EXPLORE POTENTIAL RESULTS"
      waterFallHeadingWhite="AFTER IHC"
      waterFallContent={<ISHGroup4PathAndIQContent />}
    />
  );
};

const ISHGroup5 = () => {
  return (
    <>
      <h3 className="bolded-header">Group 5 is ISH negative</h3>
      <ul className="slide-list">
        <li>HER2/CEP17 ratio &lt;2, average HER2 copy number &lt;4 signals/cell</li>
      </ul>
      <br />
      <div>
        <b>
          Approximately 60% of breast cancers currently categorized as HER2-negative express low
          levels of HER2, which is actionable.
        </b>{' '}
        In a proposed paradigm for identifying low HER2 expression, HER2 IHC score of 1+ or 2+/ISH
        negative would be considered HER2-low.
      </div>
    </>
  );
};

const ISHGroup2PathAndIQContent = () => {
  return (
    <ul>
      <li>
        <b>Sample is HER2+ if IHC score is 3+</b>
      </li>
      <li>
        <b>A blinded observer should recount ISH, in at least 20 cells, if IHC score is 2+</b>
      </li>
      <table style={{ marginLeft: 10 }}>
        <tbody>
          <tr>
            <td>-</td>
            <td>
              If HER2/CEP17 ratio &ge;2 and average HER2 copy number &lt;4 signals/cell, result is
              HER2-negative with comment*
            </td>
          </tr>
          <tr>
            <td>-</td>
            <td>
              If other ISH result, adjudicate per internal procedure to determine final category
            </td>
          </tr>
        </tbody>
      </table>
      <li>
        <b>Sample is HER2-negative with comment* if IHC score is 0 or 1+</b>
      </li>
    </ul>
  );
};

const ISHGroup3PathAndIQContent = () => {
  return (
    <ul>
      <li>
        <b>Sample is HER2+ if IHC score is 3+</b>
      </li>
      <li>
        <b>A blinded observer should recount ISH, in at least 20 cells, if IHC score is 2+</b>
      </li>
      <table style={{ marginLeft: 10 }}>
        <tbody>
          <tr>
            <td>-</td>
            <td>
              If HER2/CEP17 ratio &lt;2 and average HER2 copy number &ge;6 signals/cell, result is
              HER2+
            </td>
          </tr>
          <tr>
            <td>-</td>
            <td>
              If other ISH result, adjudicate per internal procedure to determine final category
            </td>
          </tr>
        </tbody>
      </table>
      <li>
        <b>Sample is HER2-negative with comment* if IHC score is 0 or 1+</b>
      </li>
    </ul>
  );
};

const ISHGroup4PathAndIQContent = () => {
  return (
    <ul>
      <li>
        <b>Sample is HER2+ if IHC score is 3+</b>
      </li>
      <li>
        <b>A blinded observer should recount ISH, in at least 20 cells, if IHC score is 2+</b>
      </li>
      <table style={{ marginLeft: 10 }}>
        <tbody>
          <tr>
            <td>-</td>
            <td>
              If HER2/CEP17 ratio &lt;2.0, average HER2 copy number &ge;4.0 and &lt;6.0
              signals/cell, result is HER2-negative with comment*
            </td>
          </tr>
          <tr>
            <td>-</td>
            <td>
              If other ISH result, adjudicate per internal procedure to determine final category
            </td>
          </tr>
        </tbody>
      </table>
      <li>
        <b>Sample is HER2-negative with comment* if IHC score is 0 or 1+</b>
      </li>
    </ul>
  );
};

const ISHBetweenSixAndFourContent = () => {
  return (
    <ul className="slide-list">
      <li>
        <b>Sample is ISH positive if</b> concurrent IHC score is 3+ and/or concurrent dual-probe ISH
        group is 1
      </li>
      <li>
        <b>Perform dual-probe ISH for final result if</b> concurrent IHC score is 2+
      </li>
      <li>
        <b>Sample is ISH negative if</b> concurrent IHC score is 0, 1+, and/or concurrent dual-probe
        ISH group is 5
      </li>
    </ul>
  );
};

/************************************
           NGS SLIDES               *
*************************************/

const HER2Positive = () => {
  return (
    <>
      <h3 className="bolded-header">Patient may be eligible for HER2-targeted therapy.</h3>
    </>
  );
};

const HER2NegativeExplained = () => {
  return (
    <>
      <h3 className="bolded-header">Patient is likely ineligible for HER2-targeted therapy.</h3>
      <p>
        Reflexing to an FDA-approved companion diagnostic is recommended for HER2 amplification
        results that indicate 2 additional copies over the baseline (of 2) to confirm results.
        Although this result is considered negative by FoundationOne<sup>&reg;</sup> CDx, in a
        clinical concordance study with an <span style={{ whiteSpace: 'nowrap' }}>FDA-approved</span> FISH test, 70% were positive and 30% were
        negative by the FISH test, with an average ratio of 2.3.
      </p>
    </>
  );
};

const HER2NegativeTitle = () => {
  return (
    <>
      <h3 className="bolded-header">Patient is likely ineligible for HER2-targeted therapy.</h3>
    </>
  );
};

const HER2NegativeNotConclusive = () => {
  return (
    <>
      <h3 className="bolded-header">
        A negative result is not conclusive for absence of biomarkers.
        <br /> Reflex to tissue testing to confirm results.
      </h3>
    </>
  );
};

export {
  IHCScore3,
  IHCScore2,
  IHCScore1,
  IHCScore0,
  ISHGreaterThanSix,
  ISHBetweenSixAndFour,
  ISHLessThanFour,
  ISHGroup1,
  ISHGroup2,
  ISHGroup3,
  ISHGroup4,
  ISHGroup5,
  HER2Positive,
  HER2NegativeExplained,
  HER2NegativeTitle,
  HER2NegativeNotConclusive,
  ISHGroup1HER2
};
