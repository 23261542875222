import React from 'react';

const Laboratory = ({ content, buttons, footer, footer2 }) => (
  <>
    <div className="laboratory-container app">
      <div className="title">Laboratory-Developed Tests</div>
      <div className="content">Laboratory-developed tests (LDTs)* are not FDA approved.</div>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      {buttons}
    </div>
    {footer2 ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flex: 1,
          width: '100%'
        }}
      >
        <div className="landing-footer" dangerouslySetInnerHTML={{ __html: footer }} />
        <div className="lab-footer" dangerouslySetInnerHTML={{ __html: footer2 }} />
      </div>
    ) : (
      <div className="lab-footer" dangerouslySetInnerHTML={{ __html: footer }} />
    )}
  </>
);

export default Laboratory;
