export const glossaryConfig = {
  'Basophils': 'Are involved in allergic reaction and asthma.',

  'Vitamin B12':
    'B12 is needed for RBC formation. Macrocytic anemia may be caused by folate or B12 deficiency.',

  'Eosinophils':
    'Defend against parasites and infections and are involved in allergic reactions and inflammation.',

  'Ferritin':
    'A circulating iron storage protein that is elevated according to iron stores in the body; the lower the level of ferritin, the more likely the patient is iron deficient. Ferritin can be elevated regardless of iron stores in conditions associated with inflammation (eg, infection, liver disease, heart failure, and cancer).',

  'Folate':
    'Folate, also known as B9, plays a role in RBC formation. Macrocytic anemia may be caused by folate or B12 deficiency.',

  'Functional Iron Deficiency':
    'Unlike absolute iron deficiency which is the absence of or severe reduction in iron stores, functional iron deficiency refers to adequate iron stores with a limited ability to harness it into RBC production.',

  'Serum Iron':
    'Measurement of circulating iron. Serum iron is low in iron deficiency as well as in anemia of chronic disease or inflammation, and it can fluctuate according to diet or supplement intake. Thus, by itself, iron is not diagnostic and must be evaluated with other tests such as ferritin or TSAT.',

  'Hb concentration':
    'A measure of the concentration of the major oxygen-carrying element in the blood.',

  'Hematocrit': 'The percentage of intact RBCs in a whole blood sample.',

  'Lymphocytes':
    'Consist of T cells, natural killer cells, and B cells; produce antibodies and defend against viral infections.',

  'Macrocytic anemia':
    'Characterized by larger RBCs, MCV >100 fL. Macrocytic anemia is likely caused by folate or B12 deficiency.',

  'Microcytic anemia':
    'Characterized by smaller RBCs, MCV <80 fL. Microcytic anemia is likely caused by iron deficiency.',

  'Monocytes':
    'Destroy bacteria, viruses, and other germs; boost immune response and clear away dead cells.',

  'Neutrophils':
    'The most common type of WBC; defend against viruses, bacteria, fungi, and other pathogens.',

  'Platelet count':
    'Platelets, also known as thrombocytes, are small components of the cytoplasm that are involved in blood clotting and tissue repair. Platelet count may be elevated in patients with iron deficiency anemia.',

  'Reticulocyte count':
    'Immature red blood cells. Reticulocytes typically comprise 1% to 2% of RBC count; deviations signal a change in the rate of red blood cell formation.',

  'RBC count':
    'The number of RBCs in a specific volume of whole blood. This can help determine the mean corpuscular volume (MCV), which is the size of the red blood cells.',

  'TIBC':
    'An indirect measurement of serum transferrin, a circulating iron transport protein that is elevated in iron deficiency. In anemias of chronic conditions, however, transferrin may be reduced.',

  'TSAT':
    'TSAT measures the ratio of serum iron to TIBC (Iron/TIBC). TSAT is lower in patients with iron deficiency because in those individuals, iron is reduced while TIBC is elevated.',

  'WBC count':
    'WBCs, also known as leukocytes, play an essential role in immune defense and debris cleanup. WBC count is helpful in diagnosing diseases that can be associated with either elevated or reduced WBC count.'
};
