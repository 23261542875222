import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoHotSpots from './VideoHotSpots';

const ONE_MINUTE = 60.0;
const TWO_MINUTES = 120.0;
const THREE_MINUTES = 180.0;
const FOUR_MINUTES = 240.0;
const FIVE_MINUTES = 300.0;
const SIX_MINUTES = 360.0;
const SEVEN_MINUTES = 420.0;
const EIGHT_MINUTES = 480.0;
const NINE_MINUTES = 540.0;
const TEN_MINUTES = 600.0;
const ELEVEN_MINUTES = 660.0;
const TWELVE_MINUTES = 720.0;
const THIRTEEN_MINUTES = 780.0;

const videoIndexs = {
  INTRO: 0,
  BREAST: 1,
  LUNG: 2,
  ANEMIA: 3
};

const VideoPlayer = ({
  videoUrls,
  videoEl,
  activeVideoIndex,
  setActiveVideoIndex,
  videosPreloaded,
  setVideosPreloaded,
  setOverlayOpen,
  overlayOpen,
  posterImg
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const navigate = useNavigate();
  const currentSectionRef = useRef(null);
  const handleVideoChange = (newIndex, newTime) => {
    //LOGIC HERE IS TO PAUSE CURRENT VIDEO, SET THE NEW TIME AT THE CORRECT INDEX
    //AND THEN SWITCH TO THE CORRECT INDEX AND ITS ALREADY AT THAT TIME
    videoEl.current[activeVideoIndex].pause();
    console.log(videoEl);
    videoEl.current[newIndex].currentTime = newTime;
    setActiveVideoIndex(newIndex);
  };

  const toggleDisplayedVideo = (index) => {
    if (videoEl.current[index].style.display !== 'block') {
      videoEl.current[index].style.display = 'block';
      for (let i = 0; i < videoEl.current.length; i++) {
        if (i !== index) {
          videoEl.current[i].style.display = 'none';
        }
      }
    }
  };

  const videoClause = (videoIndex, startTime) => {
    return (
      videoEl.current[videoIndex]?.currentTime >= startTime &&
      videoEl.current[videoIndex]?.currentTime < startTime + 1.0
    );
  };

  /**
   *  FOLLOWING IS ALL THE SECTIONS THAT THE POP UPS SHOULD BE OPENED AND OVERLAYED IS TRUE
   */

  //BREAST SECTION
  const isIHCSection = (videoIndex, startTime) => {
    return startTime >= THREE_MINUTES + 50.0 && startTime < 268 && videoIndex === 1;
  };
  const isISHSection = (videoIndex, startTime) => {
    return startTime >= 268 && startTime < 344 && videoIndex === 1;
  };
  const isTissueSection = (videoIndex, startTime) => {
    return startTime >= FIVE_MINUTES + 45 && startTime < 360 && videoIndex === 1;
  };
  const isLiquidSection = (videoIndex, startTime) => {
    return startTime >= 394 && startTime < 409 && videoIndex === 1;
  };

  //LUNG SECTION
  const isDiagnosticImagingSection = (videoIndex, startTime) => {
    return startTime >= 54 && startTime < 55 && videoIndex === 2;
  };

  const isTumorBiopsySection = (videoIndex, startTime) => {
    return startTime >= ONE_MINUTE + 9 && startTime < ONE_MINUTE + 11 && videoIndex === 2;
  };

  const isPiechartSection = (videoIndex, startTime) => {
    return startTime >= FOUR_MINUTES + 6 && startTime < FOUR_MINUTES + 10 && videoIndex === 2;
  };

  const isTissueNGSSection = (videoIndex, startTime) => {
    return startTime >= FIVE_MINUTES + 50 && startTime < SIX_MINUTES + 23 && videoIndex === 2;
  };

  const isLiquidBiopsySection = (videoIndex, startTime) => {
    return startTime >= SIX_MINUTES + 56 && startTime < SEVEN_MINUTES + 29 && videoIndex === 2;
  };

  const handleIntroVideoProgress = () => {
    const CURRENT_INDEX = videoIndexs.INTRO;

    console.log(
      'CURRENT VIDEO TIME IN INTRO: ',
      videoEl.current[activeVideoIndex]?.currentTime,
      activeVideoIndex
    );

    toggleDisplayedVideo(activeVideoIndex);

    switch (true) {
      //NEW VIDEO CLAUSE
      case videoClause(CURRENT_INDEX, 1):
        videoEl.current[activeVideoIndex].currentTime = 5;
        break;
      case videoClause(CURRENT_INDEX, 14):
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].play();
        break;
      case videoClause(CURRENT_INDEX, 25):
        videoEl.current[activeVideoIndex].currentTime = 12;
        break;
      case videoClause(CURRENT_INDEX, 35):
        //SWITCH TO ANEMIA VIDEO
        console.log('I AM HERE');
        handleVideoChange(videoIndexs.ANEMIA, 35);
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 9.0):
        //SWITCH TO LUNG CANCER VIDEO
        handleVideoChange(videoIndexs.LUNG, 29);
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 45.0):
        //SWITCH TO BREAST CANCER VIDEO
        handleVideoChange(videoIndexs.BREAST, 18);
        break;
      default:
        setOverlayOpen(false);
        console.log('WE ARE IN THE DEFAULT CASE');
        break;
    }
  };

  const handleBreastVideoProgress = () => {
    const CURRENT_INDEX = videoIndexs.BREAST;

    toggleDisplayedVideo(activeVideoIndex);

    console.log(
      'CURRENT VIDEO TIME IN BREAST: ',
      videoEl.current[activeVideoIndex]?.currentTime,
      activeVideoIndex
    );

    switch (true) {
      case videoClause(CURRENT_INDEX, 19.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '45');
        break;
      case videoClause(CURRENT_INDEX, 22.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '46');
        break;
      case videoClause(CURRENT_INDEX, 25.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '47');
        break;
      case videoClause(CURRENT_INDEX, 27.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '48');
        break;
      //Loop video on T Assess Primary Tumor
      case videoClause(CURRENT_INDEX, 43.0):
        videoEl.current[activeVideoIndex].currentTime = 30.0;
        console.log('we are in section:', '49');
        break;
      //Loop video on T Assess Primary Tumor Part Two
      case videoClause(CURRENT_INDEX, 63.0):
        videoEl.current[activeVideoIndex].currentTime = 49.5;
        console.log('we are in section:', '50');
        break;
      case videoClause(CURRENT_INDEX, 76.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '51');
        break;
      case videoClause(CURRENT_INDEX, 78.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '52');
        break;
      //Loop video on N Asses Regional Nodal 22 - 35
      case videoClause(CURRENT_INDEX, 95.0):
        videoEl.current[activeVideoIndex].currentTime = 82.0;
        console.log('we are in section:', '53');
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 42.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '54');
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 44.5):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '55');
        break;
      //LOOP M ASSESS METASTASES
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 1.0):
        videoEl.current[activeVideoIndex].currentTime = ONE_MINUTE + 48.0;
        console.log('we are in section:', '56');
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 8.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '57');
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 19.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '58');
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 21.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '59');
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 24.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '60');
        break;
      case videoClause(CURRENT_INDEX, 145.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '61');
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 32.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '62');
        break;
      //TUMOR BIOPSY LOOP
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 54.0):
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 41.0;
        console.log('we are in section:', '63');
        break;
      // case videoClause(CURRENT_INDEX, THREE_MINUTES + 1.0):
      //   videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 20.0;
      //console.log('we are in section:', '');//
      //break;
      //TUMOR BIOPSY LOOP
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 16.0):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 4.0;
        console.log('we are in section:', '64');
        break;
      //TUMOR BIOPSY LOOP
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 38.0):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 24.0;
        console.log('we are in section:', '65');
        break;

      //BRING BIOPSYS TO BIOPSY TUMOR PAGE 3:01 3:21 3:42 to 3:43
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 1.0):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 44.0;
        console.log('we are in section:', '66');
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 21.4):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 44.0;
        console.log('we are in section:', '67');
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 42.3):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 44.0;
        console.log('we are in section:', '68');
        break;
      //LIQUID BIOSPY PAUSE
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 5.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '69');
        break;
      //LIQUID BIOPSY LOOP
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 25.0):
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 13.0;
        console.log('we are in section:', '70');
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 47.0):
        videoEl.current[activeVideoIndex].pause();
        console.log('we are in section:', '71');
        break;

      //ICH ENTER
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 49.0):
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 50.0;
        navigate('/home/0');
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 28):
        videoEl.current[activeVideoIndex].currentTime = FOUR_MINUTES + 29;
        navigate('/home/1');
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 44):
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 45;
        navigate('/home/2');
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 33):
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 34;
        navigate('/home/3');
        console.log('we are in section:', '71');
        break;

      case isIHCSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;
      case isISHSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;
      case isTissueSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        console.log('WE ARE IN TISSUE SECTION');
        videoEl.current[activeVideoIndex].pause();
        break;
      case isLiquidSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;
      case !isIHCSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isISHSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isTissueSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isLiquidSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        console.log('WE ARE IN THE DEFAULT CASE');
        currentSectionRef.current = null;
        setOverlayOpen(false);
        break;

      default:
    }
  };

  const handleLungVideoProgress = () => {
    const CURRENT_INDEX = videoIndexs.LUNG;

    toggleDisplayedVideo(activeVideoIndex);

    switch (true) {
      //NEW VIDEO CLAUSE
      case videoClause(CURRENT_INDEX, 29):
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, 40):
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, 51):
        videoEl.current[activeVideoIndex].pause();
        break;
      // case videoClause(CURRENT_INDEX, 54):
      //   //IN THE LUNGWALL SECTIONS
      //   videoEl.current[activeVideoIndex].pause();
      //   break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 6):
        //IN THE TUMOR BIOPSY SECTION
        videoEl.current[activeVideoIndex].pause();
        break;
      // case videoClause(CURRENT_INDEX, ONE_MINUTE + 9):
      //   //IN THE LUNGWALL SECTIONS
      //   videoEl.current[activeVideoIndex].pause();
      //   break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 19):
        //IN THE HISTOLOGICAL SECTION
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 25):
        //HISTOLOGICAL ADENO POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 44):
        //HISTOLOGICAL SQUAMOUS POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 35):
        //HISTOLOGICAL LARGE POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 50.0):
        //IN THE ASSES STAGE LANDING SECTION
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 53.0):
        //ASSES LANDING CTNM POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 55.0):
        //ASSES LANDING PTNM POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES):
        //MENU TIME IN PLAY
        videoEl.current[activeVideoIndex].play();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 12.0):
        //ASSES PRIMARY TUMOR LOOP
        videoEl.current[activeVideoIndex].currentTime = ONE_MINUTE + 59.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 31.0):
        //ASSES PRIMARY TUMOR LOOP
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 20.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 38.0):
        //ASSES PRIMARY TUMOR LOOP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 42.0):
        //ASSES PRIMARY TUMOR LOOP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 50.0):
        //MENU TIME IN PLAY
        videoEl.current[activeVideoIndex].play();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 3.0):
        //ASSES REGIONAL LOOP
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 49.0;
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 12.0):
        //ASSES REGIONAL NODAL INVOLVMENET
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 15.0):
        //ASSES REGIONAL NODAL INVOLVMENET
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 20.0):
        //MENU TIME IN PLAY
        videoEl.current[activeVideoIndex].play();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 34.0):
        //ASSES M METASTASIS LOOPS
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 20.0;
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 54.0):
        //ASSES M METASTASIS M Score
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 56.0):
        //ASSES M METASTASIS M Score REVIEW POP UP
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES):
        //ASSES M METASTASIS M final tnm stage
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 6):
        //TEST FOR BIOMARKERS
        videoEl.current[activeVideoIndex].pause();
        break;
      // case videoClause(CURRENT_INDEX, FOUR_MINUTES + 8):
      //   //MOLECULAR SUBTYPES AND PIECHART
      //   videoEl.current[activeVideoIndex].pause();
      //   break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 17):
        //TEST FOR SAMPEL COLLECTIONS
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 21):
        //TUMOR BIOPSY -
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 40):
        //TUMOR BIOPSY
        videoEl.current[activeVideoIndex].currentTime = FOUR_MINUTES + 26.0;
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 46):
        //END OF 22 GAUGE ANIMATION
        //TO DO: THIS LEADS USERS TO THE TISSUE NGS HOME PAGE
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 50.0;
        navigate('/home/5');
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 1):
        //endoscope loop
        videoEl.current[activeVideoIndex].currentTime = FOUR_MINUTES + 50.0;
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 15):
        //END OF ENDOSCOPE ANIMATION
        //TO DO: THIS LEADS USERS TO THE TISSUE NGS HOME PAGE
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 50.0;
        navigate('/home/5');
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 33):
        //MEDIASCOPE LOOP
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 19.0;
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 43):
        //END OF MEDIA ANIMATION
        //TO DO: THIS LEADS USERS TO THE TISSUE NGS HOME PAGE
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 50.0;
        navigate('/home/5');
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 26):
        //LIQUID BIOPSY START
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 47):
        //LIQUID BIOPSY NEED LOOOP
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 36.0;
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 52):
        //LIQUID BIOPSY END
        //TO DO: THIS LEADS USERS TO THE LIQUID NGS HOME PAGE
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 56.0;
        navigate('/home/4');
        break;

      case isDiagnosticImagingSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;

      case isTumorBiopsySection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;

      case isPiechartSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        navigate('/piechart');
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;

      case isTissueNGSSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        // navigate('/home/5');
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;

      case isLiquidBiopsySection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        //navigate('/home/4');
        setOverlayOpen(true);
        videoEl.current[activeVideoIndex].pause();
        break;

      case !isDiagnosticImagingSection(
        CURRENT_INDEX,
        videoEl.current[activeVideoIndex].currentTime
      ) &&
        !isTumorBiopsySection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isPiechartSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isTissueNGSSection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime) &&
        !isLiquidBiopsySection(CURRENT_INDEX, videoEl.current[activeVideoIndex].currentTime):
        console.log('WE ARE IN THE DEFAULT CASE');
        currentSectionRef.current = null;
        setOverlayOpen(false);
        break;

      default:
    }
  };

  const handleAnemiaVideoProgress = () => {
    const CURRENT_INDEX = videoIndexs.ANEMIA;

    console.log(
      'CURRENT VIDEO TIME IN ANEMIA: ',
      videoEl.current[activeVideoIndex]?.currentTime,
      activeVideoIndex
    );

    toggleDisplayedVideo(activeVideoIndex);

    switch (true) {
      case videoClause(CURRENT_INDEX, 35):
        //ANEMIA LANDING PAGE
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, 42):
        //SCREENING FOR IDA
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, 49):
        //SCREENING FOR IDA
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 9.0):
        //CBC WITHOUT DIFFERENTIAL LOOP
        videoEl.current[activeVideoIndex].currentTime = 59;
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 38.0):
        //CBC WITHOUT IRON PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 14.0):
        //SKIP ANIMATION
        videoEl.current[activeVideoIndex].currentTime = ONE_MINUTE + 19.0;
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 24.0):
        //CBC WITHOUT DIFFERENTIAL LANDING
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 55.0):
        //CBC WITHOUT DIFFERENTIAL IRON PANEL LOOP
        videoEl.current[activeVideoIndex].currentTime = ONE_MINUTE + 45.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES):
        //CBC WITHOUT DIFFERENTIAL LANDING
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 2.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 6.0):
        //CBC WITHOUT DIFFERENTIAL LANDING
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 20.0):
        //CBC WITHOUT DIFFERENTIAL ANEMIA PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 37.0):
        //CBC WITHOUT DIFFERENTIAL ANEMIA LOOP
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 27.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 41):
        //CBC WITHOUT DIFFERENTIAL LANDING
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 43.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 48):
        //CBC WITHOUT DIFFERENTIAL LANDING
        videoEl.current[activeVideoIndex].currentTime = TWO_MINUTES + 50.0;
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 54.0):
        //CBC WITHout DIFFERENTIAL anemia panel
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 10):
        //CBC WITHOUT DIFFERENTIAL vitamin START
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 29):
        //CBC WITHOUT DIFFERENTIAL vitamin loop
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 19;
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 34):
        //CBC WITHOUT DIFFERENTIAL vitamin loop
        videoEl.current[activeVideoIndex].currentTime = THREE_MINUTES + 39;
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 43):
        //CBC WITHOUT DIFFERENTIAL vitamin TEST
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES):
        //IDA DIAGNOSIS
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 5):
        //IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 10):
        //IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 18):
        //IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 20):
        //IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 23):
        //IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 30.0):
        //CBC WITH DIFFERENTIAL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 49.0):
        //CBC WITH DIFFERENTIAL LOOP
        videoEl.current[activeVideoIndex].currentTime = FOUR_MINUTES + 39.0;
        break;
      case videoClause(CURRENT_INDEX, FOUR_MINUTES + 55.0):
        //CBC WITH DIFFERENTIAL LOOP
        videoEl.current[activeVideoIndex].currentTime = FOUR_MINUTES + 59.0;
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 3.0):
        //CBC WITH DIFFERENTIAL page'
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 26.0):
        //CBC WITH DIFFERENTIAL iron panel
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 43.0):
        //CBC WITH DIFFERENTIAL iron panel loop
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 33.0;
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 48.0):
        //CBC WITH DIFFERENTIAL LOOP
        videoEl.current[activeVideoIndex].currentTime = FIVE_MINUTES + 52.0;
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 55.0):
        //CBC WITH DIFFERENTIAL iron panel
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 10.0):
        //CBC WITH DIFFERENTIAL anemia panel
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 28):
        //CBC WITH DIFFERENTIAL anemia loop
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 18.0;
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 33):
        //CBC WITH DIFFERENTIAL anemia loop
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 39.0;
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 43):
        //CBC WITH DIFFERENTIAL anemia loop
        videoEl.current[activeVideoIndex].currentTime = SIX_MINUTES + 45.0;
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 47.5):
        //CBC WITH DIFFERENTIAL anemia panel
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES):
        //CBC WITH DIFFERENTIAL VITAMIN DEF TEST
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 17):
        //CBC WITH DIFFERENTIAL VITAMIN DEF TEST LOOP
        videoEl.current[activeVideoIndex].currentTime = SEVEN_MINUTES + 7;
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 22):
        //CBC WITH DIFFERENTIAL VITAMIN DEF TEST LOOP
        videoEl.current[activeVideoIndex].currentTime = SEVEN_MINUTES + 27;
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 32):
        //CBC WITH DIFFERENTIAL VITAMIN DEF TEST
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 41):
        //CBC WITH DIFFERENTIAL IDA DIAGNOSIS
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 45):
        //CBC WITH DIFFERENTIAL IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 52):
        //CBC WITH DIFFERENTIAL IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES):
        //CBC WITH DIFFERENTIAL IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 7):
        //CBC WITH DIFFERENTIAL IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 13):
        //CBC WITHOUT DIFFERENTIAL REFLEX
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 29):
        //CBC WITHOUT DIFFERENTIAL REFLEX LOOP
        videoEl.current[activeVideoIndex].currentTime = EIGHT_MINUTES + 19;
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 34):
        //CBC WITHOUT DIFFERENTIAL REFLEX LOOP
        videoEl.current[activeVideoIndex].currentTime = EIGHT_MINUTES + 39;
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 42):
        //CBC WITHOUT DIFFERENTIAL REFLEX LOOP
        videoEl.current[activeVideoIndex].currentTime = EIGHT_MINUTES + 45;
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 48):
        //CBC WITHOUT DIFFERENTIAL REFLEX panel
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 1):
        //CBC WITHOUT DIFFERENTIAL REFLEX IDA DIAGNOSIS
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 7):
        //CBC WITHOUT DIFFERENTIAL REFLEX IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 12):
        //CBC WITHOUT DIFFERENTIAL REFLEX IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 17):
        //CBC WITHOUT DIFFERENTIAL REFLEX IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 22):
        //CBC WITHOUT DIFFERENTIAL REFLEX IDA DIAGNOSIS CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 32):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 53):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].currentTime = NINE_MINUTES + 43;
        break;
      case videoClause(CURRENT_INDEX, NINE_MINUTES + 57):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].currentTime = TEN_MINUTES + 2;
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 6):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].currentTime = TEN_MINUTES + 8;
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 11):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        setOverlayOpen(false);
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 26):
        //CBC WITH DIFFERENTIAL ida diagnosis
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 33):
        //CBC WITH DIFFERENTIAL ida diagnosis CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 39):
        //CBC WITH DIFFERENTIAL ida diagnosis CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 43):
        //CBC WITH DIFFERENTIAL ida diagnosis CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 48):
        //CBC WITH DIFFERENTIAL ida diagnosis CANCER
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 57):
        //IRON PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ELEVEN_MINUTES + 17):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].currentTime = ELEVEN_MINUTES + 7;
        break;
      case videoClause(CURRENT_INDEX, ELEVEN_MINUTES + 21):
        //CBC WITH DIFFERENTIAL REFLEX PANEL
        videoEl.current[activeVideoIndex].currentTime = ELEVEN_MINUTES + 27;
        break;
      case videoClause(CURRENT_INDEX, ELEVEN_MINUTES + 30):
        //IRON PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ELEVEN_MINUTES + 46):
        //IRON PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      //REVIEW TEST REPORTS
      case videoClause(CURRENT_INDEX, ONE_MINUTE + 29.0):
        //without
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TWO_MINUTES + 11):
        //without IRON
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES):
        //without ANEMIA
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, THREE_MINUTES + 47):
        //without VITAMIN
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 2):
        //with iron
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SIX_MINUTES + 52):
        //with ANEMIA
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, SEVEN_MINUTES + 35.5):
        //with VITAMIN
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, ELEVEN_MINUTES + 37):
        //IRON PANEL
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, FIVE_MINUTES + 11):
        //cbc with scroll section
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, EIGHT_MINUTES + 53):
        //cbc with scroll section
        videoEl.current[activeVideoIndex].pause();
        break;
      case videoClause(CURRENT_INDEX, TEN_MINUTES + 17):
        //cbc with scroll section
        videoEl.current[activeVideoIndex].pause();
        break;
      default:
    }
  };

  const progressHandlingFunctions = {
    0: handleIntroVideoProgress,
    1: handleBreastVideoProgress,
    2: handleLungVideoProgress,
    3: handleAnemiaVideoProgress
  };

  return (
    <>
      {videosPreloaded && (
        <>
          {videoUrls.map((url, index) => (
            <video
              id={`wirewax-player${index}`}
              preload={'auto'}
              key={index}
              src={url}
              ref={(el) => (videoEl.current[index] = el)}
              onTimeUpdate={() => {
                progressHandlingFunctions[activeVideoIndex]();
                setCurrentTime(videoEl.current[activeVideoIndex]?.currentTime);
              }}
              controls={false}
              poster={index === 0 ? posterImg : null}
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
          ))}
        </>
      )}
      <VideoHotSpots
        currentTime={currentTime}
        currentIndex={activeVideoIndex}
        videoEl={videoEl}
        videoIndexs={videoIndexs}
        setOverlayOpen={setOverlayOpen}
        overlayOpen={overlayOpen}
      />
    </>
  );
};

export default VideoPlayer;
