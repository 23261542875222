import React, { useState, useEffect } from 'react';

const reference14 =
  process.env.REACT_APP_BRANDING === 'blinded'
    ? 'HER2 Know: Breast cancer clinical cases. https://www.her2know.com/home/ihc-image-library.html#ihc-image-gallery. Accessed August 12, 2022.'
    : 'Daiichi-Sankyo and AstraZeneca. HER2 Know: Breast cancer clinical cases. https://www.her2know.com/home/ihc-image-library.html#ihc-image-gallery. Accessed August 12, 2022.';

const references = [
  'Aggarwal C, Thompson JC, Black TA, et al. Clinical implications of plasma-based genotyping with the delivery of personalized therapy in metastatic non-small cell lung cancer. <i>JAMA Oncol</i>. 2019;5(2):173-180.',
  'Allison KH, Hammond MEH, Dowsett M, et al. Estrogen and progesterone receptor testing in breast cancer: ASCO/CAP guideline update. <i>J Clin Oncol</i>. 2020;38(12):1346-1366.',
  'American Cancer Society. Breast cancer stages. https://www.cancer.org/cancer/breast-cancer/understanding-a-breast-cancer-diagnosis/stages-of-breast-cancer.html. Updated November 8, 2021. Accessed May 20, 2022.',
  'APS. Qualitative vs. Quantitative Immunohistochemistry. https://apsmedbill.com/whitepapers/qualitative-vs-quantitative-immunohistochemistry. Published March 21, 2021. Accessed May 20, 2022.',
  'Bond<sup><small>TM</small></sup> Oracle<sup><small>TM</small></sup> HER2 IHC System for Leica BOND-MAX System [instructions for use]. Newcastle, UK: Leica Biosystems Newcastle Ltd; 2020.',
  'Breast. In: Amin MB, Edge SB, Greene FL, et al, eds. <i>AJCC Cancer Staging Manual</i>. 8th ed. Chicago, IL: American College of Surgeons; 2018:589-636.',
  'Breast disease. In: Hoffman BL, Schorge JO, Halvorson LM, Hamid CA, Corton MM, Schaffer JI, eds. <i>Williams Gynecology</i>. 4th ed. McGraw Hill Education; 2020. https://accessmedicine.mhmedical.com/content.aspx?bookid=2658&#38;sectionid=218608871. Accessed May 20, 2022.',
  'Cancer Research UK. Bone scan. https://www.cancerresearchuk.org/about-cancer/cancer-in-general/tests/bone-scan. Accessed June 10, 2022.',
  'Caris<sup>&reg;</sup> Life Sciences. Molecular profiling technologies. https://www.carislifesciences.com/products-and-services/molecular-profiling/profiling-technologies. Accessed May 20, 2022.',
  'Caris<sup>&reg;</sup> Life Sciences. Testing Menu. https://www.carislifesciences.com/products-and-services/molecular-profiling/testing-menu. Accessed May 20, 2022.',
  'Caris<sup>&reg;</sup> Life Sciences. The Caris difference: reflex testing menu. https://www.carismolecularintelligence.com/wp-content/uploads/2019/11/Reflex-Testing-Menu_.pdf. Published 2020. Accessed May 20, 2022.',
  'CGS<sup>&reg;</sup> A Celerian Group Company. Next generation sequencing (NGS) and Tier 1 and Tier 2 Coding and Billing Guidelines. https://cgsmedicare.com/articles/cope31870.html#:~:text=Report. Published February 9, 2016. Updated April 7, 2016. Accessed May 20, 2022.',
  'CMS.gov. Clinical Laboratory Improvement Amendments (CLIA). https://www.cms.gov/Regulations-and-Guidance/Legislation/CLIA. Accessed May 20, 2022.',
  reference14,
  'Doshi S, Ray D, Stein K, et al. Economic analysis of alternative strategies for detection of ALK rearrangements in non small cell lung cancer. <i>Diagnostics (Basel)</i>. 2016;6(1):4.',
  'El-Deiry WS, Goldberg RM, Lenz HJ, et al. The current state of molecular testing in the treatment of patients with solid tumors, 2019. <i>CA Cancer J Clin</i>. 2019;69(4):305-343.',
  'Foundation Medicine. Compare our tests: our testing portfolio. https://www.foundationmedicine.com/portfolio. Accessed May 20, 2022.',
  'FoundationOne<sup>&reg;</sup> CDx [technical information]. Cambridge, MA: Foundation Medicine, Inc; 2022.',
  'FoundationOne<sup>&reg;</sup>Liquid CDx [technical information]. Cambridge, MA: Foundation Medicine, Inc; 2022.',
  'Guardant 360<sup>&reg;</sup> CDx [technical information]. Redwood City, CA: Guardant Health, Inc; 2021.',
  'HER2 IQFISH pharmDx. Singapore: Agilent Technologies Singapore (International) Pte Ltd; 2021.',
  'HercepTest<sup><small>TM</small></sup> for Automated Link Platforms. Singapore: Agilent Technologies Singapore (International) Pte Ltd; 2020.',
  'Hicks DG, Kulkarni S. HER2+ breast cancer: review of biologic relevance and optimal use of diagnostic tools. <i>Am J Clin Pathol</i>. 2008;129(2):263-273.',
  'Jafari SH, Saadatpour Z, Salmaninejad A, et al. Breast cancer diagnosis: imaging techniques and biochemical markers. <i>J Cell Physiol</i>. 2018;233(7):5200-5213.',
  'Jagsi R, King TA, Lehman C, Morrow M, Harris JR, Burstein HJ. Malignant tumors of the breast. In: DeVita VT Jr, Lawrence TS, Rosenberg SA, eds. <i>Cancer Principles & Practice of Oncology</i>. 11th ed. Wolters Kluwer; 2019:1269-1316.',
  'Kalli S, Semine A, Cohen S, Naber SP, Makim SS, Bahl M. American Joint Committee on Cancer&apos;s staging system for breast cancer, eighth edition: what the radiologist needs to know. <i>Radiographics</i>. 2018;38(7):1921-1933.',
  'Karam A. The breast. In: DeCherney AH, Nathan L, Laufer N, Roman AS, eds. <i>CURRENT Diagnosis & Treatment: Obstetrics & Gynecology</i>. 12th ed. McGraw Hill Education; 2019. https://accessmedicine.mhmedical.com/content.aspx?bookid=2559&#38;sectionid=206957689. Accessed June 10, 2022.',
  'Krishnamurti U, Silverman JF. HER2 in breast cancer: a review and update. <i>Adv Anat Pathol</i>. 2014;21(2):100-107.',
  'Labcorp. HER-2/CEP17, FISH. https://www.labcorp.com/tests/483248/her-2-cep17-fish. Accessed May 20, 2022.',
  'Labcorp. HER-2 immunohistochemistry (IHC), breast with reflex to FISH if 2+ by IHC. https://www.labcorp.com/tests/483289/her-2-immunohistochemistry-ihc-breast-with-reflex-to-fish-if-2-by-ihc. Accessed May 20, 2022.',
  'Leighl NB, Page RD, Raymond VM, et al. Clinical utility of comprehensive cell-free DNA analysis to identify genomic biomarkers in patients with newly diagnosed metastatic non-small cell lung cancer. <i>Clin Cancer Res</i>. 2019;25(15):4691-4700.',
  'Marino MA, Avendano D, Zapata P, Riedl CC, Pinker K. Lymph node imaging in patients with primary breast cancer: concurrent diagnostic tools. <i>Oncologist</i>. 2020;25(2):e231-e242.',
  'Modi S, Jacot W, Yamashita T, et al. <i>N Engl J Med</i>. 2022;387(1):9-20.',
  'Moelans CB, Kibbelaar RE, van den Heuvel MC, Castigliego D, de Weger RA, van Diest PJ. Validation of a fully automated HER2 staining kit in breast cancer. <i>Cell Oncol</i>. 2010;32(1-2):149-155.',
  'Neogenomics. Test Catalog. Fort Myers, FL: NeoGenomics Laboratories, Inc; 2022.',
  'NIH National Cancer Institute SEER Training Modules. General guidelines for TNM staging. https://training.seer.cancer.gov/staging/systems/ajcc/guidelines.html. Accessed May 20, 2022.',
  'Parker CC, Damodaran S, Bland KI, Hunt KK. The breast. In: Brunicardi F, Andersen DK, Billiar TR, et al, eds. <i>Schwartz&apos;s Principles of Surgery</i>. 11th ed. McGraw Hill Education; 2019. https://accessmedicine.mhmedical.com/content.aspx?bookid=2576&#38;sectionid=216206605 Accessed May 20, 2022.',
  'PathVysion HER-2 DNA Probe Kit [package insert]. Des Plaines, IL: Abbot Molecular Inc; 2016.',
  'Radiologyinfo.org. PET/CT. https://www.radiologyinfo.org/en/info/pet. Accessed June 10, 2022.',
  'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>) for Breast Cancer. V.4.2022. &copy; National Comprehensive Cancer Network, Inc. 2022. All rights reserved. Accessed June 22, 2022. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranies of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
  'SPOT-Light<sup>&reg;</sup> HER2 CISH Kit [package insert]. Paisley, UK: Invitrogen Ltd; 2008.',
  'Taber&apos;s Online. Taber&apos;s medical dictionary. https://www.tabers.com/tabersonline/index/Tabers-Dictionary/Entries. Accessed May 20, 2022.',
  'Tarantino P, Hamilton E, Tolaney SM, et al. HER2-low breast cancer: pathological and clinical landscape. <i>J Clin Oncol</i>. 2020;38(17):1951-1962.',
  'TEMPUS Sequencing Lab. https://www.tempus.com/wp-content/uploads/2021/11/LabOverview_092921.pdf. Accessed May 20, 2022.',
  'TEMPUS xF Gene Panel. https://www.tempus.com/wp-content/uploads/2020/09/xF-Gene-List-3.pdf. Accessed May 20, 2022.',
  'TEMPUS xT Gene Panel. https://www.tempus.com/wp-content/uploads/2021/12/xTv4GeneList_111821-3.pdf. Accessed May 20, 2022.',
  'US Food and Drug Administration. List of cleared or approved companion diagnostic devices (in vitro and imaging tools). https://www.fda.gov/medical-devices/in-vitro-diagnostics/list-cleared-or-approved-companion-diagnostic-devices-in-vitro-and-imaging-tools. Accessed May 24, 2022.',
  'VENTANA<sup>&reg;</sup> HER2 Dual ISH DNA Probe Cocktail [package insert]. Tucson, AZ: Ventana Medical Systems, Inc; 2020.',
  'VENTANA<sup>&reg;</sup> PATHWAY anti-HER2/neu (4B5) Rabbit Monoclonal Primary Antibody [package insert]. Tucson, AZ: Ventana Medical Systems, Inc; 2019.',
  'Wolff AC, Hammond MEH, Allison KH, et al. Human epidermal growth factor receptor 2 testing in breast cancer: American Society of Clinical Oncology/College of American Pathologists clinical practice guideline focused update. <i>Arch Pathol Lab Med</i>. 2018;142(11):1364-1382.',
  'Yang L, Chen M, Pu T, et al. The differences of clinicopathologic characteristics among subgroups of reclassified HER2 fluorescence in situ hybridization (FISH) according to the ASCO/CAP 2018 breast cancer HER2 testing guidelines. <i>J Clin Pathol</i>. 2020;73(5):283-290.'
];

const lungReferences = [
  'Aggarwal C, Thompson JC, Black TA, et al. Clinical implications of plasma-based genotyping with the delivery of personalized therapy in metastatic non-small cell lung cancer. <i>JAMA Oncol</i>. 2019;5(2):173-180.',
  'American Cancer Society. Early detection, diagnosis, and staging. https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging Accessed February 13, 2023.',
  'American Cancer Society. Signs and symptoms of lung cancer. https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging/signs-symptoms.html Accessed February 13, 2023.',
  'American Cancer Society. Thoracoscopy. https://www.cancer.org/treatment/understanding-your-diagnosis/tests/endoscopy/thoracoscopy.html Accessed February 13, 2023.',
  'American Cancer Society. Testing biopsy and cytology specimens for cancer. https://www.cancer.org/treatment/understanding-your-diagnosis/tests/testing-biopsy-and-cytology-specimens-for-cancer.html Accessed February 13, 2023.',
  'American Cancer Society. What is lung cancer? https://www.cancer.org/cancer/lung-cancer/about/what-is.html Accessed February 13, 2023.',
  'Amin MB, Edge S, Greene F, et al. (Eds.). <i>AJCC Cancer Staging Manual</i>. 8th ed. New York City, NY: Springer Cham, 2017.',
  'Arcila ME, Chaft JE, Nafa K, et al. Prevalence, clinicopathologic associations, and molecular spectrum of ERBB2 (HER2) tyrosine kinase mutations in lung adenocarcinomas. <i>Clin Cancer Res</i>. 2012;18(18):4910-4918.',
  'Bergethon K, Shaw AT, Ou SH, et al. ROS1 rearrangements define a unique molecular class of lung cancers. <i>J Clin Oncol</i>. 2012;30(8):863-870.',
  'Boire A, Brandsma D, Brastianos PK, et al.  Liquid biopsy in central nervous system metastases: a RANO review and proposals for clinical applications. <i>Neuro Oncol</i>. 2019;21(5):571-584.',
  'Britannica. Lung cancer. https://www.britannica.com/science/lung-cancer Accessed February 13, 2023.',
  'Cancer.Net. Lung cancer - non-small cell: diagnosis. https://www.cancer.net/cancer-types/lung-cancer-non-small-cell/diagnosis Accessed February 13, 2023. ',
  'Caris<sup>&reg;</sup> Life Sciences. Molecular profiling technologies. https://www.carislifesciences.com/products-and-services/molecular-profiling/profiling-technologies Accessed February 13, 2023. ',
  'Caris<sup>&reg;</sup> Life Sciences. Testing menu. https://www.carislifesciences.com/products-and-services/molecular-profiling/testing-menu Accessed February 13, 2023. ',
  'CGS<sup>&reg;</sup> A Celerian Group Company. Next generation sequencing (NGS) and tier 1 and tier 2 coding and billing guidelines. https://cgsmedicare.com/articles/cope31870.html#:~:text=Report Published February 9, 2016. Updated April 7, 2016. Accessed May 20, 2022. ',
  'Daly ME, Singh N, Ismaila N, et al. Management of stage III non-small-cell lung cancer: ASCO guideline. <i>J Clin Oncol</i>. 2022;40(12):1356-1384.',
  'De Wever W, Coolen J, Verschakelen JA. Imaging techniques in lung cancer. <i>Breathe</i>. 2011;7(4):338-346.',
  'Dietel M, Savelov N, Salanova R, et al. Real-world prevalence of programmed death ligand 1 expression in locally advanced or metastatic non-small-cell lung cancer: the global, multicenter EXPRESS study. <i>Lung Cancer</i>. 2019;134:174-179. ',
  'El-Deiry WS, Goldberg RM, Lenz HJ, et al. The current state of molecular testing in the treatment of patients with solid tumors, 2019. <i>CA Cancer J Clin</i>. 2019;69(4):305-343.',
  'Foundation Medicine. Our testing portfolio. https://www.foundationmedicine.com/portfolio Accessed May 20, 2022. ',
  'FoundationOne<sup>&reg;</sup> CDx [technical information]. Cambridge, MA: Foundation Medicine, Inc; 2022.',
  'FoundationOne<sup>&reg;</sup>Liquid CDx [technical information]. Cambridge, MA: Foundation Medicine, Inc; 2022.',
  'Garrido-Castro AC, Felip E. HER2 driven non-small cell lung cancer (NSCLC): potential therapeutic approaches. <i>Transl Lung Cancer Res</i>. 2013;2(2):122-127. ',
  'Guardant 360<sup>&reg;</sup> CDx [technical information]. Redwood City, CA: Guardant Health, Inc; 2021.',
  'Guo Y, Cao R, Zhang X, et al. Recent progress in rare oncogenic drivers and targeted therapy for non-small cell lung cancer. <i>Onco Targets Ther</i>. 2019;12:10343-10360.',
  'Heineman DJ, Daniels JM, Schreurs WH. Clinical staging of NSCLC: current evidence and implications for adjuvant chemotherapy. <i>Ther Adv Med Oncol</i>. 2017;9(9):599-609. ',
  'Inamura K, Yokouchi Y, Kobayashi M, et al. Association of tumor TROP2 expression with prognosis varies among lung cancer subtypes. <i>Oncotarget</i>. 2017;8(17):28725-28735. ',
  'J&auml;nne PA, Baik C, Su WC, et al. Efficacy and safety of patritumab deruxtecan (HER3-DXd) in EGFR inhibitor-resistant, EGFR-mutated non-small cell lung cancer. <i>Cancer Discov</i>. 2022;12(1):74-89. ',
  'Kitadai R, Okuma Y. Treatment strategies for non-small cell lung cancer harboring common and uncommon EGFR mutations: drug sensitivity based on exon classification, and structure-function analysis. <i>Cancers (Basel)</i>. 2022;14(10):2519. ',
  'Koyama K, Ishikawa H, Abe M, et al. Patritumab deruxtecan (HER3-DXd), a novel HER3 directed antibody drug conjugate, exhibits in vitro activity against breast cancer cells expressing HER3 mutations with and without HER2 overexpression. <i>PLoS One</i>. 2022;17(5):e0267027. ',
  'Leighl NB, Page RD, Raymond VM, et al. Clinical utility of comprehensive cell-free DNA analysis to identify genomic biomarkers in patients with newly diagnosed metastatic non-small cell lung cancer. <i>Clin Cancer Res</i>. 2019;25(15):4691-4700. ',
  'Len&aacute;rt S, Len&aacute;rt P, &#x0160;marda J, Rem&#x0161;&#xed;k J, Sou&#x010D;ek K, Bene&#x0161; P. Trop2: jack of all trades, master of none. <i>Cancers (Basel)</i>. 2020;12(11):3328. ',
  'Lindeman NI, Cagle PT, Beasley MB, et al. Molecular testing guideline for selection of lung cancer patients for EGFR and ALK tyrosine kinase inhibitors: guideline from the College of American Pathologists, International Association for the Study of Lung Cancer, and Association for Molecular Pathology. <i>J Mol Diagn</i>. 2013;15(4):415-453.',
  'Marchetti A, Ferro B, Pasciuto MP, Zampacorta C, Buttitta F, D&#x2019;Angelo E. NTRK gene fusions in solid tumors: agnostic relevance, prevalence and diagnostic strategies. <i>Pathologica</i>. 2022;114(3):199-216.',
  'Masucci GV, Cesano A, Hawtin R, et al. Validation of biomarkers to predict response to immunotherapy in cancer: volume I - pre-analytical and analytical validation. <i>J Immunother Cancer</i>. 2016;4:76. ',
  'Mazi&egrave;res J, Peters S, Lepage B, et al. Lung cancer that harbors an HER2 mutation: epidemiologic characteristics and therapeutic perspectives. <i>J Clin Oncol</i>. 2013;31(16):1997-2003.',
  'Merriam-Webster. Overexpression. https://www.merriam-webster.com/medical/overexpression Accessed February 13, 2023. ',
  'Mezei T, Contac A, Iacob A, Egyed-Zsigmond I. Making mock-FNA smears from fresh surgical pathology specimens to improve smear preparation technique and to create cytohistological correlation series. <i>PLoS One</i>. 2014;9(8):e104983. ',
  'Milovanovic IS, Stjepanovic M, Mitrovic D. Distribution patterns of the metastases of the lung carcinoma in relation to histological type of the primary tumor: an autopsy study. <i>Ann Thorac Med</i>. 2017;12(3):191-198.',
  'Mishra R, Patel H, Alanazi S, Yuan L, Garrett JT. HER3 signaling and targeted therapy in cancer. <i>Oncol Rev</i>. 2018;12(1):355. ',
  'NIH National Cancer Institute Surveillance, Epidemiology, and End Results Program. Cancer stat facts: lung and bronchus cancer. https://seer.cancer.gov/statfacts/html/lungb.html Accessed February 13, 2023. ',
  'NIH National Cancer Institute. Non-small cell cancer treatment (PDQ<sup>&reg;</sup>)-patient version. https://www.cancer.gov/types/lung/patient/non-small-cell-lung-treatment-pdq Accessed February 13, 2023.  ',
  'NIH National Human Genome Research Institute. Gene amplification. https://www.genome.gov/genetics-glossary/gene-amplification Updated February 16, 2023. Accessed February 23, 2023.',
  'NIH National Human Genome Research Institute. Mutation. https://www.genome.gov/genetics-glossary/mutation Updated February 16, 2023. Accessed February 23, 2023.',
  'Pillai RN, Behera M, Berry LD, et al. HER2 mutations in lung adenocarcinomas: a report from the Lung Cancer Mutation Consortium. <i>Cancer</i>. 2017;123(21):4099-4105. ',
  'Quint LE. Multidisciplinary approach to thoracic tissue sampling. <i>Cancer Imaging</i>. 2010;10 Spec no A(1A):S151-5. ',
  'Radiology Assistant. TNM classification 8th edition. https://radiologyassistant.nl/chest/lung-cancer/tnm-classification-8th-edition Accessed February 13, 2023. ',
  'Rami-Porta R, Call S, Dooms C, et al. Lung cancer staging: a concise update. <i>Eur Respir J</i>. 2018;51(5):1800190.',
  'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>) for Non-Small Cell Lung Cancer. V.3.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed April 20, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
  'Reungwetwattana T, Ou SH. MET exon 14 deletion (METex14): finally, a frequent-enough actionable oncogenic driver mutation in non-small cell lung cancer to lead MET inhibitors out of “40 years of wilderness” and into a clear path of regulatory approval. <i>Transl Lung Cancer Res</i>. 2015;4(6):820-824.',
  'Scharpenseel H, Hanssen A, Loges S, et al. EGFR and HER3 expression in circulating tumor cells and tumor tissue from non-small cell lung cancer patients. <i>Sci Rep</i>. 2019;9(1):7406. ',
  'Sharma SV, Bell DW, Settleman J, Haber DA. Epidermal growth factor receptor mutations in lung cancer. <i>Nat Rev Cancer</i>. 2007;7(3):169-181. ',
  'Sholl LM, Aisner DL, Varella-Garcia M, et al. Multi-institutional oncogenic driver mutation analysis in lung adenocarcinoma: the Lung Cancer Mutation Consortium experience. <i>J Thorac Oncol</i>. 2015;10(5):768-777. ',
  'TEMPUS Sequencing Lab. https://www.tempus.com/wp-content/uploads/2021/11/LabOverview_092921.pdf Accessed May 20, 2022.',
  'TEMPUS xF Gene Panel. https://www.tempus.com/wp-content/uploads/2020/09/xF-Gene-List-3.pdf Accessed May 20, 2022. ',
  'TEMPUS xT Gene Panel. https://www.tempus.com/wp-content/uploads/2021/12/xTv4GeneList_111821-3.pdf Accessed May 20, 2022. ',
  'ThermoFisher Scientific. Oncomine Dx Target Test. https://assets.thermofisher.com/TFS-Assets/LSG/brochures/oncomine-dx-target-test-brochure.pdf Accessed February 13, 2023.',
  'UC San Diego Health. Pulmonary & critical care medicine. https://health.ucsd.edu/specialties/pulmonary/procedures/pages/endobronchial.aspx Accessed February 16, 2023.',
  'US Food and Drug Administration. List of cleared or approved companion diagnostic devices (in vitro and imaging tools). https://www.fda.gov/medical-devices/in-vitro-diagnostics/ list-cleared-or-approved-companion-diagnostic-devices-in-vitro-and-imaging-tools Accessed January 10, 2023.',
  'Wang ZF, Ren SX, Li W, Gao GH. Frequency of the acquired resistant mutation T790 M in non-small cell lung cancer patients with active exon 19Del and exon 21 L858R: a systematic review and meta-analysis. <i>BMC Cancer</i>. 2018;18(1):148. ',
  'Wistuba II. Molecular pathogenesis of non-small cell lung carcinomas. <i>J Lung Cancer</i>. 2012;11(1):12-20.',
  'Yonesaka K, Tanizaki J, Maenishi O, et al. HER3 augmentation via blockade of EGFR/AKT signaling enhances anticancer activity of HER3-targeting patritumab deruxtecan in EGFR-mutated non-small cell lung cancer. <i>Clin Cancer Res</i>. 2022;28(2):390-403. ',
  'Zaman S, Jadid H, Denson AC, Gray JE. Targeting Trop-2 in solid tumors: future prospects. <i>Onco Targets Ther</i>. 2019;12:1781-1790.'
];

const newLungRef = [
  'Aggarwal C, Thompson JC, Black TA, et al. Clinical implications of plasma-based genotyping with the delivery of personalized therapy in metastatic non-small cell lung cancer. <i>JAMA Oncol</i>. 2019;5(2):173-180.',
  'American Cancer Society. Early detection, diagnosis, and staging. https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging Accessed February 13, 2023.',
  'American Cancer Society. Signs and symptoms of lung cancer. https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging/signs-symptoms.html Accessed February 13, 2023.',
  'American Cancer Society. Thoracoscopy. https://www.cancer.org/treatment/understanding-your-diagnosis/tests/endoscopy/thoracoscopy.html Accessed February 13, 2023.',
  'American Cancer Society. Testing biopsy and cytology specimens for cancer. https://www.cancer.org/treatment/understanding-your-diagnosis/tests/testing-biopsy-and-cytology-specimens-for-cancer.html Accessed February 13, 2023.',
  'American Cancer Society. What is lung cancer? https://www.cancer.org/cancer/lung-cancer/about/what-is.html Accessed February 13, 2023.',
  'Amin MB, Edge S, Greene F, et al. (Eds.). <i>AJCC Cancer Staging Manual</i>. 8th ed. New York City, NY: Springer Cham, 2017.',
  'Arcila ME, Chaft JE, Nafa K, et al. Prevalence, clinicopathologic associations, and molecular spectrum of ERBB2 (HER2) tyrosine kinase mutations in lung adenocarcinomas. <i>Clin Cancer Res</i>. 2012;18(18):4910-4918. ',
  'Atlantic Medical Imaging. PET/CT scan FAQ. https://www.atlanticmedicalimaging.com/radiology-services/pet-ct/pet-ct-scan-faqs/ Accessed August 11, 2023.',
  'Bergethon K, Shaw AT, Ou SH, et al. ROS1 rearrangements define a unique molecular class of lung cancers. <i>J Clin Oncol</i>. 2012;30(8):863-870. ',
  "Boire A, Brandsma D, Brastianos PK, et al.<span style='mso-spacerun:yes'> Liquid biopsy in central nervous system metastases: a RANO review and proposals for clinical applications. <i>Neuro Oncol</i>. 2019;21(5):571-584.",
  'Britannica. Lung cancer. https://www.britannica.com/science/lung-cancer Accessed February 13, 2023.',
  'Cancer.Net. Lung cancer – non-small cell: diagnosis. https://www.cancer.net/cancer-types/lung-cancer-non-small-cell/diagnosis Accessed February 13, 2023. ',
  'Cancer.Net. Positron emission tomography and computed tomography (PET-CT) scans. https://www.cancer.net/navigating-cancer-care/diagnosing-cancer/tests-and-procedures/positron-emission-tomography-and-computed-tomography-pet-ct-scans Accessed August 11, 2023.',
  'Caris<sup>&reg;</sup> Life Sciences. Molecular profiling technologies. https://www.carislifesciences.com/products-and-services/molecular-profiling/profiling-technologies Accessed February 13, 2023. ',
  'Caris<sup>&reg;</sup> Life Sciences. Testing menu. https://www.carislifesciences.com/products-and-services/molecular-profiling/testing-menu Accessed February 13, 2023. ',
  'CGS<sup>&reg;</sup> A Celerian Group Company. Next generation sequencing (NGS) and tier 1 and tier 2 coding and billing guidelines. https://cgsmedicare.com/articles/cope31870.html#:~:text=Report Published February 9, 2016. Updated April 7, 2016. Accessed May 20, 2022. ',
  'City of Hope. PET/CT scan for cancer. https://www.cancercenter.com/diagnosing-cancer/diagnostic-imaging/pet-ct-scan Accessed August 11, 2023.',
  'Cleveland Clinic. Chest x-ray. https://my.clevelandclinic.org/health/diagnostics/10228-chest-x-ray Accessed August 11, 2023.',
  'Cleveland Clinic. CT (computed tomography) scan. https://my.clevelandclinic.org/health/diagnostics/4808-ct-computed-tomography-scan Accessed August 11, 2023.',
  'Daly ME, Singh N, Ismaila N, et al. Management of stage III non-small-cell lung cancer: ASCO guideline. <i>J Clin Oncol</i>. 2022;40(12):1356-1384.',
  'De Wever W, Coolen J, Verschakelen JA. Imaging techniques in lung cancer. <i>Breathe</i>. 2011;7(4):338-346.',
  'Dietel M, Savelov N, Salanova R, et al. Real-world prevalence of programmed death ligand 1 expression in locally advanced or metastatic non-small-cell lung cancer: the global, multicenter EXPRESS study. <i>Lung Cancer</i>. 2019;134:174-179. ',
  'Docpanel. How long does it take to get MRI results. https://www.docpanel.com/blog/post/how-long-does-it-take-get-mri-results Accessed August 11, 2023.',
  'El-Deiry WS, Goldberg RM, Lenz HJ, et al. The current state of molecular testing in the treatment of patients with solid tumors, 2019. <i>CA Cancer J Clin</i>. 2019;69(4):305-343.',
  'Foundation Medicine. Our testing portfolio. https://www.foundationmedicine.com/portfolio Accessed May 20, 2022. ',
  'FoundationOne<sup>&reg;</sup> CDx [technical information]. Cambridge, MA: Foundation Medicine, Inc; 2022.',
  'FoundationOne<sup>&reg;</sup>Liquid CDx [technical information]. Cambridge, MA: Foundation <b>Medicine, Inc; 2022.<o:p></o:p></b>',
  'Garrido-Castro AC, Felip E. HER2 driven non-small cell lung cancer (NSCLC): potential therapeutic approaches. <i>Transl</i><i> Lung Cancer Res</i>. 2013;2(2):122-127. ',
  'Guardant 360<sup>&reg;</sup> CDx [technical information]. Redwood City, CA: Guardant Health, Inc; 2021.',
  'Guo Y, Cao R, Zhang X, et al. Recent progress in rare oncogenic drivers and targeted therapy for non-small cell lung cancer<i>. Onco Targets Ther</i>. 2019;12:10343-10360.',
  'Heineman DJ, Daniels JM, Schreurs WH. Clinical staging of NSCLC: current evidence and implications for adjuvant chemotherapy. <i>Ther</i><i> Adv Med Oncol</i>. 2017;9(9):599-609. ',
  'Inamura K, Yokouchi Y, Kobayashi M, et al. Association of tumor TROP2 expression with prognosis varies among lung cancer subtypes. <i>Oncotarget</i>. 2017;8(17):28725-28735. ',
  'Jänne PA, Baik C, Su WC, et al. Efficacy and safety of patritumab deruxtecan (HER3-DXd) in EGFR inhibitor-resistant, EGFR-mutated non-small cell lung cancer. <i>Cancer Discov</i>. 2022;12(1):74-89. ',
  'Kaiser Permanente. Bone scan: results. https://healthy.kaiserpermanente.org/health-wellness/health-encyclopedia/he.bone-scan.hw200283#hw200292 Accessed August 11, 2023.',
  'Kitadai R, Okuma Y. Treatment strategies for non-small cell lung cancer harboring common and uncommon EGFR mutations: drug sensitivity based on exon classification, and structure-function analysis. <i>Cancers (Basel).</i> 2022;14(10):2519. ',
  'Koyama K, Ishikawa H, Abe M, et al. Patritumab deruxtecan (HER3-DXd), a novel HER3 directed antibody drug conjugate, exhibits in vitro activity against breast cancer cells expressing HER3 mutations with and without HER2 overexpression. <i>PLoS</i><i> One.</i> 2022;17(5):e0267027. ',
  'Leighl NB, Page RD, Raymond VM, et al. Clinical utility of comprehensive cell-free DNA analysis to identify genomic biomarkers in patients with newly diagnosed metastatic non-small cell lung cancer. <i>Clin Cancer Res</i>. 2019;25(15):4691-4700.',
  'Lenárt S, Lenárt P, Šmarda J, Remšík J, Souček K, Beneš P. Trop2: jack of all trades, master of none. <i>Cancers (Basel).</i> 2020;12(11):3328. ',
  'Lindeman NI, Cagle PT, Beasley MB, et al. Molecular testing guideline for selection of lung cancer patients for EGFR and ALK tyrosine kinase inhibitors: guideline from the College of American Pathologists, International Association for the Study of Lung Cancer, and Association for Molecular Pathology. <i>J Mol Diagn</i>. 2013;15(4):415-453.',
  'Marchetti A, Ferro B, Pasciuto MP, Zampacorta C, Buttitta F, D’Angelo E. NTRK gene fusions in solid tumors: agnostic relevance, prevalence and diagnostic strategies. <i>Pathologica</i>. 2022;114(3):199-216.',
  'Masucci GV, Cesano A, Hawtin R, et al. Validation of biomarkers to predict response to immunotherapy in cancer: volume I - pre-analytical and analytical validation<i>. J Immunother Cancer</i>. 2016;4:76. ',
  'Mazières J, Peters S, Lepage B, et al. Lung cancer that harbors an HER2 mutation: epidemiologic characteristics and therapeutic perspectives. <i>J Clin Oncol</i>. 2013;31(16):1997-2003.',
  'Mayo Clinic. CT scan. https://www.mayoclinic.org/tests-procedures/ct-scan/about/pac-20393675 Accessed August 11, 2023. ',
  'Mayo Clinic. MRI. https://www.mayoclinic.org/tests-procedures/mri/about/pac-20384768 Accessed August 11, 2023.',
  'MedlinePlus. Bone scan. https://medlineplus.gov/ency/article/003833.htm Accessed August 11, 2023.',
  'Merriam-Webster. Overexpression. https://www.merriam-webster.com/medical/overexpression Accessed February 13, 2023. ',
  'Mezei T, Contac A, Iacob A, Egyed-Zsigmond I. Making mock-FNA smears from fresh surgical pathology specimens to improve smear preparation technique and to create cytohistological correlation series. <i>PLoS</i><i> One</i>. 2014;9(8):e104983. ',
  'Milovanovic IS, Stjepanovic M, Mitrovic D. Distribution patterns of the metastases of the lung carcinoma in relation to histological type of the primary tumor: an autopsy study. <i>Ann Thorac Med</i>. 2017;12(3):191-198.',
  'Mishra R, Patel H, Alanazi S, Yuan L, Garrett JT. HER3 signaling and targeted therapy in cancer. <i>Oncol Rev</i>. 2018;12(1):355. ',
  'NIH National Cancer Institute Surveillance, Epidemiology, and End Results Program. Cancer stat facts: lung and bronchus cancer. https://seer.cancer.gov/statfacts/html/lungb.html Accessed February 13, 2023. ',
  "NIH National Cancer Institute. Non-small cell cancer treatment (PDQ<sup>&reg;</sup>)–patient version. https://www.cancer.gov/types/lung/patient/non-small-cell-lung-treatment-pdq Accessed February 13, 2023.<span style='mso-spacerun:yes'>  ",
  'NIH National Human Genome Research Institute. Gene amplification. https://www.genome.gov/genetics-glossary/gene-amplification Updated February 16, 2023. Accessed February 23, 2023.',
  'NIH National Human Genome Research Institute. Mutation. https://www.genome.gov/genetics-glossary/mutation Updated February 16, 2023. Accessed February 23, 2023.',
  'Pillai RN, Behera M, Berry LD, et al. HER2 mutations in lung adenocarcinomas: a report from the Lung Cancer Mutation Consortium. <i>Cancer.</i> 2017;123(21):4099-4105. ',
  'Quint, LE. Multidisciplinary approach to thoracic tissue sampling. <i>Cancer Imaging</i>. 2010;10 Spec no A(1A):S151-5. ',
  'Radiology Assistant. TNM classification 8th edition. https://radiologyassistant.nl/chest/lung-cancer/tnm-classification-8th-edition Accessed February 13, 2023. ',
  'Rami-Porta R, Call S, Dooms C, et al. Lung cancer staging: a concise update. <i>Eur</i><i> Respir J</i>. 2018;51(5):1800190. ',
  'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>) for Non-Small Cell Lung Cancer. V.3.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed April 20, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
  'Reungwetwattana T, Ou SH. MET exon 14 deletion (METex14): finally, a frequent-enough actionable oncogenic driver mutation in non-small cell lung cancer to lead MET inhibitors out of “40 years of wilderness” and into a clear path of regulatory approval. <i>Transl</i><i> Lung Cancer Res</i>. 2015;4(6):820-824.',
  'Scharpenseel H, Hanssen A, Loges S, et al. EGFR and HER3 expression in circulating tumor cells and tumor tissue from non-small cell lung cancer patients. <i>Sci Rep</i>. 2019;9(1):7406. ',
  'Sharma SV, Bell DW, Settleman J, Haber DA. Epidermal growth factor receptor mutations in lung cancer. <i>Nat Rev Cancer</i>. 2007;7(3):169-181. ',
  'Sholl LM, Aisner DL, Varella-Garcia M, et al. Multi-institutional oncogenic driver mutation analysis in lung adenocarcinoma: the Lung Cancer Mutation Consortium experience<i>. J Thorac Oncol</i>. 2015;10(5):768-777. ',
  'Shvartsur A, Bonavida B. Trop2 and its overexpression in cancers: regulation and clinical/therapeutic implications. <i>Genes Cancer</i>. 2015;6(3-4):84-105. ',
  'TEMPUS Sequencing Lab. https://www.tempus.com/wp-content/uploads/2021/11/LabOverview_092921.pdf Accessed May 20, 2022.',
  'TEMPUS xF Gene Panel. https://www.tempus.com/wp-content/uploads/2020/09/xF-Gene-List-3.pdf Accessed May 20, 2022. ',
  'TEMPUS xT Gene Panel. https://www.tempus.com/wp-content/uploads/2021/12/xTv4GeneList_111821-3.pdf Accessed May 20, 2022. ',
  'ThermoFisher Scientific. Oncomine Dx Target Test. https://assets.thermofisher.com/TFS-Assets/LSG/brochures/oncomine-dx-target-test-brochure.pdf Accessed February 13, 2023.',
  'UC San, Diego Health. Pulmonary &amp; critical care medicine. https://health.ucsd.edu/specialties/pulmonary/procedures/pages/endobronchial.aspx Accessed February 16, 2023.',
  'US Food and Drug Administration. List of cleared or approved companion diagnostic devices (in vitro and imaging tools). https://www.fda.gov/medical-devices/in-vitro-diagnostics/list-cleared-or-approved-companion-diagnostic-devices-in-vitro-and-imaging-tools Accessed January 10, 2023.',
  'Wang ZF, Ren SX, Li W, Gao GH. Frequency of the acquired resistant mutation T790 M in non-small cell lung cancer patients with active exon 19Del and exon 21 L858R: a systematic review and meta-analysis. <i>BMC Cancer</i>. 2018;18(1):148. ',
  'Wistuba II. Molecular pathogenesis of non-small cell lung carcinomas.<i> J Lung Cancer</i>. 2012;11(1):12-20.',
  'Yonesaka K, Tanizaki J, Maenishi O, et al. HER3 augmentation via blockade of EGFR/AKT signaling enhances anticancer activity of HER3-targeting patritumab deruxtecan in EGFR-mutated non-small cell lung cancer. <i>Clin Cancer Res</i>. 2022;28(2):390-403. ',
  'Zaman S, Jadid H, Denson AC, Gray JE. Targeting Trop-2 in solid tumors: future prospects. <i>Onco</i><i> Targets Ther.</i> 2019;12:1781-1790.'
];

const anemiaRef = [
  'Anand IS, Chandrashekhar Y, Ferrari R, Poole-Wilson PA, Harris PC. Pathogenesis of oedema in chronic severe anaemia: studies of body water and sodium, renal function, haemodynamic variables, and plasma hormones<i>. Br Heart J. </i>1993;70(4):357-362.',
  'American Society of Hematology. Anemia. https://www.hematology.org/education/patients/anemia Accessed June 16, 2023.',
  'Auerbarch M. Causes and diagnosis of iron deficiency and iron deficiency anemia in adults. https://www.uptodate.com/contents/causes-and-diagnosis-of-iron-deficiency-and-iron-deficiency-anemia-in-adults. Accessed June 16, 2023.',
  'Camaschella C. Iron-deficiency anemia. <i>N Engl J Med.</i> 2015;372(19):1832-1843.',
  'City of Hope. Colorectal cancer symptoms. https://www.cancercenter.com/cancer-types/colorectal-cancer/symptoms Accessed June 16, 2023.',
  'Cleveland Clinic. Complete blood count. https://my.clevelandclinic.org/health/body/22879-platelets Accessed June 16, 2023.',
  'Cleveland Clinic. Function of white blood cells. https://my.clevelandclinic.org/health/body/21871-white-blood-cells Accessed June 16, 2023.',
  'Cleveland Clinic. Platelets. https://my.clevelandclinic.org/health/body/22879-platelets Accessed June 16, 2023.',
  'Heidenreich PA, Bozkurt B, Aguilar D, et al. 2022 AHA/ACC/HFSA guideline for the management of heart failure: a report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. <i>Circulation</i>. 2022;145(18):e895-e1032.',
  'KDIGO. KDIGO clinical practice guideline for anemia in chronic kidney disease. <i>Kidney Int Suppl.</i> 2012;2(4):279-335.',
  'Killip S, Bennett JM, Chambers MD. Iron deficiency anemia. Am Fam Physician. 2007;75(5):671-678.',
  'Ko CW, Siddique SM, Patel A, et al. AGA clinical practice guidelines on the gastrointestinal evaluation of iron deficiency anemia. <i>Gastroenterology</i>. 2020;159(3):1085-1094.',
  'Labcorp. Anemia profile B. https://www.labcorp.com/tests/042077/anemia-profile-b Accessed June 22, 2023. ',
  'Labcorp. Complete blood count (CBC) with differential. https://www.labcorp.com/tests/005009/complete-blood-count-cbc-with-differential Accessed June 22, 2023. ',
  'Labcorp. Complete blood count (CBC) without differential. https://www.labcorp.com/tests/028142/complete-blood-count-cbc-without-differential Accessed June 22, 2023. ',
  'Labcorp. Ferritin. https://www.labcorp.com/tests/004598/ferritin Accessed June 22, 2023. ',
  'Labcorp. Sample report, 000810. https://files.labcorp.com/testmenu-d8/sample_reports/000810.pdf Accessed June 22, 2023. ',
  'Labcorp. Sample report, 001321. https://files.labcorp.com/testmenu-d8/sample_reports/001321.pdf Accessed June 22, 2023. ',
  'Labcorp. Sample report, 005009. https://files.labcorp.com/testmenu-d8/sample_reports/005009.pdf Accessed June 22, 2023. ',
  'Labcorp. Vitamin B12 and folates. https://www.labcorp.com/tests/000810/vitamin-b-sub-12-sub-and-folates Accessed June 22, 2023. ',
  'Lumen Learning. Anatomy &amp; physiology II: erythrocytes. https://courses.lumenlearning.com/suny-ap2/chapter/erythrocytes Accessed June 16, 2023.',
  'Masini G, Graham FJ, Pellicori P, et al. Criteria for iron deficiency in patients with heart failure. <i>J Am Coll Cardiol</i>. 2022;79(4):341-351.',
  'Mayo Clinic. Complete blood count. https://www.mayoclinic.org/tests-procedures/complete-blood-count/about/pac-20384919 Accessed June 16, 2023.',
  'Moreno Chulilla JA, Romero Colás MS, Gutiérrez Martín M. Classification of anemia for gastroenterologists. <i>World J Gastroenterol</i>. 2009;15(37):4627-4637.',
  'NIH National Heart, Lung, and Blood Institute. Iron-deficiency anemia. https://www.nhlbi.nih.gov/health/anemia/iron-deficiency-anemia Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Blood differential. https://medlineplus.gov/lab-tests/blood-differential/ Accessed June 16, 2023. ',
  'NIH National Library of Medicine. Medline Plus: Folate deficiency. https://medlineplus.gov/ency/article/00034.htm Accessed June 16, 2023. ',
  'NIH National Library of Medicine. Medline Plus: Folate-deficiency anemia. https://medlineplus.gov/ency/article/000551.htm Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: RBC count. http://medlineplus.gov/ency/article/003644.htm Accessed June 20, 2023.',
  'NIH National Library of Medicine. Medline Plus: RBC indices. http://medlineplus.gov/ency/article/003648.htm Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Reticulocyte count. https://medlineplus.gov/lab-tests/reticulocyte-count/ Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Vitamin B12 deficiency anemia. https://medlineplus.gov/ency/article/000574.htm Accessed June 16, 2023.',
  'Quest Diagnostics<sup>&#8482;</sup>. Iron, TIBC and ferritin panel. https://testdirectory.questdiagnostics.com/test/test-detail/5616/iron-tibc-and-ferritin-panel?q=anemia%20panel&amp;cc=MASTER Accessed June 23, 2023. ',
  'Quest Diagnostics<sup>&#8482;</sup>. Reticulocyte count, automated. https://testdirectory.questdiagnostics.com/test/test-detail/793/reticulocyte-count-automated?cc=MASTER Accessed June 23, 2023. ',
  'Quest Diagnostics<sup>&#8482;</sup>. Vitamin B12 (cobalamin) and folate panel, serum. https://testdirectory.questdiagnostics.com/test/test-detail/7065/?cc=DLO Accessed June 23, 2023. ',
  'Short MW, Domagalski JE. Iron deficiency anemia: evaluation and management. <i>Am Fam Physician</i>. 2013;87(2):98-104.',
  'Society for the Advancement of Patient Blood Management (SABM<sup>&reg;</sup>). Iron corner: management of iron deficiency anemia in cancer and chemotherapy. https://sabm.org/wp-content/uploads/2ci-IDA-Cancer-Chemotherapy.pdf Accessed June 23, 2023.',
  'Testing.com. The complete blood count sample report. https://www.testing.com/wp-content/uploads/2021/07/CBC-sample-report-with-notes_0.pdf Accessed June 20, 2023. ',
  'Tortora GJ, Derrickson BH. The cardiovascular system: the blood. In: <i>Principles of Anatomy &amp; Physiology</i>. 16th ed. John Wiley &amp; Sons, Inc; 2021:696-726.',
  'World Health Organization. Haemoglobin concentrations for the diagnosis of anaemia and assessment severity. https://apps.who.int/iris/bitstream/handle/10665/85839/WHO_NMH_NHD_MNM_11.1_eng.pdf Accessed June 16, 2023.'
];

const newAnemiaRef = [
  'Anand IS, Chandrashekhar Y, Ferrari R, Poole-Wilson PA, Harris PC. Pathogenesis of oedema in chronic severe anaemia: studies of body water and sodium, renal function, haemodynamic variables, and plasma hormones. <i>Br Heart J</i>. 1993;70(4):357-362.',
  'American Society of Hematology. Anemia. https://www.hematology.org/education/patients/anemia Accessed June 16, 2023.',
  'Auerbarch M. Causes and diagnosis of iron deficiency and iron deficiency anemia in adults. https://www.uptodate.com/contents/causes-and-diagnosis-of-iron-deficiency-and-iron-deficiency-anemia-in-adults. Accessed June 16, 2023.',
  'Camaschella C. Iron-deficiency anemia. <i>N Engl J Med.</i> 2015;372(19):1832-1843.',
  'City of Hope. Colorectal cancer symptoms. https://www.cancercenter.com/cancer-types/colorectal-cancer/symptoms Accessed June 16, 2023.',
  'Cleveland Clinic. Complete blood count. https://my.clevelandclinic.org/health/body/22879-platelets Accessed June 16, 2023.',
  'Cleveland Clinic. Function of white blood cells. https://my.clevelandclinic.org/health/body/21871-white-blood-cells Accessed June 16, 2023.',
  'Cleveland Clinic. Platelets. https://my.clevelandclinic.org/health/body/22879-platelets Accessed June 16, 2023.',
  'Heidenreich PA, Bozkurt B, Aguilar D, et al. 2022 AHA/ACC/HFSA guideline for the management of heart failure: a report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. <i>Circulation</i>. 2022;145(18):e895-e1032.<',
  'KDIGO. KDIGO clinical practice guideline for anemia in chronic kidney disease. <i>Kidney Int Suppl</i>. 2012;2(4):279-335.',
  'Killip S, Bennett JM, Chambers MD. Iron deficiency anemia. <i>Am Fam Physician</i>. 2007;75(5):671-678.',
  'Ko CW, Siddique SM, Patel A, et al. AGA clinical practice guidelines on the gastrointestinal evaluation of iron deficiency anemia. <i>Gastroenterology</i>. 2020;159(3):1085-1094.',
  'Labcorp. Anemia profile B. https://www.labcorp.com/tests/042077/anemia-profile-b Accessed June 22, 2023. ',
  'Labcorp. Complete blood count (CBC) with differential. https://www.labcorp.com/tests/005009/complete-blood-count-cbc-with-differential Accessed June 22, 2023. ',
  'Labcorp. Complete blood count (CBC) without differential. https://www.labcorp.com/tests/028142/complete-blood-count-cbc-without-differential Accessed June 22, 2023. ',
  'Labcorp. Ferritin. https://www.labcorp.com/tests/004598/ferritin Accessed June 22, 2023. ',
  'Labcorp. Sample report, 000810. https://files.labcorp.com/testmenu-d8/sample_reports/000810.pdf Accessed June 22, 2023. ',
  'Labcorp. Sample report, 001321. https://files.labcorp.com/testmenu-d8/sample_reports/001321.pdf Accessed June 22, 2023. ',
  'Labcorp. Sample report, 005009. https://files.labcorp.com/testmenu-d8/sample_reports/005009.pdf Accessed June 22, 2023. ',
  'Labcorp. Vitamin B12 and folates. https://www.labcorp.com/tests/000810/vitamin-b-sub-12-sub-and-folates Accessed June 22, 2023. ',
  'Lumen Learning. Anatomy &amp; physiology II: erythrocytes. https://courses.lumenlearning.com/suny-ap2/chapter/erythrocytes Accessed June 16, 2023.',
  'Masini G, Graham FJ, Pellicori P, et al. Criteria for iron deficiency in patients with heart failure. <i>J Am Coll Cardiol</i>. 2022;79(4):341-351.',
  'Mayo Clinic. Complete blood count. https://www.mayoclinic.org/tests-procedures/complete-blood-count/about/pac-20384919 Accessed June 16, 2023.',
  'Moreno Chulilla JA, Romero Colás MS, Gutiérrez Martín M. Classification of anemia for gastroenterologists. <i>World J Gastroenterol</i>. 2009;15(37):4627-4637.',
  'NIH National Heart, Lung, and Blood Institute. Iron-deficiency anemia. https://www.nhlbi.nih.gov/health/anemia/iron-deficiency-anemia Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Blood differential. https://medlineplus.gov/lab-tests/blood-differential/ Accessed June 16, 2023. ',
  'NIH National Library of Medicine. Medline Plus: Folate deficiency. https://medlineplus.gov/ency/article/00034.htm Accessed June 16, 2023. ',
  'NIH National Library of Medicine. Medline Plus: Folate-deficiency anemia. https://medlineplus.gov/ency/article/000551.htm Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: RBC count. http://medlineplus.gov/ency/article/003644.htm Accessed June 20, 2023.',
  'NIH National Library of Medicine. Medline Plus: RBC indices. http://medlineplus.gov/ency/article/003648.htm Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Reticulocyte count. https://medlineplus.gov/lab-tests/reticulocyte-count/ Accessed June 16, 2023.',
  'NIH National Library of Medicine. Medline Plus: Vitamin B12 deficiency anemia. https://medlineplus.gov/ency/article/000574.htm Accessed June 16, 2023.',
  'Quest Diagnostics<sup>&#8482;</sup>. Iron, TIBC and ferritin panel. https://testdirectory.questdiagnostics.com/test/test-detail/5616/iron-tibc-and-ferritin-panel?q=anemia%20panel&amp;cc=MASTER Accessed June 23, 2023. ',
  'Quest Diagnostics<sup>&#8482;</sup>. Reticulocyte count, automated. https://testdirectory.questdiagnostics.com/test/test-detail/793/reticulocyte-count-automated?cc=MASTER Accessed June 23, 2023. ',
  'Quest Diagnostics<sup>&#8482;</sup>. Vitamin B12 (cobalamin) and folate panel, serum. https://testdirectory.questdiagnostics.com/test/test-detail/7065/?cc=DLO Accessed June 23, 2023. ',
  'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>) for Hematopoietic Growth Factors. V.1.2024. &copy; National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed November 8, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
  'Short MW, Domagalski JE. Iron deficiency anemia: evaluation and management. <i>Am Fam Physician.</i> 2013;87(2):98-104.',
  'Society for the Advancement of Patient Blood Management (SABM<sup>&reg;</sup>). Iron corner: management of iron deficiency anemia in cancer and chemotherapy. https://sabm.org/wp-content/uploads/2ci-IDA-Cancer-Chemotherapy.pdf Accessed June 23, 2023.',
  'Testing.com. The complete blood count sample report. https://www.testing.com/wp-content/uploads/2021/07/CBC-sample-report-with-notes_0.pdf Accessed June 20, 2023. ',
  'Tortora GJ, Derrickson BH. The cardiovascular system: the blood. In: <i>Principles of Anatomy &amp; Physiology</i>. 16th ed. John Wiley &amp; Sons, Inc; 2021:696-726.',
  'World Health Organization. Haemoglobin concentrations for the diagnosis of anaemia and assessment severity. https://apps.who.int/iris/bitstream/handle/10665/85839/WHO_NMH_NHD_MNM_11.1_eng.pdf Accessed June 16, 2023.'
];

const References = ({ videoEl, activeVideoIndex }) => {
  const [data, setData] = useState(references);

  useEffect(() => {
    if (activeVideoIndex === 1) {
      setData(references);
    } else if (activeVideoIndex === 2) {
      setData(newLungRef);
    } else if (activeVideoIndex === 3) {
      setData(newAnemiaRef);
    } else {
      setData(references);
    }
  }, [activeVideoIndex]);

  return (
    <>
      <div className="reference-header">References:</div>
      <table className="reference-table">
        {data.map((val, key) => {
          return (
            <tr key={key} className="reference-row">
              <td style={{ textAlign: 'right' }}>{key + 1}.&nbsp;</td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: val }} />
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );
};

export default References;
