import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const Button = ({ name, route, buttonClass, time, reverse, videoIndex, videoEl, gtagId, gtagText }) => {
  let gtagAttrs = {};
  if (gtagId !== undefined && gtagText !== undefined) {
    gtagAttrs = {
      'id': gtagId,
      'text': gtagText
    };
  }
  if (route && time) {
    return (
      <Link
        {...gtagAttrs}
        className={`landing-button-container ${buttonClass !== undefined ? buttonClass : ''} ${
          reverse ? 'flex-reverse' : ''
        }`}
        to={route}
        onClick={() => {
          console.log('VARAIBELS FOR BUTTONS: ', videoIndex, time);
          videoEl.current[videoIndex].currentTime = time;
        }}
      >
        <div className="name" dangerouslySetInnerHTML={{ __html: name }} />
        {buttonClass !== 'wall' && (
          <div className="chevron">
            {!reverse ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} />
            )}
          </div>
        )}
      </Link>
    );
  }

  if (route && time === undefined) {
    return (
      <Link
        {...gtagAttrs}
        className={`landing-button-container ${buttonClass !== undefined ? buttonClass : ''} ${
          reverse ? 'flex-reverse' : ''
        }`}
        to={route}
      >
        <div className="name" dangerouslySetInnerHTML={{ __html: name }} />
        {buttonClass !== 'wall' && (
          <div className="chevron">
            {!reverse ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} />
            )}
          </div>
        )}
      </Link>
    );
  }

  return (
    <div
      {...gtagAttrs}
      className={`landing-button-container ${buttonClass !== undefined ? buttonClass : ''} ${
        reverse ? 'flex-reverse' : ''
      }`}
      onClick={() => {
        videoEl.current[videoIndex].currentTime = time;
      }}
    >
      <div className="name" dangerouslySetInnerHTML={{ __html: name }} />
      {buttonClass !== 'wall' && (
        <div className="chevron">
          {!reverse ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </div>
      )}
    </div>
  );
};

export default Button;
