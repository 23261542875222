import React from 'react';

const BulletTable = ({ bullets }) => (
  <table>
    {bullets.map((bullet, i) => (
      <tr key={i}>
        <td style={{ verticalAlign: 'top', paddingRight: '5px' }}>&bull;</td>
        <td dangerouslySetInnerHTML={{ __html: bullet }} />
      </tr>
    ))}
  </table>
);

export default BulletTable;
