import React from 'react';
import Button from './Button';

const Buttons = ({ buttons, containerClass, videoEl }) => (
  <div
    className={`landing-buttons-container ${containerClass !== undefined ? containerClass : ''}`}
  >
    {buttons.map((button, i) => {
      let gtagAttrs = {};
      if (button.gtagId !== undefined && button.gtagText !== undefined) {
        gtagAttrs = {
          'gtagId': button.gtagId,
          'gtagText': button.gtagText 
        };
      }
      return (
        <Button
          key={i}
          videoEl={videoEl}
          name={button.name}
          route={button.route}
          buttonClass={button.buttonClass}
          seek={button.seek}
          time={button.time}
          reverse={button.reverse}
          videoIndex={button.videoIndex}
          {...gtagAttrs}
        />
      )
    })}
  </div>
);

export default Buttons;
