import React from 'react';

const Bullets = ({ bullets }) => (
  <ul className="bullets">
    {bullets.map((bullet, i) => (
      <li key={i} dangerouslySetInnerHTML={{ __html: bullet }} />
    ))}
  </ul>
);

export default Bullets;
