import React, { useState, useContext } from 'react';
import { Context } from '../hooks';
import './DNAContent.css';

const DNARightCentered = ({ rightHeadline, rightBullet, rightCaption }) => {
  return (
    <>
      <div className="right-headline">{rightHeadline}</div>
      {rightBullet ? (
        <ul className="right-centered-list">
          <li>{rightBullet}</li>
        </ul>
      ) : (
        ' '
      )}
      <br />
      <div className="blue-background" dangerouslySetInnerHTML={{ __html: rightCaption }} />
    </>
  );
};

const DNARightAccordion = ({
  rightHeadline,
  rightSubHeadLine,
  rightBullet1,
  rightBullet2,
  waterFallHeadingGreen,
  waterFallHeadingWhite,
  waterFallContent
}) => {
  const { footerOpen, setFooterOpen } = useContext(Context);

  return (
    <>
      <div className="right-headline" style={{ marginBottom: 20 }}>
        {rightHeadline}
      </div>
      {rightSubHeadLine ? (
        <div style={{ marginBottom: 30 }}>{rightSubHeadLine}</div>
      ) : (
        <ul className="right-centered-list" style={{ marginBottom: 30 }}>
          <li>{rightBullet1}</li>
          <li>{rightBullet2}</li>
        </ul>
      )}

      <div className="waterfall-heading" onClick={() => setFooterOpen(!footerOpen)}>
        <div style={{ alignSelf: 'center' }}>
          <span>{waterFallHeadingGreen}</span> {waterFallHeadingWhite}
        </div>
        <div>
          <div className={`plusMinus ${footerOpen ? 'activeMinus' : ''}`}>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {footerOpen ? <div style={{ marginTop: 20 }}>{waterFallContent}</div> : ''}
    </>
  );
};

const ExpansionAccordion = ({ accordionHeading, accordionContent }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleClick = () => setAccordionOpen(!accordionOpen);

  return (
    <div className="accordion-container">
      <div className="accordion-heading" onClick={() => handleClick()}>
        <div
          style={{ alignSelf: 'center' }}
          dangerouslySetInnerHTML={{ __html: accordionHeading }}
        />
        <div>
          <div className={`plusMinus ${accordionOpen ? 'activeMinus' : ''}`}>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {accordionOpen && <div>{accordionContent}</div>}
    </div>
  );
};

export { DNARightCentered, DNARightAccordion, ExpansionAccordion };
